@import "../../../../assets/scss/__mandatory";

.project-details-indicators {
  &_head,
  &_foot {
    .is-leader-view {
    }

    .is-hub-view {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px 80px 0 80px;
      h3 {
        font-weight: $ft-medium;
        font-size: 20px;
      }
      .datepicker {
        width: 100%;
        max-width: 145px;
        margin-left: 10px;
        &_inner {
          width: 100%;
          max-width: 240px;
          position: relative;

          input[type="date"] {
            width: 100%;
            min-height: 30px;
            padding: 0px 10px;
            border-radius: 20px;
            border: 2px solid $color-softGrey;
            font-size: 14px;
            font-weight: $ft-bold;
            color: $color-ocean;
            background-color: white;
            transition: all 0.25s ease;
            &:focus {
              border-color: $color-deepblue-100;
            }
            &::-webkit-calendar-picker-indicator {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: auto;
              height: auto;
              color: transparent;
              background: transparent;
              cursor: pointer;
            }
            &:after {
              font-family: $font-icon;
              font-weight: $ft-black;
              content: "\f073";
              color: $color-ocean;
              cursor: pointer;
            }
          }

          &-value {
            font-size: 14px;
            font-weight: $ft-medium;
            color: $color-ocean;
            width: calc(100% - 35px);
            background-color: $white;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            height: 20px;
            display: flex;
            align-items: center;
          }
        }
      }
      button {
        margin-left: 10px;
      }
    }
  }

  &_content {
    padding: 24px 40px;
  }
}
