$color-white: #ffffff;
$color-black: #3a3a3b;

$font-body: "Arial";
$font-body-wf: "Montserrat";

$font-title: "Arial";
$font-title-wf: "Montserrat";

$font-title-alt: "Arial";
$font-title-alt-wf: "Montserrat";

$font: "Open Sans";
$font-button: "Open Sans";

$font-icon: "Font Awesome 5 Pro";

$font-mui: $font-body-wf;

$ft-thin: 100;
$ft-extralight: 200;
$ft-light: 300;
$ft-normal: 400;
$ft-regular: normal;
$ft-medium: 500;
$ft-semibold: 600;
$ft-bold: 700;
$ft-extrabold: 800;
$ft-black: 900;

/* COULEUR SITE PALETTE */

$color-deepblue-100: #102d40;
$color-deepblue-90: #2e4251;
$color-deepblueBright: #004d80;

$color-sky: #6dc3e5;
$color-ocean: #2b77a6;
$color-oceanBright: #389ad6;
$color-sky: #6dc3e5;
$color-sea: #c2e6f7;
$color-seaLight: #ecf4f9;

$color-bar: #284253;
$color-border: #405766;

$color-softGrey: #d5e0e1;

$color-citron: #f2e068;
$color-citronBright: #ffe136;

$color-mandarine: #ff992b;

$color-successBright: #1ad49b;
$color-success: #1df2b1;

$color-alert: #ff4a56;

$color-cobalt: #004d5f;

$color-title: white;
$white: white;

$color: $color-ocean;
$background-color: #0e2d40;

$text-black: #283237;
$black: #283237;
$blue: #4276a2;
$blue-text: #4076a2;
$blue-background: #5ca2d2;
$dark-blue: #1e4c5d;
$light-blue: #c6e1f1;

$green: #5bbf94;

$light-grey: #d2d8d8;

$card-width: 230px;

$color-anim-btn1: #186980;
$color-anim-btn2: #145769;

$color-text: $color-deepblue-100;
$color-text-dark: $color-ocean;
$color-text-light: white;

$color-sdg1: #eb1b2e;
$color-sdg2: #d3a029;
$color-sdg3: #279b48;
$color-sdg4: #c31e33;
$color-sdg5: #ee402c;
$color-sdg6: #01aed8;
$color-sdg7: #fdb712;
$color-sdg8: #901738;
$color-sdg9: #f36d24;
$color-sdg10: #e11484;
$color-sdg11: #f89d26;
$color-sdg12: #cf8d2b;
$color-sdg13: #47773e;
$color-sdg14: #007dbc;
$color-sdg15: #3eb049;
$color-sdg16: #02558b;
$color-sdg17: #193768;

$color-white: #ffffff;

$color-secondary: #ff5d5c;

$listColorESG: (
  (1, $color-sdg1, $color-white),
  (2, $color-sdg2, $color-deepblue-100),
  (3, $color-sdg3, $color-white),
  (4, $color-sdg4, $color-white),
  (5, $color-sdg5, $color-white),
  (6, $color-sdg6, $color-deepblue-100),
  (7, $color-sdg7, $color-deepblue-100),
  (8, $color-sdg8, $color-white),
  (9, $color-sdg9, $color-deepblue-100),
  (10, $color-sdg10, $color-white),
  (11, $color-sdg11, $color-deepblue-100),
  (12, $color-sdg12, $color-deepblue-100),
  (13, $color-sdg13, $color-white),
  (14, $color-sdg14, $color-white),
  (15, $color-sdg15, $color-deepblue-100),
  (16, $color-sdg16, $color-white),
  (17, $color-sdg17, $color-white)
);

$color-primary: $color-deepblue-100;
$color-primary-5: #f7f8fb;
$color-primary-10: #eef1f7;
$color-primary-15: #f7f8fb;
$color-primary-20: #dee4ef;
$color-primary-25: #d6ddea;
$color-primary-30: #ced6e6;
$color-primary-35: #c6d0e2;
$color-primary-40: #bec9de;
$color-primary-50: rgba($color-primary, 0.5);
$color-primary-60: #9dadce;
$color-primary-70: #8da0c6;
$color-primary-80: #d1d9e8;
$color-primary-90: #ccd5e5;

$color-primary-dark: #5c77ad;

// $color-primary-light: $turquoise-50;
// $color-primary: $turquoise-100;
// $color-primary-dark: $turquoise-150;

// $color-secondary-light: $purple-50;
// $color-secondary: $purple-100;
// $color-secondary-dark: $purple-150;

$background: #c6e1f1;
$text: #283237;

$color-shadow: rgba(72, 40, 86, 0.8);
$box-shadow: 0 5px 70px -5px rgba(72, 40, 86, 0.8);

$color-html: $background;

$color-body: $background;
$color-body-text: $text;
$color-text: $text;

$color-hamburger: $black;
$color-hamburger-bg: transparent;
$color-hamburger-open: $black;
$color-hamburger-bg-open: transparent;

$color-offcanvas-overlay: $white;
$color-offcanvas-bg: $background;

$color-header-background: transparent;
$color-header-mainnav-background: transparent;
$color-header-mainnav-link: $white;

$color-mainheader-background: $white;
$color-mainheader-logo-background: $white;

$color-invalid: red;

$listTheme: (
  ("white", white, $color-primary),
  ("primary", $color-primary, white),
  ("deepblue-100", $color-deepblue-100, white),
  ("deepblue-90", $color-deepblue-90, white),
  ("deepblue-deepblueBright", $color-deepblueBright, white),
  ("sky", $color-sky, white),
  ("ocean", $color-ocean, white),
  ("oceanBright", $color-oceanBright, white),
  ("sea", $color-sea, white),
  ("seaLight", $color-seaLight, $color-deepblue-100)
);

$listColor: (
  ("white", white),
  ("primary", $color-primary),
  ("deepblue-100", $color-deepblue-100),
  ("deepblue-90", $color-deepblue-90),
  ("deepblue-deepblueBright", $color-deepblueBright),
  ("sky", $color-sky),
  ("ocean", $color-ocean),
  ("oceanBright", $color-oceanBright),
  ("sea", $color-sea),
  ("seaLight", $color-seaLight)
);

$heightElementUserMenu: 18;
$paddingMenuUser: 20;
