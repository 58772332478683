@import "../../__mandatory";

.box {
  width: 100%;
  &_inner {
    width: 100%;
    color: $color-primary;
  }
  &__head {
    padding: 15px;
    @media screen and (min-width: $bp-tablet) {
      padding: 45px 45px 15px 45px;
    }
    h1,
    h2 {
      font-size: 16px;
      strong,
      small,
      span {
        display: block;
      }
      small {
        font-weight: $ft-light;
      }
      span {
        font-weight: 700;
        color: $color-ocean;
      }
    }
  }
  &__content {
    padding: 15px;
    @media screen and (min-width: $bp-tablet) {
      padding: 15px 45px;
    }
    .spacer {
      position: relative;
      margin: 30px 0;
      font-size: 10px;
      text-transform: uppercase;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 1px;
        background-color: rgba($color-primary, 0.2);
        transform: translateX(-50%) translateY(-50%);
      }
      span {
        position: relative;
        z-index: 2;
        display: inline-block;
        background-color: white;
        padding: 5px 15px;
      }
    }
    .btn {
      &-azure {
        margin: 0 auto;
        background-color: #00518f;
        color: white;
        padding: 0 30px;
        @include border-radius(5px);
        min-height: 40px;
        margin-bottom: 15px;
        .btn {
          &_icon {
            font-size: 24px;
            margin-right: 15px;
          }
          &_label {
            font-size: 16px;
          }
        }
      }
    }
  }
  &__foot {
    padding: 15px;
    @media screen and (min-width: $bp-tablet) {
      padding: 15px 45px;
    }
    ul {
      text-align: left;
      font-size: 12px;
      padding: 15px 0;
      li {
        & + li {
          margin-top: 8px;
        }
      }
    }
  }
}
