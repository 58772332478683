@import "../../../../../assets/scss/__mandatory";
// @import "../../../../../assets/scss/custom/ui";



.table-carbon-footprint {
  &.table {
    .table_inner {
      flex-flow: wrap;
      @media print {
        color: $color-deepblue-100;
        //background-color: $color-ocean;
      }
    }
    .table__row {
      width: 100%;
      height: 58px;
      break-inside: avoid;

      @media screen and (min-width: $bp-desk-small) {
        height: 54px;
      }
      &-child {
        .subrow-main {
          @media print {
            background-color: rgba($color-seaLight, 1);

            break-inside: avoid;
            page-break-inside: avoid;
            // page-break-after: avoid;
            // page-break-inside: avoid;
          }
        }
        &:nth-child(even) {
          .subrow-main {
            @include transitionElt(all, 0.25s, ease);
            @media screen {
              background-color: rgba(white, 0.2);
            }
            &:hover {
              background-color: $color-deepblue-100;
            }
          }
        }
        &.uk-open {
          .subrow-main {
            .column-fund {
              &:before {
                transform: translateY(-50%) rotate(-180deg);
              }
            }
          }
          .ui {
            &:not(.open) {
              display: none;
              @media print {
                display: block;
              }
            }
          }
        }
        &:not(.uk-open) {
          .subrow-other {
            @media screen {
              display: none;
            }
          }
          .ui {
            &:not(.close) {
              display: none;
            }
          }
        }
        & + .table__row-child {
          margin-top: 0;
          // border-top: 1px solid red;
        }
      }
    }
    .column {
      &-fund {
        @media print {
          width: 36%;
        }
        @media screen {
          width: 30%;
        }
        &.gigf {
          @media print {
            width: 40%;
          }
          @media screen {
            width: 40%;
          }
        }
      }
      &-abs-emission {
        @media print {
          width: 14%;
        }
        @media screen {
          width: 16%;
        }
        &.gigf {
          @media print {
            width: 24%;
          }
          @media screen {
            width: 24%;
          }
        }
      }
      &-capex {
        @media print {
          width: 14%;
        }
        @media screen {
          width: 16%;
        }
      }
      &-concession {
        @media print {
          width: 10%;
        }
        @media screen {
          width: 12%;
        }
      }
      &-emission {
        @media print {
          width: 26%;
        }
        @media screen {
          width: 30%;
        }
        // @media screen and (min-width: $bp-tablet) {
        //   // width: 20%;
        //   width: 30%;
        // }
        span {
          &.bar {
            max-width: calc(100% - 90px);
            background-color: white;
            @media print {
              background-color: $color-deepblue-100;
            }
          }
        }
        .row__body {
          color: $color-ocean;
        }
      }
      &-carbon-fp {
        position: relative;
        @media print {
          width: 18%;
        }
        @media screen {
          width: 18%;
        }
        &.larger {
          @media screen {
            width: 26%;
          }
        }
        &.gigf {
          @media print {
            width: 24%;
          }
          @media screen {
            width: 24%;
          }
        }
        span {
          &.bar {
            // max-width: calc(100% - 90px); // DEBUG SAM - MORGANE - TEST
            background-color: white;
            padding-right: 0;
            @media print {
              background-color: $color-deepblue-100;
            }
          }
        }
      }
      &-trajectory {
        @media print {
          width: 6%;
        }
        @media screen {
          width: 8%;
        }
        &.larger {
          @media print {
            width: 14%;
          }
          @media screen {
            width: 14%;
          }
        }
      }
      &-avoided {
        @media print {
          width: 12%;
        }
        @media screen {
          width: 12%;
        }
      }
      &-scope1, &-scope2, &-capex-euro {
        @media print {
          width: 12%;
        }
        @media screen {
          width: 12%;
        }
      } 
      &-scope3 {
        @media print {
          width: 17%;
        }
        @media screen {
          width: 19%;
        }
      } 
      &-emission-above-below {
        @media print {
          width: 11%;
        }
        @media screen {
          width: 15%;
        }
      } 
    }

    .row {
      &__head {
        &_inner {
          width: 100%;        
          display: flex; 
          flex-flow: wrap;
          align-items: flex-end;
        }
        &.head-repeat {
          @media screen {
            display: none;
          }
        }
        &:after {
          content: initial;
        }
        .table__column {
          display: flex;
          align-items: flex-end;
          padding-bottom: 7px;
          flex-flow: wrap;
          sub {
            font-size: 80%;
          }
          &:after {
            content: "";
            height: 1px;
            width: calc(100% - 20px);
            display: block;
            margin: 7px 0 0 0;
            background-color: $color-sea;
            @media print {
              background-color: $color-deepblue-100;
            }
          }
          &.column {
            &-fund,
            &-trajectory,
            &-avoided {
              &:after {
                width: calc(100%);
              }
            }
          }
        }
      }
      &__body {
        height: auto;
        align-items: flex-start;
        &:nth-child(odd) {
          background-color: rgba(white, 0.2);
        }
        & + .row__body {
          border-top: 1px solid white;
        }
      }
    }
    .subrow-main {
      height: 40px;
      align-items: center;
      @media print {

        break-inside: avoid;
        page-break-inside: avoid;
        // page-break-after: avoid;
        // page-break-inside: avoid;
      }
      cursor: pointer;
      @include transitionElt(all, 0.25s, ease);

      &:hover {
        background-color: $color-deepblue-100;
      }
      .column-fund {
        position: relative;
        &:before {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          transform-origin: 50%;
          font-family: $font-icon;
          font-size: 12px;
          font-weight: 700;
          width: 8px;
          height: 10px;
          @include transitionElt(all, 0.25s, ease);
          @media screen {
            content: "\f103";
          }
        }
      }
    }
    .subrow-main,
    .subrow-other {
      .column-fund {
        padding-left: 20px;
      }
      .table__column {
        height: 100%;
        display: flex;
        flex-flow: wrap;
        align-items: center;
      }
      .line {
        margin: auto 0;
      }
    }
    .subrow-other {
    }
    .subrow-project {
      height: 40px;
      align-items: center;
    }

    .fund,
    .project {
      width: 100%;
      @media print {
        break-inside: avoid;
        // page-break-inside: avoid;
        page-break-after: avoid;
        page-break-inside: avoid;
      }
      .subrow {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        // @media print {
        //   page-break-inside: avoid;
        // }
      }
      span {
        &:not(.bar) {
          padding-right: 20px;
        }
      }
      .line {
        width: 100%;
      }
      &_name {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        height: 18px;
        line-height: 18px;
        max-width: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        span {
          overflow: hidden;
          max-width: 320px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .fund {
      &_subprojects {
        span {
          display: inline;
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          padding-right: 0;
          &.ui {
            &-indication {
              font-style: italic;
              @media screen and (max-width: $bp-desk-small) {
                display: none;
              }
            }
          }
        }
      }
    }
    .project {
      position: relative;
      color: $color-sea;
      @media print {
        color: $color-ocean;
      }
      @include transitionElt(all, 0.25s, ease);
      &:hover {
        background-color: $color-deepblue-100;
      }
      .column-fund {
        span {
          font-weight: 400;
          img {
            width: 16px;
            height: 16px;
            opacity: 0.8;
          }
        }
      }
      &_name {
        @media print {
          font-size: 12px;
        }
      }
      .column-trajectory {
        width: 8%;
        &.larger {
          width: 14%;
        }
        span {
          font-size: 12px;
          b {
            font-weight: $ft-bold;
          }
        }
      }
      & + .project {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 20px;
          height: 1px;
          width: calc(100% - 20px);
          background-color: #5592b8;
          @media print {
            background-color: $color-ocean;
          }
        }
      }
      span {
        &.bar {
          background-color: $color-sea;
          max-height: 10px;
          min-width: 0;
          @media print {
            background-color: $color-ocean;
          }
          &[data-percent="0"] {
            margin-right: 0;
          }
        }
        b {
          color: $color-sea;
          font-size: 12px;
          font-weight: 500;
          @media print {
            color: $color-ocean;
          }
        }
      }
      &_name {
        width: calc(100% - 30px);
      }
      &_icon {
        display: flex;
        align-items: center;
        width: 30px;
        height: 100%;
        @media print {
          img {
            filter: invert(0.9) sepia(1) saturate(3) hue-rotate(200deg) !important;
          }
          i {
            color: $color-deepblue-90 !important;
          }
        }
        i {
          display: block;
          margin: auto;
        }
      }
    }
  }
}
