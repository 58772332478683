@import "../../../assets/scss/__mandatory";

.page {
  &-dashboard {
    min-height: calc(100vh - 140px);
    .page {
      &_inner {
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
      }
    }
    .cards {
      width: 100%;
      max-width: 940px;
      margin: 0 auto;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
    }
  }
}

.grid {
  &-dashboard {
    @media screen and (min-width: $bp-tablet) {
      .grid {
        &_inner {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(5, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
        }
      }
      .grid_inner {
        .card {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 3;
          }
          &:nth-child(2) {
            grid-area: 1 / 3 / 2 / 5;
          }
          &:nth-child(3) {
            grid-area: 2 / 1 / 3 / 3;
          }
          &:nth-child(4) {
            grid-area: 2 / 3 / 3 / 5;
          }
          &:nth-child(5) {
            grid-area: 3 / 1 / 4 / 3;
          }
          &:nth-child(6) {
            grid-area: 3 / 3 / 4 / 5;
          }
          &:nth-child(7) {
            grid-area: 4 / 1 / 5 / 3;
          }
          &:nth-child(8) {
            grid-area: 4 / 3 / 5 / 5;
          }
          &:nth-child(9) {
            grid-area: 5 / 1 / 6 / 3;
          }
          &:nth-child(10) {
            grid-area: 5 / 3 / 6 / 5;
          }
        }
      }
    }
    @media screen and (min-width: $bp-desk-small) {
      .grid {
        &_inner {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
        }
      }
      &[data-grid="mode-0"] {
        .grid_inner {
          .card {
            &:nth-child(1) {
              grid-area: 1 / 1 / 3 / 3;
            }
            &:nth-child(2) {
              grid-area: 1 / 3 / 3 / 5;
            }
            &:nth-child(3) {
              grid-area: 3 / 1 / 5 / 2;
              .card_inner {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                .icon {
                  height: 60px;
                  width: 45px;
                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
                .category {
                  width: 100%;
                  padding-left: 0;
                  display: block;
                  height: auto;
                  &-name {
                    font-size: 16px;
                  }
                }
              }
            }
            &:nth-child(4) {
              grid-area: 3 / 2 / 5 / 3;
              .card_inner {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                .icon {
                  height: 60px;
                  width: 45px;
                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
                .category {
                  width: 100%;
                  padding-left: 0;
                  display: block;
                  height: auto;
                  &-name {
                    font-size: 16px;
                  }
                }
              }
            }
            &:nth-child(5) {
              grid-area: 3 / 3 / 5 / 5;
            }
            &:nth-child(6) {
              grid-area: 5 / 1 / 7 / 2;
              .card_inner {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                .icon {
                  height: 46px;
                  width: 80px;
                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
                .category {
                  width: 100%;
                  padding-left: 0;
                  display: block;
                  height: auto;
                  &-name {
                    font-size: 16px;
                  }
                }
              }
            }
            &:nth-child(7) {
              grid-area: 5 / 2 / 6 / 3;
              .card_inner {
                flex-flow: column-reverse;
                align-items: flex-start;
                .icon {
                  margin-top: 10px;
                  svg {
                    margin: 0;
                  }
                }
                .category {
                  width: 100%;
                  padding: 0;
                  &-name {
                    font-size: 16px;
                  }
                }
              }
            }
            &:nth-child(8) {
              grid-area: 5 / 3 / 6 / 4;
              .card_inner {
                flex-flow: column-reverse;
                align-items: flex-start;
                .icon {
                  margin-top: 10px;
                  svg {
                    margin: 0;
                  }
                }
                .category {
                  width: 100%;
                  padding: 0;
                  &-name {
                    font-size: 16px;
                  }
                }
              }
            }
            &:nth-child(9) {
              grid-area: 5 / 4 / 6 / 5;
              .card_inner {
                flex-flow: column-reverse;
                align-items: flex-start;
                .icon {
                  margin-top: 10px;
                  svg {
                    margin: 0;
                  }
                }
                .category {
                  width: 100%;
                  padding: 0;
                  &-name {
                    font-size: 16px;
                  }
                }
              }
            }
            &:nth-child(10) {
              grid-area: 6 / 2 / 7 / 3;
              .card_inner {
                flex-flow: column-reverse;
                align-items: flex-start;
                .icon {
                  margin-top: 10px;
                  svg {
                    margin: 0;
                  }
                }
                .category {
                  width: 100%;
                  padding: 0;
                  &-name {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }

      &[data-grid="mode-1"] {
        .grid_inner {
          .card {
            &:nth-child(1) {
              grid-area: 1 / 1 / 2 / 3;
            }
            &:nth-child(2) {
              grid-area: 1 / 3 / 2 / 5;
            }
            &:nth-child(3) {
              grid-area: 2 / 1 / 3 / 3;
            }
            &:nth-child(4) {
              grid-area: 2 / 3 / 3 / 5;
            }
          }
        }
      }

      &[data-grid="mode-10"] {
        .grid_inner {
          .card {
            &:nth-child(1) {
              grid-area: 1 / 1 / 2 / 3;
            }
            &:nth-child(2) {
              grid-area: 1 / 3 / 2 / 5;
            }
          }
        }
      }
    }
  }
}
