@import "../../../../assets/scss/__mandatory";

.tool-spv-manager {
  background-color: white;
  color: $color-deepblue-100;
  max-width: 940px;
  padding: 20px;
  margin: 0 auto 80px auto;
  width: 100%;

  .content-body {
    .label {
      font-size: 12px;
    }
    .list {
      .row {
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        div + div {
          margin-left: 10px;
          @media screen and (min-width: $bp-tablet) {
            margin-left: 15px;
          }
        }

        .name,
        .warning,
        .status,
        .open,
        .survey {
          width: 100%;
          min-height: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: $font;
          .btn.btn-select {
            padding: 0 10px;
            -webkit-appearance: none;
            .btn-icon {
              width: 20px;
              margin-left: 10px;
            }
          }
        }

        .name {
          max-width: 130px;
          min-width: 130px;
          @media screen and (min-width: $bp-tablet) {
            max-width: none;
          }
        }
        .warning {
          max-width: 120px;
          min-width: 120px;
          justify-content: flex-end;
          @media screen and (min-width: $bp-tablet) {
            max-width: 170px;
            min-width: 170px;
          }
        }
        .status {
          max-width: 120px;
          min-width: 120px;
        }
        .open {
          max-width: 90px;
        }
        .survey {
          justify-content: flex-end;
        }

        &:not(.filters) {
          padding: 10px 15px;

          .name {
            font-weight: $ft-bold;
            font-size: 16px;
          }
          .warning {
            color: $color-alert;
            i {
              font-size: 16px;
            }
            span {
              font-size: 12px;
              font-style: italic;
              margin-left: 5px;
            }
          }
          .status {
            font-size: 16px;
          }
          .survey {
            max-width: 140px;
          }

          &:nth-child(even) {
            background-color: $color-seaLight;
            border-radius: 0px 25px 25px 0px;
          }
        }

        &.filters {
          i {
            font-size: 12px;
          }
          .btn-filter {
            min-height: 20px;
            background-color: $color-cobalt;
            border: 2px solid $color-cobalt;
            border-radius: 15px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $white;
            font-weight: $ft-bold;
            font-size: 12px;
            .btn_icon {
              margin-left: 5px;
            }
          }

          .name {
            max-width: calc(130px + 15px);
            min-width: calc(130px + 15px);
            @media screen and (min-width: $bp-tablet) {
              max-width: none;
            }
          }
          .warning,
          .survey {
            justify-content: flex-end;
            label {
              font-style: italic;
              font-size: 12px;
              margin-right: 10px;
              text-align: right;
              width: calc(100% - 50px);
              @media (any-hover: hover) {
                &:hover {
                  cursor: pointer;
                }
              }
            }
            &-switcher {
              pointer-events: none;
              position: relative;
              background-color: $white;
              width: 30px;
              height: 20px;
              border: 2px solid #829395;
              border-radius: 15px;
              cursor: pointer;
              transition: 0.3s all ease-in-out;
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 4px;
                transform: translateY(-50%);
                background-color: #829395;
                width: 10px;
                height: 10px;
                border-radius: 20px;
                transition: 0.3s all ease-in-out;
              }
            }
            &-input {
              opacity: 0;
              width: 30px;
              height: 20px;
              position: absolute;
              @media (any-hover: hover) {
                &:hover {
                  cursor: pointer;
                }
              }
              &:checked {
                & + .warning-switcher,
                & + .survey-switcher {
                  background-color: #829395;
                  transition: 0.3s all ease-in-out;
                  &::before {
                    background-color: $white;
                    left: 12px;
                    transition: 0.3s all ease-in-out;
                  }
                }
              }
            }
          }
          .open {
            display: none;
            @media screen and (min-width: $bp-tablet) {
              min-width: 50px;
              max-width: 50px;
              display: flex;
            }
          }
          .survey {
            min-width: 200px;
            @media screen and (min-width: $bp-tablet) {
              max-width: 200px;
            }
          }
        }
      }
    }
  }

  .loader {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
}
