input {
  &[type="search"] {
    @media screen and (min-width: $bp-tablet) {
    }
    &:focus,
    &:active {
      border-color: $color;
    }
    & + .btn {
    }
  }
}
.row {
  &-autocomplete {
    margin-top: 15px;
    position: relative;
    &:after {
      content: "\f002";
      position: absolute;
      top: 0;
      right: 0;
      width: 28px;
      height: 32px;
      font-size: 17px;
      line-height: 32px;
      z-index: 10;
      font-weight: $ft-bold;
      font-family: $font-icon;
      color: $color-secondary;
      pointer-events: none;
    }
    input {
      position: relative;
      z-index: 5;
      padding-right: 40px;
      background-color: white;
    }
    .search-results {
      position: absolute;
      left: 0;
      width: 100%;
      transform: translateY(30px);
      @include transitionElt(all, 0.3, ease);
      opacity: 0;
      &.search-open {
        transform: translateY(0px);
        opacity: 1;
      }
      ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: white;
        @include border-radius(16px);
        padding: 42px 10px 10px 10px;
        box-shadow: $box-shadow;
        li {
          background-color: rgba($color-primary-25, 0);
          font-size: 14px;
          font-weight: $ft-regular;
          color: $color-primary;
          @include border-radius(16px);
          padding: 0 15px;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          min-height: 30px;
          a {
            color: inherit;
          }
          &:hover,
          &--is-selected,
          &--is-focused {
            font-weight: $ft-extrabold;
            background-color: rgba($color-primary-25, 1);
          }
        }
      }
    }
  }
}
