@import "../../../../assets/scss/__mandatory";

.tool-country-list {
  background-color: white;
  color: $color-deepblue-100;
  max-width: 940px;
  padding: 20px;
  margin: 0 auto;
  width: 100%;

  .loader {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }

  .content-body {
    .label {
      font-size: 12px;
    }
    .list {
      .row {
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        div + div {
          margin-left: 10px;
          @media screen and (min-width: $bp-tablet) {
            margin-left: 15px;
          }
        }

        .name,
        .currency,
        .warning,
        .dollar-parity,
        .last-update {
          width: 100%;
          min-height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $font;
        }

        .name {
          max-width: 250px;
          min-width: 140px;
          justify-content: space-between;
          .iso-code {
            text-align: right;
            height: 100%;
            display: flex;
          }
        }
        .currency {
          justify-content: flex-start;
          max-width: 160px;
          min-width: 70px;
          padding: 0 5px;
          @media screen and (min-width: $bp-tablet) {
            padding: 0 15px;
          }
        }
        .warning {
          max-width: 30px;
          display: none;
          @media screen and (min-width: $bp-tablet) {
            display: flex;
          }
        }
        .group {
          width: 100%;
          max-width: 140px;
          .selector {
            margin-bottom: 0;
            .btn_label {
              text-transform: capitalize;
            }
          }
        }
        .dollar-parity {
          max-width: 100px;
          min-width: 70px;
          @media screen and (min-width: $bp-tablet) {
            min-width: 80px;
          }
        }
        .last-update {
          max-width: 110px;
        }

        &:not(.filters) {
          padding: 10px 15px;
          border-radius: 0px 25px 25px 0px;

          .name {
            border-radius: 15px;
            background-color: $color-seaLight;
            font-size: 14px;
            padding: 0px 15px;
            .country {
              font-weight: $ft-bold;
            }
          }
          .currency {
            border-left: 1px solid $color-sea;
          }
          .warning {
            font-weight: 900;
            font-size: 20px;
            color: $color-alert;
          }
          .dollar-parity {
            position: relative;
            input {
              max-width: 90px;
              width: 100%;
              border: 2px solid #829395;
              border-radius: 15px;
              background-color: $white;
              padding: 5px;
              font-family: $font;
              font-weight: $ft-bold;
              font-size: 12px;
              text-align: center;
              color: $color-deepblue-100;
              @media screen and (min-width: $bp-tablet) {
                padding: 5px 10px;
                font-size: 14px;
              }
            }
            .tooltip {
              position: absolute;
              top: -10px;
              width: max-content;
              background-color: rgba(#fbf1b1, 0.9);
              padding: 0px 10px 3px 10px;
              &-label {
                font-style: italic;
                font-weight: bold;
                font-size: 12px;
              }

              opacity: 0;
              pointer-events: none;
              transition: 0.3s all ease-in-out;
            }
          }
          .last-update {
            font-weight: $ft-bold;
            font-size: 14px;
            text-align: center;
          }

          &:nth-child(odd) {
            background-color: $color-seaLight;
            .name {
              background-color: $white;
            }
          }
        }
        &.filters {
          i {
            font-size: 12px;
          }
          .btn-filter {
            min-height: 20px;
            background-color: $color-cobalt;
            border: 2px solid $color-cobalt;
            border-radius: 15px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $white;
            font-weight: $ft-bold;
            font-size: 12px;
            .btn_icon {
              margin-left: 5px;
            }
          }
          .name {
            max-width: calc(250px + 15px);
            min-width: calc(140px + 15px);
          }
          .group {
            .btn-select .btn_icon:before {
              content: "";
            }
          }
          // .dollar-parity {
          // justify-content: space-evenly;
          // i:last-of-type {
          //   display: none;
          // }
          // &.warn {
          //   color: $color-alert;
          //   i:last-of-type {
          //     display: block;
          //   }
          // }
          // }
          .last-update {
            max-width: calc(110px + 15px);
          }
        }
        &.warn {
          background-color: rgba($color-alert, 0.25) !important;
          .name {
            background-color: $white;
          }
          .dollar-parity {
            input {
              border-color: $color-alert;
              color: $color-alert;
              &::placeholder {
                color: $color-alert;
              }
              @media (any-hover: hover) {
                &:hover {
                  & + .tooltip {
                    opacity: 1;
                    transition: 0.3s all ease-in-out;
                  }
                }
              }
            }
          }
        }

        &.is-modified {
          background-color: rgba($color-citron, 0.5) !important;
        }

        & + .row {
          margin-top: 4px;
        }
      }
    }
  }
}
