@import "../../../../assets/scss/__mandatory";

@import "../../../../assets/scss/custom/charts/sdg-bars";

.tool_sdg-impact-synthesis {
  .tool_lp_inner {
    position: relative;
    @media screen {
      background-color: $color-seaLight;
      overflow: hidden;
    }

    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    @media screen and (min-width: $bp-tablet) {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
    }
    .tool__title {
      h3 {
        font-weight: 400;
        color: $color-deepblue-100;
        text-transform: uppercase;
        font-size: 18px;
        margin-top: 5px;
      }
    }
    .sdg {
      &-infos {
        position: relative;
        z-index: 10;
        color: $color-deepblue-100;
        @media screen and (min-width: $bp-tablet) {
          padding: 0;
        }
        @media screen {
          margin-top: 20px;
          padding: 0 15px 0 0;
        }
      }
      &_inner {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .legend {
          font-weight: 400;
          margin: auto 0;
          padding-right: 10px;
          font-size: 12px;
        }
        .icon {
          width: 30px;
          margin: auto 0;
          .sdg-icon {
            display: block;
            width: 30px;
            height: 30px;
            margin: auto;
            @include border-radius(50%);
            overflow: hidden;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
        .scores {
          margin-left: 10px;
          border-top: 1px solid $color-sea;
          border-bottom: 1px solid $color-sea;
          min-height: 30px;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          min-width: 180px;
          @media screen and (min-width: $bp-tablet) {
            min-width: 280px;
          }
          .score {
            &_inner {
              .legend {
              }
              .value {
                font-weight: 700;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .filters {
      display: flex;
      flex-flow: wrap;
      margin-top: 10px;
      .btn {
        &-goals {
          background-color: rgba($color-sea, 1);
          border: 1px solid rgba($color-sea, 1);
          min-height: 20px;
          @include border-radius(15px);
          padding: 0 10px;
          &.on {
            background-color: rgba($color-success, 0.5);
            border-color: rgba($color-success, 0.5);
          }
          .btn {
            &_label {
              font-size: 12px;
              font-weight: 300;
            }
          }
        }
      }
      .goal {
        min-height: 20px;
        padding: 0 10px;
        background-color: rgba($color-success, 0.5);
        @include border-radius(15px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        span {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  &.tool.tool_lp {
    .tool__actions {
      margin-top: 0;
    }
  }

  .dataviz_wrapper {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 0px;
    display: flex;
    flex-flow: wrap;
    @include transitionElt(all, 0.25s, ease);
    @media screen and (min-width: $bp-tablet) {
      max-height: 390px;
      padding-top: 20px;
      padding-bottom: 0px;
    }
    &.split {
      .dataviz {
        @media screen and (min-width: $bp-tablet) {
          width: 50%;
        }
        &_rosace {
          justify-content: flex-start;
          @media screen and (min-width: $bp-tablet) {
            margin-left: 0;
            min-width: 400px;
          }
          .container-charts {
            @media screen and (min-width: $bp-tablet) {
              max-width: 400px;
              margin-left: 20px;
              margin-top: 20px;
            }
          }
        }
        &_bars {
          @media screen and (min-width: $bp-tablet) {
            transform: translateX(-100%);
            max-width: calc(100% - 480px);
          }
          @media screen and (min-width: $bp-desk-small) {
            max-width: none;
          }
        }
      }
      .info-sdg-line {
        .label {
          display: none;
        }
      }
    }
    &:not(.split) {
      .info-sdg-line {
        max-width: 420px;
        @media screen and (min-width: $bp-desk-large) {
          max-width: 470px;
        }
      }
    }
    .dataviz {
      position: relative;
      width: 100%;
      margin: 0 auto;

      .btn-select {
        min-width: 90px;
        .btn_label {
          font-size: 12px;
          padding-left: 2px;
        }
      }

      .call-to-use {
        position: absolute;
        text-align: left;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 220px;
        display: none;
        @media screen and (min-width: $bp-tablet) {
          display: block;
        }
        span {
          display: block;
          transform: translateX(0%);
          font-style: italic;
          font-size: 14px;
          line-height: 1.4;
        }
        svg {
          position: absolute;
          bottom: 0;
          right: 0;
          margin-right: 10px;
          transform: translateX(100%) translateY(0%);
          width: 47px;
          height: 16px;
        }
      }
      &_rosace {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        height: auto;
        .container-charts {
          width: 100%;
          margin: auto;
          @media screen and (min-width: $bp-tablet) {
            transform: translateY(-60px);
          }
        }
      }
      &_bars {
        position: relative;
        z-index: 30;
        height: 100%;
        //max-height: 674px;
        background-color: white;
        @include transitionElt(all, 0.25s, ease);
        transform: translateX(0%);
        @media screen and (min-width: $bp-tablet) {
          position: absolute;
          top: 0;
          left: 100%;
        }
        [data-sdg="0"] {
          opacity: 0;
          pointer-events: none;
        }
        .btn {
          &-close {
            position: absolute;
            top: 0;
            right: 100%;
            padding: 0 5px;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            height: 60px;
            border-radius: 0 0 0 5px;
            min-width: 20px;
            transition: none;
            .btn {
              &_icon {
                font-size: 12px;
                margin: auto;
              }
              &_label {
                margin: auto;
              }
            }
          }
        }
        .chart {
          &__head {
            position: relative;
            @media screen and (min-width: $bp-tablet) {
              position: absolute;
            }
            .inner {
              padding: 0;
              .label {
                width: calc(100% - 20px - 60px - 94px);
                padding-right: 10px;
              }
            }
          }
          &-content {
            @media screen and (min-width: $bp-tablet) {
              width: 100%;
              padding-top: 60px;
              min-height: 623px;
              max-height: 623px;
              overflow: scroll;
              padding-right: 15px;
            }
          }
        }
      }
    }
    .info-sdg {
      &-line {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;
        margin-bottom: 30px;
        @media screen and (min-width: $bp-tablet) {
          margin-bottom: -78px;
        }
        @media screen and (min-width: $bp-desk-small) {
          margin-bottom: -68px;
        }
      }
    }
    .select {
      &-sdg {
        width: 100%;
        max-width: 94px;
        @media screen and (min-width: $bp-tablet) {
          width: auto;
        }
        ul {
          width: 94px;
          li {
            min-height: 16px;
            font-size: 12px;
            &.current {
              font-weight: bold;
            }
          }
        }
      }
      .drop {
        max-width: 94px;
        min-width: 94px;
      }
    }
  }
}

.info-sdg {
  &-line {
    width: 100%;
    max-width: none;
    font-size: 12px;
    margin-right: 0;
    margin-left: 0;
    @media screen and (min-width: $bp-tablet) {
      max-width: 280px;
    }
    &_inner {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      @media screen and (min-width: $bp-tablet) {
        justify-content: flex-end;
      }
    }
    .label {
      font-weight: 700;
      margin: auto 0;
      padding-right: 10px;
    }
    .icon {
      width: 40px;
      margin: auto 0;
      .sdg-icon {
        display: block;
        width: 30px;
        height: 30px;
        margin: auto;
        @include border-radius(50%);
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .scores {
      border-top: 1px solid $color-sea;
      border-bottom: 1px solid $color-sea;
      min-height: 30px;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      .score {
        &_inner {
          .legend {
          }
          .value {
            padding-left: 5px;
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
    }
  }
  width: 100%;
  max-width: 260px;
  display: flex;
  flex-flow: wrap;
  align-items: flex-end;
  margin: 0 auto;
  @media screen and (min-width: $bp-desk-large) {
    max-width: none;
    margin-top: 0px;
    position: absolute;
    bottom: 0%;
    transform: translateX(0px) translateY(25%);
  }
  // @media screen and (min-width: $bp-desk-xlarge) {
  //   transform: translateX(-100%) translateY(100%);
  // }
  .info-head {
    width: 100%;

    .label {
      font-size: 12px;
      font-weight: 700;
      color: $color-deepblue-100;
      padding: 15px 0 5px 0;
      text-align: center;
      margin: 0 auto;
      max-width: 200px;
    }
  }
  .info-body {
    width: 100%;
    margin: 10px auto;
    padding: 5px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $color-sea;
    border-bottom: 1px solid $color-sea;
    .scores {
      position: relative;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      text-align: center;
      justify-content: center;
      .score {
        position: relative;
        display: flex;
        flex-flow: wrap;
        width: 100%;
        text-align: center;
        justify-content: center;
        span {
          display: block;
        }
        .label {
          width: 100%;
          font-size: 12px;
          & + .value {
            margin-top: 5px;
          }
        }
        .values {
          width: 100%;
          margin: auto;
          span {
            display: block;
          }
        }
        .value {
          font-weight: 700;
        }
      }
      &:not(.with-comparaison) {
        .score {
          width: 100%;
          .value {
            font-size: 24px;
          }
        }
      }
      &.with-comparaison {
        .score {
          width: 50%;
          min-height: 48px;
          .value {
            font-size: 16px;
          }
          &.score1 {
            text-align: right;
            padding-right: 20px;
          }
          &.score2 {
            text-align: left;
            padding-left: 20px;
            color: $color-ocean;
          }
        }
        .square {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 25px;
          height: 25px;
          @include border-radius(4px);
          transform: rotate(45deg) translateX(-50%) translateY(-50%);
          overflow: hidden;
          transform-origin: 0% 0%;
          .inner-square {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 36px;
            height: 36px;
            transform: rotate(-45deg) translateX(0%) translateY(-25px);
            display: block;
            &:before,
            &:after {
              position: absolute;
              top: 50%;
              content: "";
              width: 50%;
              height: 100%;
              display: block;
            }
            &:before {
              background-color: $color-deepblue-100;
              right: 50%;
              //transform-origin: 100% 50%;
              transform: rotate(0) translateY(-50%);
            }
            &:after {
              background-color: $color-ocean;
              left: 50%;
              //transform-origin: 0% 50%;
              transform: rotate(0) translateY(-50%);
            }
          }
        }
      }
    }
  }
}

@each $color, $hexaColor, $completHexaColor in $listColorESG {
  .cmp {
    &.chart-bars {
      &[data-sdg="#{$color}"] {
        .chart-head {
          background-color: $hexaColor;
          .label {
            color: $completHexaColor;
          }
        }
        .btn-close {
          right: 99%;
          border-radius: 0;
          background-color: $hexaColor;
          color: $completHexaColor;
        }
      }
    }
  }
}
