@import "../../../../../assets/scss/_mandatory";

.user {
  &-infos {
    &_inner {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 20px;

      input[type="text"] {
        &:disabled {
          pointer-events: none;
          cursor: none;
          border-color: $black !important;
          color: $white !important;
          background-color: $black !important;
        }
      }
    }
  }
}
