@mixin bg-gradient($deg, $start-colour, $end-colour) {
  //background-color: $start-colour;
  background-image: -webkit-gradient(
    $deg,
    left top,
    left bottom,
    from($start-colour),
    to($end-colour)
  );
  background-image: -webkit-linear-gradient($deg, $start-colour, $end-colour);
  background-image: -moz-linear-gradient($deg, $start-colour, $end-colour);
  background-image: -ms-linear-gradient($deg, $start-colour, $end-colour);
  background-image: -o-linear-gradient($deg, $start-colour, $end-colour);
  background-image: linear-gradient($deg, $start-colour, $end-colour);
}

@mixin bg-gradient3pts($deg, $start-colour, $mid-colour, $end-colour) {
  background-color: $start-colour;
  background-image: -webkit-gradient(
    $deg,
    left top,
    left bottom,
    from($start-colour),
    to($end-colour)
  );
  background-image: -webkit-linear-gradient(
    $deg,
    $start-colour,
    $mid-colour,
    $end-colour
  );
  background-image: -moz-linear-gradient(
    $deg,
    $start-colour,
    $mid-colour,
    $end-colour
  );
  background-image: -ms-linear-gradient(
    $deg,
    $start-colour,
    $mid-colour,
    $end-colour
  );
  background-image: -o-linear-gradient(
    $deg,
    $start-colour,
    $mid-colour,
    $end-colour
  );
  background-image: linear-gradient(
    $deg,
    $start-colour,
    $mid-colour,
    $end-colour
  );
}

@mixin bg-gradientRadial($deg, $start-colour, $end-colour) {
  background-image: -webkit-gradient(
    $deg,
    left top,
    left bottom,
    from($start-colour),
    to($end-colour)
  );
  background-image: -webkit-radial-gradient($deg, $start-colour, $end-colour);
  background-image: -moz-radial-gradient($deg, $start-colour, $end-colour);
  background-image: -ms-radial-gradient($deg, $start-colour, $end-colour);
  background-image: -o-radial-gradient($deg, $start-colour, $end-colour);
  background-image: radial-gradient($deg, $start-colour, $end-colour);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transitionElt($attribut, $time, $type) {
  -webkit-transition: $attribut $time $type;
  -moz-transition: $attribut $time $type;
  -ms-transition: $attribut $time $type;
  -o-transition: $attribut $time $type;
  transition: $attribut $time $type;
}

@mixin originPoint($horizontal, $vertical) {
  -webkit-transform-origin: $horizontal $vertical;
  -moz-transform-origin: $horizontal $vertical;
  -ms-transform-origin: $horizontal $vertical;
  -o-transform-origin: $horizontal $vertical;
  transform-origin: $horizontal $vertical;
}

@mixin reset-float() {
  content: "";
  clear: both;
  float: none;
  display: block;
}

@mixin box-sizing($box) {
  -webkit-box-sizing: $box;
  -moz-box-sizing: $box;
  box-sizing: $box;
}

@mixin rotateElt($degre) {
  -webkit-transform: rotate($degre);
  -moz-transform: rotate($degre);
  -ms-transform: rotate($degre);
  -o-transform: rotate($degre);
  transform: rotate($degre);
}

@mixin scaleElt($value) {
  -webkit-transform: scale($value);
  -moz-transform: scale($value);
  -ms-transform: scale($value);
  -o-transform: scale($value);
  transform: scale($value);
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin aspect-ratio($arglist... /*$width/$ratio, $height*/) {
  $map: keywords($arglist);
  $height: map-get($map, height) or nth-or-null($arglist, 2);
  $width: map-get($map, width) or nth-or-null($arglist, 1);
  $ratio: map-get($map, ratio) or
    if($width and $height, $width/$height, nth-or-null($arglist, 1)) or 1;
  $padding: 1 / $ratio * 100%;
  &:before {
    content: "";
    float: left;
    padding-bottom: $padding;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-or-null($list, $index) {
  @return if(length($list) >= $index, nth($list, $index), null);
}

//
//  Function to create an optimized svg url
//
@function svg-url($svg) {
  //
  //  Add missing namespace
  //
  @if not str-index($svg, xmlns) {
    $svg: str-replace($svg, "<svg", '<svg xmlns="http://www.w3.org/2000/svg"');
  }
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded: "";
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg) / $slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode
    //
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, "%", "%25");
    $chunk: str-replace($chunk, "&", "%26");
    $chunk: str-replace($chunk, "#", "%23");
    $chunk: str-replace($chunk, "{", "%7B");
    $chunk: str-replace($chunk, "}", "%7D");
    $chunk: str-replace($chunk, "<", "%3C");
    $chunk: str-replace($chunk, ">", "%3E");

    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml,#{$encoded}");
}

//  Background svg mixin
@mixin background-svg($svg) {
  background-image: svg-url($svg);
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @return if(
    $index,
    str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      ),
    $string
  );
}

@mixin multiLineEllipsis($fontSize: 1.2em, $lineHeight: 1.2em, $lineCount: 1) {
  display: block;
  display: -webkit-box;
  font-size: $fontSize * 1px;
  line-height: $lineHeight * 1px;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ng-deep($enableNgDeep: true) {
  @if $enableNgDeep {
    ::ng-deep {
      @content;
    }
  } @else {
    @content;
  }
}
