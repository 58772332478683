@import "../../../../../assets/scss/__mandatory";
@import "../../../../../assets/scss/custom/form/_base";

input {
  border: $input-border-size solid $color-input-off;
  width: 100%;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  background-color: $color-input-bg;
  @include transitionElt(all, 0.25s, ease);
  &:active,
  &:focus {
    border-color: $color-input-active;
    font-weight: 700;
  }

  &:optional {
  }
  &:required {
  }

  &.automated {
    border-color: $color-input-border-automated;
    color: $color-input-automated;
    background-color: $color-input-automated-bg;
  }
  &[readonly] {
    pointer-events: none;
    cursor: none;
    border-color: $color-input-border-automated;
    color: $color-input-automated;
    background-color: $color-input-automated-bg;
  }

  &:not(.no-check) {
    &.ng-invalid,
    &:invalid {
      &:not(.ng-untouched) {
        border-color: $color-input-border-invalid;
        &:active,
        &:focus {
          border-color: $color-input-border-active;
        }
      }
      &.ng-untouched {
        &:active,
        &:focus {
          border-color: $color-input-border-active;
        }
      }
    }
    &:valid {
      border-color: $color-input-border-success;
    }
  }
}

input {
  height: $input-height;
  padding: 0px 10px;
  @include border-radius(20px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

textarea {
  border: $input-border-size solid $color-input-off;
  width: 100%;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  background-color: $color-input-bg;
  @include transitionElt(all, 0.25s, ease);

  padding: 10px;
  @include border-radius(10px);
  min-height: 190px;
  &:active,
  &:focus {
    border-color: $color-input-active;
    font-weight: 700;
  }
  &.message {
    resize: none;
  }
  &:not(.message) {
    resize: vertical;
  }
}

.question-open-value {
  display: block;
  &:optional {
  }
  &:required {
  }

  &.automated {
    border-color: $color-input-border-automated;
    color: $color-input-automated;
    background-color: $color-input-automated-bg;
  }
  &[readonly] {
    pointer-events: none;
    cursor: none;
    border-color: $color-input-border-automated;
    color: $color-input-automated;
    background-color: $color-input-automated-bg;
  }

  &.ng-invalid,
  &:invalid {
    &:not(.ng-untouched) {
      textarea {
        border-color: $color-input-border-invalid;
        &:active,
        &:focus {
          border-color: $color-input-border-active;
        }
      }
    }
    &.ng-untouched {
      &:active,
      &:focus {
        textarea {
          border-color: $color-input-border-active;
        }
      }
    }
  }
  &.ng-valid {
    textarea {
      border-color: $color-input-border-success;
    }
  }
}
