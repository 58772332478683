@import "../../../assets/scss/__mandatory";

body {
  &[id="bodySite"] {
    // background-color: $color-deepblue-100;
    font-size: 16px;
    color: $color-text;
    @media print {
      background-color: white;
      font-family: $font-body;
    }
    & * {
      @include box-sizing(border-box);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

html {
  &.own-html {
    background-color: white;
    margin-top: 0 !important;
    &.wf-active {
      #bodySite {
        font-family: $font-body-wf;
      }
    }
    &.uk-modal-page {
      .filters-main {
        filter: brightness(0.5);
        pointer-events: none;
        transition: all 0.15s linear !important;
      }
    }
    &.search-open {
      #bodySite {
        height: 100%;
        overflow: hidden;
        .toolbar-container {
          width: 100%;
          display: block;
          & + .uk-sticky-placeholder {
            display: block;
          }
          &.uk-sticky {
            &.uk-active {
              & + .uk-sticky-placeholder {
                & + .main-container {
                  .search-result {
                    top: 60px;
                    height: calc(100% - 60px);
                  }
                }
              }
            }
          }
        }
      }
    }

    .wrapp-all-sections {
      width: 100%;
      margin: auto;
      min-height: 100vh;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }
}

.highcharts-tooltip-container {
  .highcharts-root,
  .highcharts-header,
  br {
    display: none;
  }
  .highcharts-tooltip-1,
  .highcharts-tooltip-2,
  .highcharts-tooltip-3,
  .highcharts-tooltip-4,
  .highcharts-tooltip-5,
  .highcharts-tooltip-6,
  .highcharts-tooltip-7 {
    filter: none;
  }
}

.uk-tooltip {
  &.tooltip-chart {
    display: flex !important;
    flex-flow: wrap;
    transform: translateX(-50%) translateY(-100%);
    margin-top: 18px;
    background-color: white;
    border: 1px solid white;
    @include border-radius(1px);
    white-space: initial;
    padding: 20px;
    min-width: 200px;
    -webkit-box-shadow: 0px 10px 40px 0px rgba($color-deepblue-100, 0.75);
    -moz-box-shadow: 0px 10px 40px 0px rgba($color-deepblue-100, 0.75);
    box-shadow: 0px 10px 40px 0px rgba($color-deepblue-100, 0.75);
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateY(0%) translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #ffffff transparent transparent transparent;
    }
    .line {
      width: 100%;
      text-align: center;
      line-height: 1.3;
      &.line1 {
        h3 {
          font-family: $font-title;
          font-weight: 700;
          font-size: 14px;
        }
      }
      &.line2 {
        font-family: $font-title;
        font-weight: 400;
        font-size: 16px;
      }
      &.line3 {
        padding: 5px 0 0 0;
        font-weight: 400;
        font-size: 18px;
        font-family: "Montserrat";
      }
    }
  }
  &.tooltip {
    &-lp {
      color: $color-deepblue-100;
      margin-top: -5px;
    }
  }
}

.upp {
  text-transform: uppercase;
}
