@import "../../../../../assets/scss/__mandatory";

.remote {
  &-list {
    margin-top: 10px;
    padding: 0px 20px;
    &_inner {
      display: flex;
      flex-flow: wrap;
      padding: 10px 0 0;
      border-top: 1px solid $color-softGrey;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        @include border-radius(15px);
        min-height: 20px;
        color: white;
        font-weight: $ft-bold;
        border: 2px solid $color-softGrey;
        background-color: $color-softGrey;
        color: rgba($color-deepblue-100, 0.5);
        &_label {
          font-size: 12px;
        }
        &.active {
          border: 2px solid $color-deepblue-100;
          background-color: $color-ocean;
          color: white;
          &_label {
            font-weight: $ft-bold;
          }
        }
      }
      li {
        & + li {
          margin-left: 10px;
        }
      }
    }
  }
}

.custom-selection {
  &-wrapper {
    width: 100%;
    padding: 0 20px;
    flex-flow: wrap;
    &.open {
      display: flex;
      padding: 15px 20px;
    }
    &.close {
      display: none;
    }
    .projects {
      &-list {
        width: 100%;

        :global {
          .MuiFormControlLabel {
            &-root {
              margin-left: 0;
              padding: 0;
              .MuiCheckbox {
                &-root {
                  color: $color-primary;
                  width: 18px;
                  height: 18px;
                  padding: 0;
                  svg {
                    width: 18px;
                    height: 18px;
                    fill: $color-sea;
                  }

                  & + .MuiTypography {
                    &-root {
                      font-family: $font;
                      font-size: 12px;
                      line-height: 1;
                      margin-left: 5px;
                      font-weight: $ft-bold;
                    }
                  }
                  &.Mui {
                    &-checked {
                      svg {
                        fill: $color-deepblue-100;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .list {
          &-item {
            display: flex;
            min-height: 24px;
            align-items: center;
            padding: 7px 10px 7px 40px;
            span {
              font-size: 12px;
              line-height: 1;
              display: block;
              margin: auto 0;
            }

            &.all {
              padding: 7px 10px 7px 20px;
            }

            &:not(.all) {
              border-top: 1px solid $color-sea;
            }

            &.checked {
              background-color: $color-sea;
            }
          }
        }
        li {
          width: 100%;
          span {
            font-weight: $ft-bold;
            font-size: 12px;
            display: block;
            margin: auto 0;
          }
          ul {
            max-height: 380px;
            overflow-y: scroll;
          }
        }
      }
    }
  }
}

.filters {
  &-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    .actions {
      padding: 20px 0 0 0;
      .dropdown {
        position: relative;
        .btn {
        }
      }
    }
  }
  &-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    padding: 15px 20px;
    &.open {
      display: flex;
    }
    &.close {
      display: none;
    }
    .filter {
      width: 100%;
      height: 100%;
      display: block;
      &-heading {
        border-top: 1px solid $color-ocean;
        display: flex;
        min-height: 18px;
        align-items: center;
        padding: 10px 15px;
        span {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: $ft-bold;
          line-height: 1.4;
        }
      }
      ul {
        padding-bottom: 10px;
      }
      &-row-checkbox {
        position: relative;
        border-top: 1px solid $color-sea;
        padding: 7px 15px;
        &.checked {
          background-color: $color-sea;
        }
        input {
          display: block;
          height: 0;
          width: 0;
          appearance: none;
          border: none;
          &:checked {
            & + label {
              font-weight: $ft-bold;
              &:before {
                width: 8px;
                height: 8px;
                border: 4px solid $color-deepblue-100;
              }
            }
          }
        }
        label {
          font-family: $font;
          font-size: 12px;
          line-height: 1;
          font-weight: $ft-bold;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @media (any-hover: hover) {
            &:hover {
              cursor: pointer;
            }
          }
          &:before {
            content: "";
            margin-right: 5px;
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: $white;
            border: 2px solid $color-sea;
            border-radius: 10px;
          }
        }
        &.fund:first-of-type {
          border-top: 1px solid $color-ocean;
        }
      }
      ul {
      }
    }
  }
}
