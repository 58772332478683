$input-heightDesk: 32px;
$input-height: 40px;

$input-border-size: 1px;
$radio-border-size: 1px;
$checkbox-border-size: 1px;

$color-input-bg: white;

$input-font-size: 12px;
$input-font-weight: $ft-medium;

$input-placeholder-color: $color-primary-50;
$input-placeholder-font-weight: 400;

$color-input-border-off: $white;
$color-input-off: $white;

$color-radio-border-off: $white;
$color-radio-off: $white;

$color-checkbox-border-off: $white;
$color-checkbox-off: $white;

$color-input-border-active: $black;
$color-input-active: $black;

$color-radio-border-active: $black;
$color-radio-active: $black;

$color-checkbox-border-active: $black;
$color-checkbox-active: $black;

$color-input-border-readonly: white;
$color-input-readonly: #ccc;

$color-input-border-automated: $black;
$color-input-automated: white;
$color-input-automated-bg: $black;

$color-input-border-invalid: $color-alert;
$color-input-invalid: $color-alert;

$color-input-border-success: $color-success;
$color-input-success: $color-success;

::-webkit-input-placeholder {
  color: $input-placeholder-color;
  font-size: $input-font-size;
  font-weight: $input-placeholder-font-weight;
  font-style: normal;
}
::-moz-placeholder {
  color: $input-placeholder-color;
  font-size: $input-font-size;
  font-weight: $input-placeholder-font-weight;
  font-style: normal;
}
:-ms-input-placeholder {
  color: $input-placeholder-color;
  font-size: $input-font-size;
  font-weight: $input-placeholder-font-weight;
  font-style: normal;
}
:-moz-placeholder {
  color: $input-placeholder-color;
  font-size: $input-font-size;
  font-weight: $input-placeholder-font-weight;
  font-style: normal;
}

.form {
  &_head {
    padding-bottom: 20px;
    p {
      font-size: 13px;
    }
  }
  &-error {
    color: $color-alert;
  }
  .row {
    &-message {
      .error {
        color: $color-alert;
        padding: 30px 0 0 0;
        &:empty {
          padding: 0;
        }
      }
    }
  }
}

@import "../form/input";
@import "../form/input-file";
@import "../form/input-search";
@import "../form/checkbox";
@import "../form/textarea";
