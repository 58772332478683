@import "../../../../../assets/scss/__mandatory";

.cols {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  .col {
    width: 100%;
    text-align: left;
    color: black;
    @media screen and (min-width: $bp-desk-small) {
      width: 50%;
    }
    &1 {
    }
    &2 {
      .tool {
        &__content {
          position: relative;
          width: 100%;
          padding: 20px 0 160px 0;
          height: 100%;
          .dataviz {
            width: 100%;
            &_rosace {
              position: relative;
            }
          }
        }
      }
    }
  }
}

.builder {
  width: 100%;
  color: $color-deepblue-100;
  background-color: white;
  @include border-radius(20px);
  padding: 10px 30px;

  &-wrapper {
    width: 100%;
    margin-top: 20px;
    &.has-filters {
      .builder {
        &-body {
          @include border-radius(0 20px 0 0);
        }
      }
    }
  }
  &-head {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    .series {
      &-list {
        display: flex;
        flex-flow: wrap;
        .list {
          &-item {
            position: relative;
            z-index: 2;
            @include border-radius(12px 12px 0 0);
            background-color: $color-softGrey;
            color: rgba($color-deepblue-100, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 28px;
            padding: 0 30px;
            &:nth-child(1) {
              .btn {
                &_label {
                  &:before {
                    background-color: $color-deepblue-100;
                  }
                }
              }
            }
            &:nth-child(2) {
              .btn {
                &_label {
                  &:before {
                    background-color: $color-ocean;
                  }
                }
              }
            }
            .btn {
              margin: auto;
              &_label {
                font-size: 12px;
                font-weight: $ft-bold;
                opacity: 0.5;
                display: flex;
                align-items: center;
                &:not(.plus) {
                  &:before {
                    content: "";
                    width: 12px;
                    height: 12px;
                    @include border-radius(50%);
                    display: block;
                    margin-right: 5px;
                  }
                }
                &.plus {
                  color: rgba($color-deepblue-100, 0.5);
                }
              }
            }
            &.active {
              background-color: white;
              color: $color-deepblue-100;
              .btn {
                &_label {
                  opacity: 1;
                }
              }
            }
            & + .list {
              &-item {
                z-index: 1;
                padding-left: 25px;
                padding-right: 15px;
                transform: translateX(-15px);
              }
            }
          }
        }
      }
    }
    .btn {
      &_label {
        font-size: 16px;
        font-weight: $ft-bold;
      }
      & + .btn {
        .btn {
          &_label {
            font-size: 12px;
            font-weight: $ft-regular;
            font-style: italic;
          }
        }
      }
    }
    .ui {
      &-indicator {
        font-style: italic;
        font-size: 11px;
        color: rgba($color-deepblue-100, 0.5);
        margin: auto auto auto 0;
        display: flex;
        align-items: center;
        span {
          cursor: pointer;
        }
      }
    }
  }
  &-body {
    width: 100%;
    background-color: white;
    padding: 0px;
    @include border-radius(0 20px 20px 20px);

    .serie {
      width: 100%;
      &.open {
        display: block;
      }
      &.close {
        display: none;
      }
      &-wrapper {
        width: 100%;
        display: flex;
        flex-flow: wrap;
      }
    }
  }
}

.dataviz {
  &-wrapper {
    position: relative;
    width: 100%;
    // background-color: red;
    padding-bottom: 60px;
    @media screen and (min-width: $bp-desk-large) {
      padding-bottom: 40px;
    }
    .sdg {
      &-infos {
        position: relative;
        text-align: right;
        .sdg {
          &_inner {
            width: 100%;
            max-width: 240px;
            margin: 0 0 auto auto;
            text-align: center;
            @media screen and (min-width: $bp-desk-large) {
              position: absolute;
              top: 0;
              left: 100%;
              transform: translateY(-75%) translateX(-50%);
            }
          }
          &-icon {
            display: block;
            width: 60px;
            height: 60px;
            margin: auto;
            @include border-radius(50%);
            overflow: hidden;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
            svg {
              width: 60px;
              height: 60px;
            }
            &-wrapper {
              width: 100%;
            }
          }
          &-legend {
            margin: auto 0;
            padding: 10px 0;
            span {
              font-weight: $ft-bold;
              font-size: 12px;
            }
          }
          &-label {
            width: 100%;
            display: block;
            font-weight: $ft-regular;
            font-size: 14px;
          }
          &-value {
            width: 100%;
            font-weight: $ft-bold;
            font-size: 14px;
          }
          &-scores {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: center;
            border-top: 1px solid $color-sea;
            border-bottom: 1px solid $color-sea;
            min-width: 180px;
            padding: 10px 0;
            &.split {
              .score {
                position: relative;
                width: 50%;
                min-height: 32px;
                &:before {
                  content: "";
                  width: 24px;
                  height: 24px;
                  position: absolute;
                  top: 50%;
                  @include border-radius(4px);
                }
                &.score1 {
                  text-align: right;
                  color: $color-deepblue-100;
                  padding-right: 25px;
                  &:before {
                    background-color: $color-deepblue-100;
                    left: 100%;
                    transform: translateY(-50%) translateX(-50%) rotate(45deg);
                  }
                }
                &.score2 {
                  text-align: left;
                  color: $color-ocean;
                  padding-left: 25px;
                  &:before {
                    background-color: $color-ocean;
                    right: 100%;
                    transform: translateY(-50%) translateX(50%) rotate(45deg);
                  }
                }
              }
            }
            .score {
              overflow: hidden;
              display: flex;
              flex-flow: wrap;
              align-items: center;
              &_inner {
                width: 100%;
                margin: auto 0;
              }
            }
          }
        }
      }
    }
  }
}

// .pdf {
//   &-legends {
//     @media print {
//       padding: 10mm 0;
//     }
//     page-break-after: avoid;
//     display: block;
//     font-family: $font-title-wf;
//     text-align: left;
//     &-wrapper {
//       width: 100%;
//       display: flex;
//       flex-flow: wrap;
//       border-left: 1px solid $color-sea;
//       border-right: 1px solid $color-sea;
//       &.split {
//         .legends {
//           &_inner {
//             width: 50%;
//           }
//         }
//       }
//     }
//     .legends {
//       &_inner {
//         width: 100%;
//         padding: 0 10px;
//         &.serie-1 {
//           color: $color-deepblue-100;
//           h2 {
//             span {
//               &:before {
//                 background-color: $color-deepblue-100;
//               }
//             }
//           }
//         }
//         &.serie-2 {
//           border-left: 1px solid $color-sea;
//           color: $color-ocean;
//           h2 {
//             span {
//               &:before {
//                 background-color: $color-ocean;
//               }
//             }
//           }
//         }

//         h2 {
//           font-weight: $ft-black;
//           font-size: 16px;
//           span {
//             display: flex;
//             align-items: center;
//             &:before {
//               content: "";
//               width: 12px;
//               height: 12px;
//               @include border-radius(50%);
//               display: block;
//               margin-right: 5px;
//             }
//           }
//         }
//         .legend {
//           &-scope {
//             width: 100%;
//             text-align: left;
//             margin-top: 20px;
//             padding-left: 18px;
//             h4 {
//               width: 100%;
//               font-size: 14px;
//               text-align: left;
//             }
//           }
//         }
//       }
//     }
//   }
//   &-legend {
//     width: 100%;
//     margin-top: 20px;
//     padding-left: 18px;
//     & + .pdf-legend {
//       margin-top: 10px;
//     }
//     h3 {
//       font-weight: $ft-bold;
//       display: flex;
//       width: 100%;
//       flex-flow: wrap;
//       align-items: center;
//       font-size: 14px;
//       small {
//         font-weight: $ft-regular;
//       }
//       i {
//         margin-left: 5px;
//       }
//       & + ul {
//         margin-top: 5px;
//       }
//     }
//     ul {
//       width: 100%;
//       li {
//         width: 100%;
//       }
//     }
//   }
// }

// .pdf {
//   &-legends {
//     width: 100%;
//     &-wrapper {
//       width: 100%;
//       display: flex;
//       flex-flow: wrap;
//       border-left: 1px solid $color-sea;
//       border-right: 1px solid $color-sea;
//       padding-bottom: 10px;
//       &.split {
//         .legends {
//           &_inner {
//             width: 50%;
//           }
//         }
//       }
//     }
//     .legends {
//       &_inner {
//         width: 100%;
//         padding: 0 10px;
//         &.serie-1 {
//           color: $color-deepblue-100;
//           h2 {
//             span {
//               &:before {
//                 background-color: $color-deepblue-100;
//               }
//             }
//           }
//         }
//         &.serie-2 {
//           border-left: 1px solid $color-sea;
//           color: $color-ocean;
//           h2 {
//             span {
//               &:before {
//                 background-color: $color-ocean;
//               }
//             }
//           }
//         }

//         h2 {
//           font-weight: $ft-black;
//           font-size: 24px;
//           span {
//             display: flex;
//             align-items: center;
//             &:before {
//               content: "";
//               width: 12px;
//               height: 12px;
//               @include border-radius(50%);
//               display: block;
//               margin-right: 5px;
//             }
//           }
//         }
//         .legend {
//           &-scope {
//             margin-top: 20px;
//             padding-left: 18px;
//             h4 {
//               font-size: 20px;
//             }
//           }
//         }
//       }
//     }
//   }
//   &-legend {
//     margin-top: 20px;
//     padding-left: 18px;
//     & + .pdf-legend {
//       margin-top: 10px;
//     }
//     h3 {
//       font-weight: $ft-bold;
//       display: flex;
//       width: 100%;
//       flex-flow: wrap;
//       align-items: center;
//       i {
//         margin-left: 5px;
//       }
//       & + ul {
//         margin-top: 5px;
//       }
//     }
//     ul {
//       li {
//       }
//     }
//   }
// }
