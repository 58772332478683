/*//////////////////////////// GRILLE GLOBAL ////////////////////////////*/

.own-grid {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0;
  &[data-mode="grid"] {
    @media screen and (min-width: $bp-smartphone) {
    }
    @media screen and (min-width: $bp-smartphone-medium) {
    }
    @media screen and (min-width: $bp-smartphone-large) {
    }
    @media screen and (min-width: $bp-tablet) {
      //max-width: 700px;
    }
    @media screen and (min-width: $bp-desk-small) {
      //max-width: 920px;
    }
    @media screen and (min-width: $bp-desk-medium) {
      max-width: 1020px;
    }
    @media screen and (min-width: $bp-desk-large) {
      max-width: 1260px;
    }
  }

  &[data-mode="fluid"] {
    @media screen and (min-width: $bp-desk-large) {
      max-width: 1260px;
    }
  }

  &[data-mode="full"] {
    @media screen and (min-width: $bp-smartphone) {
    }
    @media screen and (min-width: $bp-smartphone-medium) {
    }
    @media screen and (min-width: $bp-smartphone-large) {
    }
    @media screen and (min-width: $bp-tablet) {
    }
    @media screen and (min-width: $bp-desk-small) {
    }
    @media screen and (min-width: $bp-desk-medium) {
    }
    @media screen and (min-width: $bp-desk-large) {
    }
  }
  &:after {
    @include reset-float();
  }
}

/*//////////////////////////// CONTAINER GRILLE ////////////////////////////*/
$grid20: 20;
$maxCol20: 20;

$grid12: 12;
$maxCol12: 12;

.own-container {
  width: 100%;
  @include box-sizing(border-box);
  .own-col {
    width: 100%;
    float: none;
    min-height: 2px;
  }
  &:after {
    @include reset-float();
  }
}

[data-length="4"].own-container {
  .own-col {
    width: 100/ (2) * 1%;
    float: left;
  }
}

/*//////////////////////////// CONTAINER GRILLE DIVISED BY 4 ////////////////////////////*/

$maxUnitGrid4: 12;
$maxColGrid4: 12;

@while $maxColGrid4 > 0 {
  @media screen and (min-width: $bp-tablet) {
    &[data-mode="grid"] {
      &[data-grid-unit="#{$maxUnitGrid4}"] {
        [data-unit="#{$maxColGrid4}"] {
          min-height: 2px;
        }
        [data-unit="6"] {
          width: 50%;
          float: left;
        }
        &.own-container-tablet {
          [data-unit="#{$maxColGrid4}"] {
            width: 50%;
            float: left;
          }
        }
      }
    }
    &[data-mode="fluid"] {
      .own-container {
        .own-col {
          width: auto;
          float: left;
        }
      }
    }
  }
  @media screen and (min-width: $bp-tablet) {
    &[data-mode="grid"] {
      &[data-grid-unit="#{$maxUnitGrid4}"] {
        [data-unit="#{$maxColGrid4}"] {
          width: 100%/ ($maxUnitGrid4 / $maxColGrid4);
          float: left;
        }
        &.own-container-tablet {
          [data-unit="#{$maxColGrid4}"] {
            width: 100%/ ($maxUnitGrid4 / $maxColGrid4);
          }
        }
      }
    }
    &[data-mode="fluid"] {
      .own-container {
        [data-unit="#{$maxColGrid4}"] {
          width: 100%/ ($maxUnitGrid4 / $maxColGrid4);
          float: left;
        }
      }
    }
  }

  $maxColGrid4: $maxColGrid4 - 1;
}

/*//////////////////////////// FIN CONTAINER GRILLE DIVISED BY 4 ////////////////////////////*/

/*//////////////////////////// CONTAINER GRILLE DIVISED BY 5 ////////////////////////////*/

$maxColGrid5: 20;
$maxUnitGrid5: 20;

@while $maxColGrid5 > 0 {
  @media screen and (min-width: $bp-tablet) {
    &[data-mode="grid"] {
      &[data-grid-unit="#{$maxUnitGrid5}"] {
        [data-unit="#{$maxColGrid5}"] {
          min-height: 2px;
        }

        [data-unit="6"] {
          width: 50%;
          float: left;
        }
        &.own-container-tablet {
          [data-unit="#{$maxColGrid5}"] {
            width: 50%;
            float: left;
          }
        }
      }
    }
    &[data-mode="fluid"] {
      .own-container {
        .own-col {
          width: auto;
          float: left;
        }
      }
    }
  }
  @media screen and (min-width: $bp-desk-small) {
    &[data-mode="grid"] {
      &[data-grid-unit="#{$maxUnitGrid5}"] {
        [data-unit="#{$maxColGrid5}"] {
          width: 100%/ ($maxUnitGrid5 / $maxColGrid5);
          float: left;
        }
        &.own-container-tablet {
          [data-unit="#{$maxColGrid5}"] {
            width: 100%/ ($maxUnitGrid5 / $maxColGrid5);
          }
        }
      }
    }
    &[data-mode="fluid"] {
      &[data-grid-unit="#{$maxUnitGrid5}"] {
        [data-unit="#{$maxColGrid5}"] {
          width: 100%/ ($maxUnitGrid5 / $maxColGrid5);
          float: left;
        }
      }
    }
  }

  $maxColGrid5: $maxColGrid5 - 1;
}

/*//////////////////////////// FIN CONTAINER GRILLE DIVISED BY 5  ////////////////////////////*/
