@import "../../../../assets/scss/__mandatory";

.page {
  &-dashboard {
    &-platform {
      min-height: calc(100vh - 140px);
      .page {
        &_inner {
          height: 100%;
          max-width: 940px;
          margin: 0 auto;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          justify-content: center;
        }
        &__head {
          color: $color-primary;
          width: 100%;
          max-width: 940px;
          margin: 0 auto;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: center;
          padding: 20px 10px 15px 10px;
          &-title {
            color: $color-deepblue-100;
            font-family: $font-body-wf;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-left: 10px;
          }
          &-buttons {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            .last-update-user {
              font-family: $font;
              font-size: 12px;
              line-height: 20px;
              color: $color-deepblue-100;
              width: 100%;
              margin-bottom: 15px;
              @media screen and (min-width: $bp-smartphone-medium) {
                width: auto;
                margin-bottom: 0;
                + .btn {
                  margin-left: 10px;
                }
              }
            }
            .btn.btn-select {
              padding: 0 10px;
              .btn-icon {
                width: 20px;
                margin-left: 10px;
              }
              + .btn {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.back-btn {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  padding: 30px 10px 0 10px !important;
  .btn {
    max-width: initial !important;
    display: flex;
    justify-content: space-evenly;
    -webkit-appearance: none;
    padding: 0 10px !important;
    &-icon {
      margin-right: 10px;
    }
    &-label {
      margin: 0 !important;
    }
  }
}

.grid {
  width: 100%;
  &_inner {
    max-width: 940px;
    margin: 0 auto;
    padding: 0 0 30px 0;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;

    @media screen and (min-width: $bp-desk-small) {
      padding: 30px 0;
    }
  }
  &-dashboard {
    .just-tech {
      .card {
        &-tool {
          width: 100%;
          @media screen and (min-width: $bp-tablet) {
            width: 50%;
            &:nth-child(odd) {
              padding-left: 0;
            }
            &:nth-child(even) {
              padding-right: 0;
            }
          }
        }
        .category-item {
          position: relative;
        }
      }
    }
  }
}
