@import "../../../../../assets/scss/__mandatory";

.remote {
  &-list {
    display: flex;
    flex-flow: wrap;
    padding: 20px 20px 10px 20px;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      @include border-radius(15px);
      min-height: 20px;
      color: white;
      border: 2px solid $color-softGrey;
      background-color: $color-softGrey;
      color: rgba($color-deepblue-100, 0.5);
      &_label {
        font-size: 12px;
      }
      &.active {
        border: 2px solid $color-deepblue-100;
        background-color: $color-ocean;
        color: white;
        &_label {
          font-weight: $ft-bold;
        }
      }
    }
    li {
      & + li {
        margin-left: 10px;
      }
    }
  }
}

.custom-selection {
  &-wrapper {
    width: 100%;
    padding: 0 20px;
    flex-flow: wrap;
    &.open {
      display: flex;
      padding: 15px 20px;
    }
    &.close {
      display: none;
    }
    .projects {
      &-list {
        width: 100%;

        :global {
          .MuiFormControlLabel {
            &-root {
              margin-left: 0;
              padding: 0;
              .MuiCheckbox {
                &-root {
                  color: $color-primary;
                  width: 18px;
                  height: 18px;
                  padding: 0;
                  svg {
                    width: 18px;
                    height: 18px;
                  }

                  & + .MuiTypography {
                    &-root {
                      font-family: $font-body-wf;
                      font-size: 12px;
                      line-height: 1;
                      margin-left: 5px;
                      font-weight: $ft-regular;
                    }
                  }
                  &.Mui {
                    &-checked {
                      & + .MuiTypography {
                        &-root {
                          font-weight: $ft-bold;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .list {
          &-item {
            display: flex;
            min-height: 24px;
            align-items: center;
            background-color: $color-seaLight;
            padding: 5px 10px;
            @include border-radius(15px);
            span {
              font-size: 12px;
              line-height: 1;
              display: block;
              margin: auto 0;
            }
          }
        }
        li {
          width: 100%;
          span {
            font-weight: $ft-bold;
            font-size: 12px;
            display: block;
            margin: auto 0;
          }
          & + li {
            margin-top: 5px;
          }
          ul {
            max-height: 380px;
            overflow-y: scroll;
            padding-left: 20px;
            li {
              & + li {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.filters {
  &-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    padding-bottom: 60px;
    &.open {
      display: flex;
    }
    &.close {
      display: none;
    }
  }
  &-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    .actions {
      padding: 20px 0 0 0;
      .dropdown {
        position: relative;
        .btn {
        }
      }
    }
  }
  &-selectors {
    width: 50%;
    padding-left: 20px;
    ul {
    }
  }

  &-group {
    width: 50%;
    .filter {
      height: 100%;
      &.open {
        padding: 10px;
        display: block;
        background-color: $color-sea;
      }
      &.close {
        display: none;
      }
      &-heading {
        background-color: white;
        display: flex;
        min-height: 18px;
        align-items: center;
        padding: 5px 10px;
        @include border-radius(15px);
        span {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: $ft-bold;
        }
      }
      ul {
        padding: 5px 0 10px 0;
      }
      &-row-checkbox {
        position: relative;
        padding-left: 20px;
        input {
          display: block;
          height: 0;
          width: 0;
          appearance: none;
          &:checked {
            & + label {
              font-weight: $ft-bold;
              &:before {
                content: "\f14a";
              }
            }
          }
          & + label {
            font-size: 12px;
            &:before {
              font-family: $font-icon;
              content: "\f0c8";
              margin-right: 5px;
            }
          }
        }

        & + .filter {
          &-row-checkbox {
            margin-top: 3px;
          }
        }
      }
      ul {
      }
    }
  }
}
.option-confidence {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  align-items: center;
  padding: 10px 20px;
  .btn {
    min-height: 24px;
    margin: auto 0;
    &_label {
    }
    &_icon {
      margin-left: 5px;
    }
  }
}
.selectors {
  &-list {
    width: 100%;
    border-top: 1px solid $color-sea;
    border-bottom: 1px solid $color-sea;
    li {
      .btn {
        position: relative;
        width: 100%;
        text-align: left;
        min-height: 30px;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 0 10px 0;
          border-color: transparent $color-sea transparent transparent;
          transform: translateY(-50%);
          pointer-events: none;
          @include transitionElt(all, 0.3s, ease);
        }
        &.active {
          .btn {
            &_label {
              font-weight: $ft-bold;
            }
          }
        }
        &.current {
          &:after {
            border-width: 10px 12px 10px 0;
          }
        }
        &_label {
          font-size: 12px;
        }
        &_icon {
        }
      }
      & + li {
        border-top: 1px solid $color-sea;
      }
    }
  }
}
