@import "../../../../assets/scss/__mandatory";
@import "../../../../assets//scss//custom//modal/base";

.uk-modal {
  .own-modal-content {
    width: 100%;
    margin-top: 0;
    max-width: 650px;
    background-color: white;
    padding: 30px;
  }
  .uk-modal {
    &-body {
      .missing-data {
        font-size: 14px;
        color: $color-alert;
        text-align: center;
        margin-bottom: 10px;
        span {
          color: $color-deepblue-100;
        }
      }
      h2 {
        color: $color-alert;
        font-weight: $ft-black;
        font-family: $font-title-wf;
        text-align: center;
        font-size: 18px;
        line-height: 1.4;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        .icon {
          font-size: 50px;
          margin: 10px 0;
        }
      }
      .btn-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        .btn {
          max-width: 70px;
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          &-label {
            margin: 0;
          }
          & + .btn {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
