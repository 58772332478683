@import "../../../../assets/scss/__mandatory";

.tool-spv-reporting {
  color: $color-deepblue-100;
  max-width: 940px;
  margin: 0 auto;
  width: 100%;

  .subtitle {
    font-weight: $ft-black;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }

  .btn-select {
    padding: 3px 10px !important;
    .btn-label + .btn-icon {
      margin-left: 10px;
    }
  }

  .tabs {
    &-container {
      &.MuiTabs-root,
      .MuiTabs-scroller.MuiTabs-fixed {
        overflow: visible !important;
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
    &-item {
      min-width: auto;
      overflow: visible;
      position: relative;
      height: 50px;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba($color-deepblue-100, 0.5);
      font-family: $font-title-wf;
      font-weight: $ft-black;
      border-radius: 10px 10px 0px 0px;
      background-color: $color-softGrey;
      opacity: 1;
      &.Mui-selected {
        background-color: white;
        color: $color-deepblue-100;
      }
      .MuiTouchRipple-root {
        display: none;
      }
      &.disabled[data-attribute] {
        color: rgba($color-deepblue-100, 0.5);
        &:after {
          content: attr(data-attribute);
          position: absolute;
          bottom: -24px;
          width: max-content;
          background-color: rgba(#fbf1b1, 0.8);
          padding: 0 10px;
          text-transform: none;
          color: $color-deepblue-100;
          font-style: italic;
          font-weight: bold;
          font-size: 12px;
          opacity: 0;
          pointer-events: none;
          transition: 0.3s all ease-in-out;
        }
        @media (any-hover: hover) {
          &:hover {
            cursor: default;
            &:after {
              opacity: 1;
              transition: 0.3s all ease-in-out;
            }
          }
        }
      }

      &.title {
        font-size: 20px;
        background-color: transparent;
        color: $color-deepblue-100;
        &.Mui-disabled {
          opacity: 1;
        }
      }
    }
  }

  .spv-reporting-content_inner {
    background-color: white;
    padding: 24px 20px;
    width: 100%;
    font-family: $font;

    .options-container {
      margin-bottom: 10px;
    }

    .downloads {
      &-container {
        margin-top: 20px;
      }
      &-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        & + .downloads-row {
          margin-top: 20px;
        }
        b {
          font-size: 12px;
          margin: 0 5px;
        }
        .warn-started {
          background-color: rgba($color-alert, 0.25);
          color: $color-alert;
          padding: 6px 30px 6px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px 0 0 15px;
          transform: translateX(22px);
          b {
            font-style: italic;
          }
        }
      }
    }
  }
}
