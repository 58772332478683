@import "../../../../assets/scss/__mandatory";

.tool-country-data {
  background-color: white;
  color: $color-deepblue-100;
  max-width: 940px;
  padding: 20px;
  margin: 0 auto 80px auto;
  width: 100%;

  .content-body {
    .label {
      font-size: 12px;
    }
    .list {
      .row {
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        div + div {
          margin-left: 10px;
          @media screen and (min-width: $bp-tablet) {
            margin-left: 15px;
          }
        }

        .id,
        .title,
        .warning,
        .edit {
          width: 100%;
          min-height: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: $font;
        }

        .id {
          max-width: 50px;
        }

        .edit {
          max-width: 80px;
        }

        &:not(.filters) {
          padding: 10px 15px;
          &:nth-child(even) {
            background-color: $color-seaLight;
            border-radius: 0px 25px 25px 0px;
          }
          .id {
            font-weight: $ft-bold;
            font-size: 12px;
          }
          .title {
            font-size: 14px;
            line-height: 1.2;
          }
          .warning {
            max-width: 25px;
            font-weight: 900;
            font-size: 20px;
            color: $color-alert;
          }
          .edit {
            .btn.btn-select {
              padding: 0 10px;
              -webkit-appearance: none;
              .btn-icon {
                width: 20px;
                margin-left: 10px;
              }
            }
          }
        }

        &.filters {
          i {
            font-size: 12px;
          }
          .btn-filter {
            min-height: 20px;
            background-color: $color-cobalt;
            border: 2px solid $color-cobalt;
            border-radius: 15px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $white;
            font-weight: $ft-bold;
            font-size: 12px;
            .btn_icon {
              margin-left: 5px;
            }
          }

          .id {
            max-width: calc(50px + 15px);
          }
          .title {
            width: 0;
          }
          .warning {
            justify-content: flex-end;
            label {
              font-style: italic;
              font-size: 12px;
              margin-right: 10px;
              text-align: right;
              @media (any-hover: hover) {
                &:hover {
                  cursor: pointer;
                }
              }
            }
            &-switcher {
              pointer-events: none;
              position: relative;
              background-color: $white;
              width: 30px;
              height: 20px;
              border: 2px solid #829395;
              border-radius: 15px;
              cursor: pointer;
              transition: 0.3s all ease-in-out;
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 4px;
                transform: translateY(-50%);
                background-color: #829395;
                width: 10px;
                height: 10px;
                border-radius: 20px;
                transition: 0.3s all ease-in-out;
              }
            }
            &-input {
              opacity: 0;
              width: 30px;
              height: 20px;
              position: absolute;
              @media (any-hover: hover) {
                &:hover {
                  cursor: pointer;
                }
              }
              &:checked {
                & + .warning-switcher {
                  background-color: #829395;
                  transition: 0.3s all ease-in-out;
                  &::before {
                    background-color: $white;
                    left: 12px;
                    transition: 0.3s all ease-in-out;
                  }
                }
              }
            }
          }
          .edit {
            max-width: calc(80px + 15px);
          }
        }
      }
    }
  }

  .loader {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
}
