@import "../../../../../assets/scss/__mandatory";

.own-modal {
  &.modal-confirmation {
    .own-modal-content {
      position: relative;
      align-items: flex-start;
      width: 100%;
      max-width: 594px;
      margin-top: 0;
      padding: 20px;
      text-align: center;
      background-color: white;
    }

    .btn-close {
      right: 20px;
      top: 20px;
      .btn-label {
        color: $color-deepblue-100;
      }
      .btn-icon {
        border-color: $color-deepblue-100;
      }
    }
    .uk-modal-header {
      padding: 0;
      border-bottom: none;

      h2 {
        color: $color-deepblue-100;
        strong {
          font-weight: 900;
          display: block;
        }
      }
    }
    .uk-modal-footer {
      padding: 0;
    }
    .uk-modal-body {
      padding: 0;
      color: $color-text;
      max-height: 480px;
      .text {
        max-width: 380px;
        margin: 0 auto 20px auto;
        font-size: 12px;
        line-height: 1.6;
        p {
          font-weight: 700;
          color: $color-alert;
          & + p {
            margin-top: 20px;
          }
        }
      }
      .wrapp-cta {
        margin: 20px auto;
      }
      &::after {
        display: none;
      }
    }
  }
}
