@import "../../../../assets/scss/__mandatory";

.tool-spv-reporting {
  color: $color-deepblue-100;
  max-width: 940px;
  margin: 0 auto;
  width: 100%;

  .btn-select {
    padding: 3px 10px !important;
    .btn-label + .btn-icon {
      margin-left: 10px;
    }
  }

  .project-name {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 20px;
    margin-bottom: 30px;
    h2 {
      font-weight: $ft-black;
      font-size: 20px;
    }
  }

  .review {
    margin-bottom: 50px;
    &_inner {
      border-radius: 23px;
      overflow: hidden;
      width: 100%;
      text-align: center;
      .top {
        padding: 15px 0;
        font-size: 20px;
        font-family: $font-title-wf;
        width: 100%;
        background-color: $white;
        b {
          color: $color-alert;
        }
      }
      .bottom {
        padding: 20px 0;
        p {
          font-size: 14px;
          margin-bottom: 10px;
          font-family: $font;
        }
      }
    }
    &.disabled {
      .bottom {
        background-color: #829395;
        color: $white;
      }
    }
    &.confirm {
      .bottom {
        background-color: $color-citron;
      }
    }
    &.valid {
      .bottom {
        background-color: $color-successBright;
        .btn-fake {
          width: 90px;
          margin: auto;
          color: white;
          background-color: #2b77a6;
          border: 2px solid #102d40;
          border-radius: 15px;
          padding: 3px 10px;
          min-height: 28px;
          cursor: default;
          .label {
            margin: auto;
            font-size: 12px;
            font-weight: 700;
          }
          .icon {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .tabs {
    &-container {
      &.MuiTabs-root,
      .MuiTabs-scroller.MuiTabs-fixed {
        overflow: visible !important;
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
    &-item {
      max-width: initial;
      min-width: auto;
      overflow: visible;
      position: relative;
      height: 50px;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba($color-deepblue-100, 0.5);
      font-family: $font-title-wf;
      font-weight: $ft-black;
      border-radius: 10px 10px 0px 0px;
      background-color: $color-softGrey;
      opacity: 1;
      &.Mui-selected {
        background-color: white;
        color: $color-deepblue-100;
      }
      .MuiTouchRipple-root {
        display: none;
      }
      &.disabled[data-attribute] {
        color: rgba($color-deepblue-100, 0.5);
        &:after {
          content: attr(data-attribute);
          position: absolute;
          bottom: -24px;
          width: max-content;
          background-color: rgba(#fbf1b1, 0.8);
          padding: 0 10px;
          text-transform: none;
          color: $color-deepblue-100;
          font-style: italic;
          font-weight: bold;
          font-size: 12px;
          opacity: 0;
          pointer-events: none;
          transition: 0.3s all ease-in-out;
        }
        @media (any-hover: hover) {
          &:hover {
            cursor: default;
            &:after {
              opacity: 1;
              transition: 0.3s all ease-in-out;
            }
          }
        }
      }
    }
  }

  .project-details-content_inner {
    background-color: white;
    width: 100%;
    font-family: $font;
  }
}
