@import "../../../__mandatory";

.filter {
  &__search {
    .filter__control {
      @include border-radius(40px);
      border-width: 2px;
      border-color: #d5e0e1;
      @include transitionElt(all, 0.25s, ease);
      &:hover {
        border-color: $color-deepblue-100;
      }
    }
    .filter__indicators {
      .filter__dropdown-indicator {
        svg {
          display: none;
        }
        &:before {
          content: "\f002";
        }
      }
    }
    .filter__value-container {
      padding: 0 15px;
      .filter__placeholder {
        font-size: 12px;
        color: $color-ocean;
      }
    }
    &__control--is-focused {
      .filter__control {
        border-color: $color-deepblue-100;
      }
    }
    .filter {
      &__menu {
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0);
        border-radius: 0;
        .filter__option {
          position: relative;
          padding-left: 24px;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            opacity: 1;
            border: 2px solid #91a2a3;
            width: 14px;
            height: 14px;
            @include border-radius(2px);
            z-index: 10;
          }
          &:after {
            content: "\f00c";
            font-family: $font-icon;
            position: absolute;
            top: 50%;
            left: 4px;
            // width: 15px;
            // height: 12px;
            transform: translateY(-50%);
            font-size: 10px;
            margin-top: -1px;
            opacity: 0;
          }
          &.filter__option--is-focused {
            background-color: transparent;
          }
          &.filter__option--is-selected {
            background-color: transparent;
          }
          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
      &__menu-list {
      }
    }
  }

  &__control {
    position: relative;
    border-color: transparent;
    @include border-radius(40px);
    box-shadow: none !important;

    &.filter__control--is-menu-open {
      .filter__dropdown-indicator {
        &:before {
          content: "\f068";
        }
      }

      // &__dropdown-indicator {

      //     background-color: red;
      //     svg {
      //         display: none;
      //     }
      //     &:before {
      //         font-family: "Font Awesome 5 Pro";
      //         content: "\f067";
      //         color: $color-blue;
      //     }
      // }
    }
  }
  &__control--is-focused {
    border-color: transparent;
  }
  &__control--menu-is-open {
    .filter__dropdown-indicator {
      &:before {
        //content: "\f068";
      }
    }
  }

  &__control--is-menu-open {
  }
  &__indicator-separator {
    display: none;
  }

  &__value-container {
    padding: 0;
    width: 100%;
    align-items: flex-start;
    .filter__single-value {
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 30px;
      margin-left: 0;
      @media screen and (min-width: $bp-tablet) {
        padding-right: 24px;
        font-size: 13px;
      }
    }
    input {
      width: 100%;
      height: 24px;
      font-size: 16px !important;
      @media screen and (min-width: $bp-tablet) {
        font-size: 13px !important;
      }
    }
  }
  &.filter__icon__ {
    .filter__input {
      pointer-events: none;
    }
    .filter__value-container {
      .filter__placeholder {
        // color: $text-light;
      }
    }
  }

  &__indicators {
    height: 38px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__dropdown-indicator {
    color: $text !important;
    svg {
      //display: none;
      // position: absolute;
      // left: 100%;
    }
    &:before {
      font-family: "Font Awesome 5 Pro";
      //content: "\f067";
    }
  }
  &__single-value {
    // background-color: $color-blue;
    // color: white;

    padding: 0 8px;
    border-radius: 2px;
    font-size: 12px;
  }

  &__menu {
    margin: 0 auto;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0);
    border-radius: 0;
    .filter__option {
      color: inherit;
      font-size: 13px;
      height: auto;
      min-height: 26px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      &:hover {
        background-color: transparent;
        &:before {
          opacity: 1;
        }
      }
    }
    .filter__option--no-options {
      background-color: red !important;
    }
  }

  &__option {
    text-align: left;
    background-color: white;
    position: relative;
    color: $text;
    font-size: 13px;
    font-weight: 600;
    min-height: 28px;
    cursor: pointer;
    &--is-focused {
      background-color: $color-blue;
      color: white;
    }
  }

  &__group {
    padding: 0;
  }

  &__indicator {
  }

  &__noclose {
    .filter__clear-indicator {
      display: none;
    }
  }
  &__clear-indicator {
    position: absolute;
    left: 100%;
    width: 20px;
    height: 20px;
    background-color: #666666;
    border-radius: 50%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    svg {
      display: none;
    }
    &:before {
      font-family: "Font Awesome 5 Pro";
      content: "\f00d";
      color: white;
      text-align: center;

      margin: auto;
      font-size: 10px;
    }
  }
  &__control {
    color: $black;
    font-size: 13px;
    font-weight: 600;
    &:hover {
      opacity: 0.8;
    }
  }
}
