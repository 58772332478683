@import "../../../../../../assets/scss/_mandatory";

.modal {
  &-filters-amp {
    .uk-modal-dialog {
      width: 100%;
      height: 100%;
      display: flex;
      margin: auto;
      align-items: center;
      background: transparent;
    }
    .modal_inner {
      min-height: 506px;
      background-color: $color-ocean;
    }
  }
  &_inner {
    position: relative;
    width: 100%;
    max-width: 940px;
    min-height: 100px;
    margin: auto;
    background: white;
  }
  &__body {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    min-height: calc(506px - 104px);
    .col {
      width: 100%;
      &-listing {
        width: 100%;
        background-color: $color-ocean;
        @media screen and (min-width: $bp-tablet) {
          max-width: 352px;
        }
        .listing {
          &_head {
            height: 50px;
            background-color: $color-ocean;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              margin: auto 10px;
              cursor: pointer;
              i {
                font-size: 16px;
              }
            }
            .label {
              font-family: $font-title-wf;
              font-weight: 900;
              font-size: 18px;
              text-transform: uppercase;
            }
          }
          &_body {
            background-color: white;
            height: calc(100% - 50px);
            padding: 15px 30px;
          }
        }
      }
      .cmp {
        &-list-collapse {
          padding: 15px 0;
        }
        &-search {
        }
      }
      .search {
        &_label {
          color: $color-deepblue-100;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 4px;
        }
        .filter {
          &__single-value {
            display: none;
          }
        }
      }
      .accordion {
        font-family: $font-title-wf;
        & + .accordion {
          margin-top: 0;
        }
        &.uk-open {
          .accordion_head {
            &:not(.no-data) {
              background-color: $color-deepblue-100;
              color: white;

              small {
                font-weight: 900;
              }
            }
          }
        }
        &:not(.uk-open) {
          .accordion_body {
            display: none;
          }
        }
        &_head {
          position: relative;
          display: flex;
          width: 100%;
          height: 32px;
          color: $color-deepblue-100;
          background-color: white;
          align-items: center;
          padding: 0 10px;
          border-bottom: 1px solid #d5e0e1;
          margin-top: 0;
          &.no-data {
            pointer-events: none;
            span {
              text-transform: uppercase;
              font-size: 14px;
              small {
                font-size: 80%;
                font-weight: 400;
              }
            }
          }
        }
        &__label {
          padding-right: 24px;
          font-weight: 900;
          text-transform: uppercase;
          font-size: 14px;
          small {
            font-size: 80%;
            font-weight: 400;
          }
        }
        &__icon {
          font-size: 12px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
        }
        &_body {
          width: 100%;
          margin-top: 0;
          padding: 5px 0;
          .checkbox {
            &__label {
              font-weight: 700;
              font-size: 12px;
              margin-left: 5px;
            }
            &__like {
              border: 2px solid #d5e0e1;
              width: 16px;
              height: 16px;
              @include border-radius(2px);
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              i {
                font-size: 10px;
              }
            }
          }
          .row {
            &-lvl1 {
              input {
                appearance: auto !important;
              }
              .checkbox {
                &__like {
                  background-color: $color-deepblue-100;
                  color: white;
                  border-color: $color-deepblue-100;
                }
              }
            }
            &-lvl2 {
              margin-left: 20px;
              .checkbox {
                &__label {
                  @include border-radius(15px);
                  padding: 2px 5px;
                }
              }
              .input {
                input {
                  &:checked {
                    & ~ .checkbox__label {
                      background-color: $color-ocean;
                      color: white;
                    }
                  }
                }
                &:hover {
                  .checkbox {
                    &__label {
                      background-color: $color-deepblue-100;
                      color: white;
                    }
                  }
                  input {
                    &:checked {
                      & ~ .checkbox__label {
                        background-color: $color-deepblue-100;
                        color: white;
                      }
                    }
                  }
                }
              }
            }
            & + .row {
              margin-top: 4px;
            }
            .input {
              width: 100%;
              display: flex;
              align-items: center;
              input {
                height: 0;
                width: 0;
                visibility: hidden;
                appearance: none;
                -webkit-appearance: none;
                &:checked {
                  & ~ .checkbox__like {
                    background-color: $color-deepblue-100;
                    color: white;
                    border-color: $color-deepblue-100;
                  }
                }
              }
            }
          }
        }
        .no-data {
          height: 32px;
          padding: 0 10px;
        }
      }
      &-map {
        position: relative;
        background-color: $color-sea;
        display: none;
        padding: 0px;
        @media screen and (min-width: $bp-tablet) {
          display: block;
          width: calc(100% - 352px);
        }
        .highcharts-root,
        .chartContainer {
          width: 100%;
          height: 100%;
        }
        .btn {
          &__remote {
            background-color: white;
            width: 24px;
            height: 24px;
            @include border-radius(50%);
            .btn__icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            svg {
              width: 18px;
              height: 18px;
              margin: auto;
            }
          }
          &__map-reset {
            & + .btn {
              margin-left: 30px;
            }
          }
          &__map-zoomout {
            & + .btn {
              margin-left: 5px;
            }
          }
        }
      }
      .remote {
        pointer-events: none;
        &:before {
          content: "";
          display: block;
          height: 3px;
          width: 100%;
          background-color: white;
          margin-bottom: 10px;
        }
        &-map {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 10px 30px;
          .buttons {
            width: 130px;
            display: flex;
            flex-flow: wrap;
          }
          .legends {
            width: calc(100% - 130px);
            display: flex;
            flex-flow: wrap;
            justify-content: flex-start;
            align-items: center;
            padding-left: 30px;
            .legend {
              margin: auto 0;
              font-size: 12px;
              font-style: italic;
              display: flex;
              flex-flow: wrap;
              & + .legend {
                margin-left: 15px;
              }
              &__icon {
                width: 12px;
                height: 12px;
                @include border-radius(50%);
                border: 1px solid $color-deepblue-100;
                display: block;
                & + .legend__label {
                  margin-left: 5px;
                }
              }
              &-available {
                .legend__icon {
                  background-color: $color-ocean;
                }
              }
              &-selected {
                .legend__icon {
                  background-color: $color-sky;
                }
              }
              &-unavailable {
                .legend__icon {
                  background-color: white;
                }
              }
            }
          }
        }
        &_inner {
          position: relative;
          width: 100%;
          display: flex;
        }
      }
    }
  }
  &__foot {
    position: relative;
    width: 100%;
    color: white;
    .btn-apply {
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .foot_inner {
      width: 100%;
      padding: 15px 30px;
    }
    .row {
      &-labels {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #5592b8;
        display: flex;
        justify-content: space-between;
        .label {
          text-transform: uppercase;
          font-weight: 300;
          span {
            strong {
              font-family: $font-title-wf;
              font-weight: 900;
            }
          }
        }
        .action {
          font-size: 12px;
          cursor: pointer;
          i {
            margin-left: 5px;
          }
        }
      }
      &-filters {
        position: relative;
        width: 100%;
        margin-top: 10px;
        min-height: 30px;
        padding-right: 90px;

        ul {
          width: 100%;
          display: flex;
          flex-flow: wrap;
          li {
            //padding: 0 5px 5px 5px;
            // margin: 0 0 5px;
            & + li {
              //margin-left: 10px;
            }
          }
        }
        .filter {
          &-country {
            padding: 0 0 5px 5px;
            .filter_inner {
              background-color: $color-deepblue-100;
              position: relative;
              display: inline-flex;
              align-items: center;
              align-items: center;
              padding-right: 24px;
              @include border-radius(20px);
              min-height: 28px;
            }
          }
          &_inner {
            padding: 0 10px;
          }
          &__label {
            font-size: 12px;
            margin: auto 0;
          }
          &__icon {
            position: absolute;
            top: 50%;
            right: 0;
            width: 24px;
            transform: translateY(-50%);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            i {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.chart-map-filters {
  width: 100%;
  height: 100%;
  .highcharts-root {
    width: 100%;
    //height: 100%;
  }
  .highcharts-background {
    stroke-width: 0;
    fill: none;
  }
  // .highcharts-reset-zoom {
  //   display: none;
  // }
  .highcharts-map-series .highcharts-point {
    stroke-width: 0.1px !important;
    stroke: $color-deepblue-100 !important;
  }
  .highcharts-point {
    &.highcharts-color-0 {
      // fill: red;
      &.highcharts-null-point {
        fill: white !important;
        stroke: $color-sky !important;
      }
      &:not(.highcharts-null-point) {
        // fill: red;
      }
    }
    &.highcharts-color-1 {
      // fill: red;
      &.highcharts-null-point {
        fill: white !important;
        stroke: $color-deepblue-100 !important;
      }
      &:not(.highcharts-null-point) {
        fill: $color-ocean !important;
        &:hover {
          fill: $color-sky !important;
        }
      }
    }
    &.highcharts-color-2 {
      // fill: red;
      &.highcharts-null-point {
        fill: none !important;
        fill-opacity: 0;
        stroke: none !important;
        stroke-opacity: 0 !important;
      }
      &:not(.highcharts-null-point) {
        fill: $color-sky !important;
        &:hover {
          fill: $color-sky !important;
        }
      }
    }
  }
  .highcharts-button {
    &.highcharts-map-navigation {
      border: none;
      background-color: transparent;
    }
    text {
      fill: $color-deepblue-100;
    }
    &.highcharts-zoom-in,
    &.highcharts-zoom-out {
      stroke-opacity: 0;
      fill-opacity: 0;
      position: absolute;
      bottom: 10px;
      cursor: pointer;
    }
    &.highcharts-zoom-in {
      transform: translateX(128px) translateY(425px);
    }
    &.highcharts-zoom-out {
      transform: translateX(93px) translateY(425px);
    }
    &.highcharts-reset-zoom {
      transform: translateX(30px) translateY(423px);
      stroke-opacity: 0;
      fill-opacity: 0;
      rect {
        width: 30px;
        height: 30px;
      }
      text {
        display: none;
      }
    }
  }
}
