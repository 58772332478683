@import "../../../assets/scss/__mandatory";

.page {
  &-user {
    width: 100%;

    .page {
      &_inner {
        position: relative;
        width: 100%;
        margin: 0 auto;
        min-height: calc(100vh - 80px - 120px);

        .page_header {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          max-width: 940px;
          margin: 0 auto;
          padding: 30px 10px 15px 10px;
          &__title {
            color: $color-deepblue-100;
            font-family: $font-body-wf;
            font-weight: $ft-regular;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
          }
        }

        .popup {
          &-overlay {
            overflow-y: scroll;
            background-color: rgba(16, 45, 64, 0.95) !important;
          }
          &-content {
            width: 100% !important;
            max-width: 940px;
            padding: 0 !important;
          }
        }
      }
    }
  }
}
