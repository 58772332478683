@import "../../../../../../assets/scss/_mandatory";

// Switcher button
.switcher {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &-currency {
    .cmp___labels {
      background-color: $color-ocean;
      border: 2px solid $color-deepblue-100;
      border-radius: 20px;
      min-height: 28px;
      padding: 0 10px;
      color: white;
      & + .switcher__currency {
        margin-left: 10px;
      }
    }
    .switcher__currency {
      display: none;
      @media screen and (min-width: $bp-tablet) {
        display: flex;
      }
      font-size: 12px;
      color: $color-ocean;
    }
  }

  input {
    &[type="checkbox"] {
      &.cmp__switcher {
        height: 0;
        width: 0;
        visibility: hidden;
        appearance: none;
        -webkit-appearance: none;
        &:not(:checked) {
          & + .cmp___labels {
            .label__off {
              font-weight: 700;
              span {
              }
            }
          }
          & ~ .switcher__like {
            background-color: $white;
          }
        }
        &:checked {
          & + .cmp___labels {
            .label__on {
              font-weight: bold;
              span {
              }
            }
          }
          & + .cmp___labels {
            .switcher__like {
              &:after {
                left: 3px;
                transform: translateY(-50%);
                // left: calc(100% - 3px);
                // transform: translateX(-100%) translateY(-50%);
              }
            }
          }
        }
      }
    }
  }

  .cmp {
    &___labels {
      display: flex;
      align-items: center;
    }
  }

  .switcher {
    &-currency {
      margin-left: 10px;
    }

    &__label,
    &__like {
      cursor: pointer;
    }

    &__label {
      // padding-left: 25px;
      // padding-right: 10px;
      // padding: 0 5px;
      // color: $color-grey;
      // font-size: 18px;
      // margin-top: auto;
      // margin-bottom: auto;
      &.label__on {
        // padding-right: 15px;
      }
      &.label__off {
        // padding-left: 15px;
      }
    }
    &__like {
      // position: absolute;
      // top: 0;
      // left: 0;
      // background-color: #3C78C8;
      // color: #234678;
      // width: 40px;
      // height: 20px;
      // @include border-radius(50%);
      // overflow: hidden;
      margin: 0 5px;
      cursor: pointer;
      text-indent: -9999px;
      width: 40px;
      height: 20px;
      display: block;
      border-radius: 100px;
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
      overflow: hidden;
      background-color: $white;

      svg {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 95px;
        height: 17px;
        transform: translateX(-50%);
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(100% - 3px);
        transform: translateX(-100%) translateY(-50%);
        // left: 3px;
        // transform: translateY(-50%);
        background: #fff;
        @include border-radius(90px);
        box-sizing: border-box;
        height: 15px;
        width: 15px;
        border: 1px solid #e0e7ff;
        background: $color-ocean;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
        transition: 0.3s;
      }
      &:hover {
        &:after {
          opacity: 0.8;
        }
      }
      &:active {
        &:after {
          width: 30px;
        }
      }
    }
  }
}
