@import "../../../../assets/scss/__mandatory";

.tool-spv-manager-form {
  color: $color-deepblue-100;
  max-width: 940px;
  margin: 0 auto;
  width: 100%;

  .content-head,
  .content-body {
    background-color: white;
    padding: 24px 20px;
    width: 100%;
    font-family: $font;

    .row-input {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      .label {
        font-weight: $ft-bold;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 5px;
      }
      input[type="text"], input[type="number"] {
        width: 100%;
        min-height: 30px;
        padding: 0px 10px;
        border-radius: 20px;
        border: 2px solid $color-softGrey;
        font-size: 14px;
        font-weight: $ft-bold;
        color: $color-deepblue-100;
        background-color: white;
        transition: all 0.25s ease;
        &.is-modified {
          background-color: $color-sea;
        }
        &:placeholder-shown {
          font-size: 14px;
          color: $color-ocean;
          background-color: white;
        }
      }
      .selector {
        width: 100%;
        .drop-select {
          ul {
            max-height: 200px;
            overflow-y: scroll;
          }
        }
      }

      .btn-users {
        padding: 0 5px 0 15px;
        .btn-icon {
          font-size: 12px;
        }
      }

      & + .row-input {
        margin-top: 15px;
      }
    }
  }

  .content-head {
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (min-width: $bp-tablet) {
      flex-flow: row nowrap;
    }
    .left {
      width: 100%;
      @media screen and (min-width: $bp-tablet) {
        width: 50%;
      }
      .title {
        font-weight: $ft-black;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        & + .row-input {
          margin-top: 15px;
        }
      }
      input {
        &[readonly] {
          pointer-events: none;
          cursor: none;
          border-color: $black;
          color: $white;
          background-color: $black;
        }
      }
    }

    .right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      @media screen and (min-width: $bp-tablet) {
        width: 50%;
      }
    }
  }

  .year-selector {
    width: 100%;
    padding: 10px 20px 20px 20px;
    border-bottom: 3px solid $color-sea;
    margin-bottom: 20px;
    .only-one {
      background-color: $color-cobalt;
      cursor: default;
    }
  }

  .tabs {
    &-container {
      &.MuiTabs-root,
      .MuiTabs-scroller.MuiTabs-fixed {
        overflow: visible !important;
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
    &-item {
      overflow: visible;
      position: relative;
      height: 50px;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-deepblue-100;
      font-family: $font-title-wf;
      font-weight: $ft-black;
      border-radius: 10px 10px 0px 0px;
      background-color: $color-softGrey;
      opacity: 1;
      &.Mui-selected {
        background-color: white;
      }
      .MuiTouchRipple-root {
        display: none;
      }
      &.disabled[data-attribute] {
        color: rgba($color-deepblue-100, 0.5);
        &:after {
          content: attr(data-attribute);
          position: absolute;
          bottom: -24px;
          width: max-content;
          background-color: rgba(#fbf1b1, 0.8);
          padding: 0 10px;
          text-transform: none;
          color: $color-deepblue-100;
          font-style: italic;
          font-weight: bold;
          font-size: 12px;
          opacity: 0;
          pointer-events: none;
          transition: 0.3s all ease-in-out;
        }
        @media (any-hover: hover) {
          &:hover {
            cursor: default;
            &:after {
              opacity: 1;
              transition: 0.3s all ease-in-out;
            }
          }
        }
      }
    }
  }

  .content-body {
    .separator {
      width: 100%;
      height: 3px;
      background-color: $color-sea;
      margin: 20px 0;
    }
    input[type="text"] {
      max-width: 225px;
    }
    input[type="number"] {
      max-width: 225px;
    }
    .btn-select {
      max-width: 225px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-label {
        margin: 0;
      }
      .btn-icon {
        width: 20px;
        margin-left: 10px;
      }
      &.is-modified {
        color: $color-deepblue-100;
        background-color: $color-citron;
      }
      &.uk-open {
        .btn_icon {
          &:before {
            content: "\f0d8";
          }
        }
      }
    }

    .project-infos {
      .list {
        margin-bottom: 30px;
        .row {
          width: 100%;
          min-height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          div + div {
            margin-left: 10px;
            @media screen and (min-width: $bp-tablet) {
              margin-left: 15px;
            }
          }

          .fund,
          .fund-committed,
          .currency {
            width: 100%;
            min-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font;
          }

          .fund {
            width: calc(100% - 100px - 150px);
            justify-content: flex-start;
          }
          .fund-committed {
            max-width: 100px;
            width: 100%;
          }
          .currency {
            max-width: 150px;
            width: 100%;
            justify-content: flex-end;
            .selector {
              max-width: 100px;
              width: 100%;
              .drop-select ul {
                max-height: 200px;
                overflow-y: scroll;
              }
            }
          }

          &:not(.filters) {
            padding: 10px 15px;
            border-radius: 0px 25px 25px 0px;

            .fund {
              font-size: 14px;
              line-height: 1.2;
            }

            .fund-committed {
              position: relative;
              input {
                max-width: 120px;
                width: 100%;
                border: 2px solid #829395;
                border-radius: 15px;
                background-color: $white;
                padding: 5px;
                font-family: $font;
                font-weight: $ft-bold;
                font-size: 12px;
                text-align: center;
                color: $color-deepblue-100;
                @media screen and (min-width: $bp-tablet) {
                  padding: 5px 10px;
                  font-size: 14px;
                }
              }
              .tooltip {
                position: absolute;
                top: -10px;
                width: max-content;
                background-color: rgba(#fbf1b1, 0.9);
                padding: 0px 10px 3px 10px;
                &-label {
                  font-style: italic;
                  font-weight: bold;
                  font-size: 12px;
                }

                opacity: 0;
                pointer-events: none;
                transition: 0.3s all ease-in-out;
              }
            }

            &:nth-child(even) {
              background-color: $color-seaLight;
              .name {
                background-color: $white;
              }
            }
          }
          &.filters {
            .fund {
              width: calc(100% - 120px - 165px + 15px);
              justify-content: flex-start;
            }
            .currency {
              max-width: calc(150px + 15px);
              width: 100%;
              padding-right: 20px;
            }
            i {
              font-size: 12px;
            }
            .btn-filter {
              min-height: 20px;
              background-color: $color-cobalt;
              border: 2px solid $color-cobalt;
              border-radius: 15px;
              padding: 0 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: $white;
              font-weight: $ft-bold;
              font-size: 12px;
              .btn_icon {
                margin-left: 5px;
              }
            }
          }

          &.is-modified {
            background-color: rgba($color-citron, 0.5) !important;
          }

          & + .row {
            margin-top: 4px;
          }
        }
      }
    }

    .materiality {
      .search-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .tool-search {
          width: 100%;
          max-width: 470px;
          text-align: right;
          .input {
            position: relative;
            width: 100%;
            input {
              height: 28px;
              padding: 0px 10px;
              border-radius: 20px;
              border: 2px solid #d5e0e1;
              width: 100%;
              font-size: 14px;
              color: $color-ocean;
              background-color: white;
              transition: all 0.25s ease;
              &::placeholder {
                font-size: 14px;
                color: $color-ocean;
              }
              &:focus,
              &.filled {
                background-color: $color-seaLight;
              }
            }
            &-icon {
              font-weight: $ft-black;
              font-size: 14px;
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              .fa-search {
                color: $color-ocean;
              }
              .fa-times {
                color: $color-alert;
                cursor: pointer;
              }
            }
          }
        }
        .selected-indicators {
          font-size: 14px;
          color: $color-ocean;
          font-family: $font;
          span {
            font-weight: $ft-bold;
          }
        }
      }

      .label {
        font-size: 12px;
      }
      .list {
        .row {
          width: 100%;
          min-height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          div + div {
            margin-left: 10px;
            @media screen and (min-width: $bp-tablet) {
              margin-left: 15px;
            }
          }

          .theme,
          .subtheme,
          .id,
          .target,
          .indicators,
          .select {
            width: 100%;
            min-height: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .theme {
            max-width: 100px;
          }

          .subtheme {
            max-width: 130px;
          }

          .id {
            max-width: 70px;
          }

          .target {
            max-width: 40px;
          }

          .select {
            max-width: 60px;
            justify-content: space-between;
          }

          &:not(.filters) {
            padding: 10px 15px;
            font-weight: bold;
            font-size: 12px;
            font-family: $font;
            border: 1px solid $color-sea;
            border-radius: 0px 25px 25px 0px;
            color: $color-deepblue-100;
            &.checked {
              background-color: $color-ocean;
              border-color: $color-ocean;
              color: white;
            }
            .indicators {
              font-weight: normal;
            }
            .select {
              position: relative;
              &-input {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                inset: 0;
                @media (any-hover: hover) {
                  &:hover {
                    cursor: pointer;
                  }
                }
                &:checked {
                  & + .box {
                    background-color: $color-ocean;
                    border: 2px solid $color-cobalt;
                    transition: 0.3s all ease-in-out;
                    i {
                      color: white;
                    }
                  }
                }
              }
              .box {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                background-color: $color-sea;
                border: 2px solid $color-softGrey;
                border-radius: 5px;
                transition: 0.3s all ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
                i {
                  font-size: 10px;
                  color: transparent;
                }
              }
            }
          }

          &.filters {
            i {
              font-size: 12px;
            }
            .btn-filter {
              min-height: 20px;
              background-color: $color-cobalt;
              border: 2px solid $color-cobalt;
              border-radius: 15px;
              padding: 0 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: $white;
              font-weight: $ft-bold;
              font-size: 12px;
              .btn_icon {
                margin-left: 5px;
              }
            }
            .subtheme,
            .id,
            .target {
              padding-left: 15px;
            }
            .target {
              max-width: 100px;
            }
            .indicators,
            .select {
              justify-content: flex-end;
              label {
                font-style: italic;
                font-size: 12px;
                margin-right: 10px;
                text-align: right;
                width: calc(100% - 50px);
                @media (any-hover: hover) {
                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
            .indicators {
              &-switcher {
                pointer-events: none;
                position: relative;
                background-color: $white;
                width: 30px;
                height: 20px;
                border: 2px solid #829395;
                border-radius: 15px;
                cursor: pointer;
                transition: 0.3s all ease-in-out;
                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 4px;
                  transform: translateY(-50%);
                  background-color: #829395;
                  width: 10px;
                  height: 10px;
                  border-radius: 20px;
                  transition: 0.3s all ease-in-out;
                }
              }
              &-input {
                opacity: 0;
                width: 30px;
                height: 20px;
                position: absolute;
                @media (any-hover: hover) {
                  &:hover {
                    cursor: pointer;
                  }
                }
                &:checked {
                  & + .indicators-switcher {
                    background-color: #829395;
                    transition: 0.3s all ease-in-out;
                    &::before {
                      background-color: $white;
                      left: 12px;
                      transition: 0.3s all ease-in-out;
                    }
                  }
                }
              }
            }
            .select {
              position: relative;
              min-width: 160px;
              margin-right: 15px;
              label {
                margin-right: 25px;
                pointer-events: none;
              }
              &-input {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 0;
                @media (any-hover: hover) {
                  &:hover {
                    cursor: pointer;
                  }
                }
                &:checked {
                  & + .box {
                    background-color: $color-ocean;
                    transition: 0.3s all ease-in-out;
                  }
                }
              }
              .box {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                background-color: transparent;
                border: 2px solid $color-cobalt;
                border-radius: 5px;
                transition: 0.3s all ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                pointer-events: none;
                i {
                  font-size: 10px;
                  color: white;
                }
              }
            }
          }

          & + .row {
            margin-top: 4px;
          }
        }
      }
    }

    .reporting {
      .activate {
        margin-bottom: 20px;
        input[type="checkbox"] {
          &:checked {
            & + label {
              &:before {
                content: "\f00c";
              }
            }
          }
        }
        label {
          &:before {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            font-size: 14px;
          }
        }
      }
      .choose {
        padding: 20px 0 10px 0;
        border-top: 3px solid $color-sea;
        p {
          font-weight: $ft-bold;
          font-size: 12px;
          margin-bottom: 15px;
        }
        &-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          input[type="radio"] {
            &:checked {
              & + label {
                &:before {
                  content: "\f111";
                }
              }
            }
          }
          label {
            &:before {
              width: 18px;
              height: 18px;
              border-radius: 20px;
              font-weight: $ft-black;
              font-size: 10px;
            }
          }
          & + .choose-item {
            margin-left: 50px;
          }
        }
      }
      input[type="checkbox"],
      input[type="radio"] {
        display: none;
        &:checked {
          & + label {
            color: $color-deepblue-100;
            &:before {
              background-color: $color-deepblue-100;
              border-color: $color-deepblue-100;
              color: $white;
            }
          }
        }
      }
      label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: $ft-bold;
        font-size: 14px;
        color: #829395;
        &:before {
          content: "";
          display: inline-block;
          background-color: $white;
          border: 2px solid $color-sea;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $font-icon;
        }
      }
    }
  }

  .loader {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
}
