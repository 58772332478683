@charset "utf-8";

/*/////////// CORE */ ////////////
@import "_variables";
@import "_colors";
@import "_settings";
@import "_breakpoints";
@import "_mixins";
@import "_path";

/*/////////// RESET */ ////////////
@import "_normalize";
@import "_reset";
@import "_generic";

// /*/////////// BASE */ ////////////
@import "_svg";
@import "_heading";
@import "_grid";
@import "_generic";
@import "_animations";

/*/////////// STRUCTURE */ ////////////
@import "core/_body";
@import "core/_buttons";
@import "core/_form";

@import "_font-awesome/fontawesome";

/*/////////// UI */ ////////////
// @import "custom/ui/tooltip";

/*/////////// COOKIE CONSENT */ ////////////
// @import "_cookiebot/_base";
// @import "_fix/cookiebot";

@import "custom/pages/_base";
@import "custom/boxes/_base";
@import "custom/cards/_base";

// @import "custom/sections/_base";
// @import "custom/banners/_base";
// @import "custom/charts/_base";

@import "../../../node_modules/uikit/src/scss/variables";
@import "../../../node_modules/uikit/src/scss/mixins";
// @import "_uikit/variables";
@import "_uikit/_import-custom";

@media screen {
  @import "core/_screen";
}

@import "custom/ui/tooltip";
