@import "../../../../assets/scss/__mandatory";
@import "../../../../assets/scss/custom/modal/base";

#modalPageMethodology {
  .btn-close {
    right: 20px;
    top: 20px;
    .btn-label {
      color: $color-deepblue-100;
    }
    .btn-icon {
      border-color: $color-deepblue-100;
    }
  }
  .own-modal-content {
    width: 100%;
    margin-top: 0;
    max-width: 734px !important;
    background-color: white;
    padding: 20px;

    align-items: flex-start;
  }
  .uk-modal-body {
    height: 300px !important;
  }
  .list {
    &_inner {
      h2 {
        font-size: 13px;
        font-weight: $ft-semibold;
        @media screen and (min-width: $bp-tablet) {
          font-size: 15px;
        }
      }
      + .list_inner {
        margin-top: 30px;
      }
    }
    &__content {
      li {
        padding: 10px 0 0 10px;
        font-size: 13px;
        @media screen and (min-width: $bp-tablet) {
          font-size: 14px;
        }
        & + li {
          padding-top: 15px;
        }
      }
    }
  }
}
