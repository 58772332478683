@import "../../../../assets/scss/__mandatory";
@import "../../../../assets/scss/_highcharts/highcharts";

.tool_allocation-by-asset {
  @media screen {
    padding: 0 20px;
  }
  @media screen and (min-width: $bp-desk-small) {
    padding: 0;
  }
  $colors: $color-deepblue-100 #f7a35c #8085e9 #f15c80 #e4d354 #2b908f #f45b5b;
  @import "../../../../assets/scss/_highcharts/custom";

  .inner-chart {
    > div {
      overflow: visible !important;
      .highcharts-container {
        overflow: visible !important;
      }
    }
  }

  .highcharts-column-series, .highcharts-bar-series {
    rect.highcharts-point {
      outline-width: 0;
      outline-color: $color-deepblue-100;

      &:hover {
        fill: $color-deepblue-100;
        opacity: 0.5;
      }
    }
  }
  .highcharts-axis-line {
    stroke: $color-deepblue-100;
    stroke-width: 2px;
  }
  .highcharts-axis {
    &.highcharts-xaxis {
      stroke: $color-deepblue-100;
      stroke-width: 2px;
    }
  }

  .tool_lp_inner {
    @media screen {
      background-color: $color-citron;
    }
    @media print {
      height: auto;
      max-height: 420px;
    }
    .tool {
      &__content {
        padding: 40px 0 0 0;
      }
    }

    .bar__horizontal {
      .highcharts-xaxis-labels {
        & > span {
          width: 140px !important;
          min-height: 26px;
          left: 160px !important;
        }
      }
      .highcharts-yaxis-labels {
        span {
          transform: translateX(-50%);
        }
      }
    }

    .highcharts-xaxis-labels {
      & > span {
        pointer-events: none;
        &:not(.label_name):not(.label_icon) {
          min-width: 80px;
        }
      }
    }

    .highcharts-yaxis-labels {
      fill: $color-deepblue-100;
      color: $color-deepblue-100;
    }
    .axis__labels {
      position: relative;
      &.axis__labels__x {
        width: 100%;
        text-align: center;
        min-height: 60px;
        .axis__label {
          width: 100%;
          left: 50%;
          min-width: 60px;
          transform: translateX(-50%);
        }
      }
      &.axis__labels__y {
        text-align: right;
        min-height: 26px;
        width: 80px;
        transform: translateX(-100%);
        &.horizontal {
          width: 140px;
        }
      }
      .axis__label {
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        &.axis__label__x {
          width: 100%;
          .label {
            &_icon {
              i {
                font-family: $font-icon;
                font-size: 20px;
              }
            }
            &_name {
              font-weight: 400;
              font-size: 12px;
              width: 100%;
              text-transform: none;
              word-break: break-word;
              display: block;
              padding-top: 5px;
              white-space: initial;
            }
          }
        }
        &.axis__label__y {
          display: flex;
          width: 100%;
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: center;
          text-align: right;
          .label {
            &_icon {
              width: 30px;
              display: block;
              i {
                font-size: 18px;
              }
            }
            &_name {
              width: 50px;
              font-weight: 400;
              font-size: 11px;
              text-transform: none;
              // word-break: break-word;
              display: block;
              padding-top: 0;
              white-space: initial;
              text-align: right;
            }
          }
          &.horizontal {
            .label {
              &_name {
                width: 140px;
                font-size: 9px;
                @media screen and (min-width: $bp-tablet) {
                  font-size: 10px;
                }
              }
              &_icon {
                width: 16px;
                margin-left: 6px;
                i {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tooltip-chart-allocationByAssets {
  color: $color-deepblue-100;
  .tooltip {
    &_icon {
      font-size: 16px;
      img {
        width: 16px;
        height: 16px;
        filter: invert(0.9) sepia(1) saturate(3) hue-rotate(200deg);
      }
    }
    &_label {
      font-size: 12px;
      font-weight: normal;
    }
    &_value {
      margin-top: 12px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
