@import "../../../../assets/scss/__mandatory";

.container {
  &_buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    p {
      font-family: $font;
      font-size: 12px;
      line-height: 20px;
      color: $color-deepblue-100;
      width: 100%;
      margin-bottom: 15px;
      @media screen and (min-width: $bp-smartphone-medium) {
        width: auto;
        margin-bottom: 0;
        + .btn {
          margin-left: 10px;
        }
      }
    }
    .btn.btn-select {
      padding: 0 10px;
      .btn-icon {
        width: 20px;
        margin-left: 10px;
      }
      + .btn {
        margin-left: 10px;
      }
    }
  }
}
