@import "../../../../assets/scss/__mandatory";

.selector {
  &-label {
    font-weight: $ft-bold;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .select {
    position: relative;
    :global {
      .btn {
        width: 100%;
        max-width: 225px;
      }
    }
    .drop {
      max-width: 225px;
      .group {
        padding: 10px 0;
        .item {
          padding: 0 10px;
          .full {
            width: 100%;
          }
        }
      }
    }
  }
}

.content-head {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
  @media screen and (min-width: $bp-tablet) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    width: auto;
    h2 {
      font-weight: $ft-black;
    }
  }
  .btn.btn-select {
    padding: 0 10px;
    margin-top: 15px;
    @media screen and (min-width: $bp-tablet) {
      margin-top: 0;
    }
    .btn-icon {
      width: 20px;
      margin-left: 10px;
    }
  }
  .tool-search {
    width: 100%;
    text-align: right;
    margin-top: 15px;
    @media screen and (min-width: $bp-tablet) {
      width: 40%;
      margin-top: 0;
    }
    .input {
      position: relative;
      input {
        height: 28px;
        padding: 0px 10px;
        border-radius: 20px;
        border: 2px solid #d5e0e1;
        width: 100%;
        font-size: 14px;
        color: $color-ocean;
        background-color: white;
        transition: all 0.25s ease;
        &::placeholder {
          font-size: 14px;
          color: $color-ocean;
        }
        &:focus,
        &.filled {
          background-color: $color-seaLight;
        }
      }
      &-icon {
        font-weight: $ft-black;
        font-size: 14px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        .fa-search {
          color: $color-ocean;
        }
        .fa-times {
          color: $color-alert;
          cursor: pointer;
        }
      }
    }
  }
}
