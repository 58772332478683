@import "../../../../assets/scss/__mandatory";

// @page {
//   @bottom-right {
//     content: "Page " counter(page) " of " counter(pages);
//   }
// }

.defs-svg {
  display: none !important;
}

@page pageTable {
  margin: 1cm 0 1cm 0;
}

@page pageTableInner {
  size: 277mm 190mm;
  // size: A4 landscape ;
}

// @page fixedHead {
//   position: fixed;
//   top: 0;
//   left: 0;
//   background-color: green;
//   z-index: 5;
// }
// @page fixedHead:first {
//   position: initial;
//   top: 0;
//   left: 0;

//   background-color: orange;
//   z-index: 5;
// }

// @page :bottom {
//   content: "Page " counter(page) " of " counter(pages);
// }
@page pageTable:first {
  margin: 1cm 0 1cm 0;
  // padding: 4cm !important;
}

.tool_carbon-footprint {
  padding: 0 20px;
  @media print {
    padding: 0;
    font-family: $font-body-wf;
  }
  @media screen and (min-width: $bp-tablet) {
    padding: 0;
  }
  .tool_lp_inner {
    position: relative;
    @import "../../../../assets/scss/custom/tables/base";
    @import "../../../../assets/scss/custom/tables/lp/carbon-footprint";
    @media screen {
      background-color: $color-ocean;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        pointer-events: none;
        height: 100%;
        background: -moz-linear-gradient(90deg, rgba(16, 45, 64, 0) 0%, rgba(16, 45, 64, 0.5) 100%);
        background: -webkit-linear-gradient(90deg, rgba(16, 45, 64, 0) 0%, rgba(16, 45, 64, 0.5) 100%);
        background: linear-gradient(90deg, rgba(16, 45, 64, 0) 0%, rgba(16, 45, 64, 0.5) 100%);
      }
      @media screen and (min-width: $bp-tablet) {
        content: initial;
      }
    }



    .tool {
      &__content {
        padding: 10px 0 0 0;
      }
      &__actions {
        .actions_inner {
          display: flex;
          justify-content: space-between;
          .export {
            .label {
              color: $white;
            }
            .btn {
              display: flex;
              padding: 0 10px;
              &-select {
                .btn_label {
                  padding: 0;
                }
                .btn_icon {
                  &:before {
                    display: none;
                  }
                }
              }
              &.btn-download-pdf {
                .btn_label {
                  font-size: 12px;
                }
              }
            }
            & + .export {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .tool__title {
      h2 {
        @media print {
          color: $color-deepblue-100;
        }
      }
      h3 {
        font-weight: 400;
        color: $color-deepblue-100;
        text-transform: uppercase;
        font-size: 18px;
        margin-top: 5px;
      }
    }
    .filter {
      &__bar {
        padding: 0 0 15px 0;
        text-align: center;
        @media screen and (min-width: $bp-tablet) {
          transform: translateY(-36px);
          text-align: right;
        }
        .switcher {
          display: inline-flex;
          margin-right: auto;
          margin-left: auto;
          background-color: white;
          color: $color-deepblue-100;
          padding: 2px 10px 2px 5px;
          @include border-radius(20px);
          @media screen and (min-width: $bp-tablet) {
            margin-right: 0;
          }
          &__label {
            font-size: 12px;
          }
          .label {
            &__on {
              font-style: italic;
              font-weight: 700;
            }
          }
          &__like {
            background-color: $color-deepblue-100 !important;
            &:after {
              background-color: white;
              box-shadow: none;
            }
          }

          input[type="checkbox"] {
            .cmp__switcher {
              &:checked {
                ~ .switcher__like {
                  background-color: $color-deepblue-100;
                }
              }
              &:not(:checked) {
                ~ .switcher__like {
                  background-color: $color-deepblue-100;
                }
              }
            }
          }
        }
      }
    }
    sub {
      vertical-align: sub;
    }
  }
}
