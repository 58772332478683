@import "../../../../../assets/scss/__mandatory";

.card {
  // &.card-1-1 {
  //     @media screen and (min-width: $bp-tablet) {
  //       width: 25%;
  //     }
  //   }
  //   &.card-1-2 {
  //     @media screen and (min-width: $bp-tablet) {
  //       width: 50%;
  //     }
  //   }

  &-tool {
    .card {
      &_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        padding-top: 0px;
        height: 100%;
        @include transitionElt(all, 0.3s, ease);
        position: relative;
        padding: 20px 10px;
        color: white;
        @media screen and (min-width: $bp-tablet) {
          padding: 20px;
        }
        .icon {
          position: relative;
          top: 0;
          left: 0;
          width: 80px;
          max-width: none;
          height: 100%;
          text-align: center;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          @media screen and (min-width: $bp-tablet) {
            width: 120px;
          }
          svg {
            margin: auto;
            &.icon-sdg-impact-synthesis {
              width: 141 * 0.75 * 1px;
              height: 141 * 0.75 * 1px;
              @media screen and (min-width: $bp-tablet) {
                width: 141 * 1 * 1px;
                height: 141 * 1 * 1px;
              }
            }
            &.icon-sdg-contribution {
              width: 160 * 0.75 * 1px;
              height: 82 * 0.75 * 1px;
              @media screen and (min-width: $bp-tablet) {
                width: 160 * 1 * 1px;
                height: 82 * 1 * 1px;
              }
            }
            &.icon-survey-editor {
              width: 100 * 0.4 * 1px;
              height: 135 * 0.4 * 1px;
              @media screen and (min-width: $bp-tablet) {
                width: 100 * 0.7 * 1px;
                height: 135 * 0.7 * 1px;
              }
              @media screen and (min-width: $bp-desk-small) {
                width: 100 * 1 * 1px;
                height: 135 * 1 * 1px;
              }
            }
            &.icon-esg-sdg-survey-management {
              width: 92 * 0.5 * 1px;
              height: 120 * 0.5 * 1px;
              @media screen and (min-width: $bp-tablet) {
                width: 92 * 0.7 * 1px;
                height: 120 * 0.7 * 1px;
              }
              @media screen and (min-width: $bp-desk-small) {
                width: 92 * 1 * 1px;
                height: 120 * 1 * 1px;
              }
            }
            &.icon-lp-dashboard {
              width: 92 * 0.5 * 1px;
              height: 136 * 0.5 * 1px;
              @media screen and (min-width: $bp-tablet) {
                width: 92 * 0.75 * 1px;
                height: 136 * 0.75 * 1px;
              }
            }
            &.icon-reporting {
              width: 80 * 0.5 * 1px;
              height: 46 * 0.5 * 1px;
              @media screen and (min-width: $bp-tablet) {
                width: 80 * 0.8 * 1px;
                height: 46 * 0.8 * 1px;
              }
            }
            &.icon-user-management,
            &.icon-spv-manager,
            &.icon-country-list,
            &.icon-country-data {
              width: 120 * 0.25 * 1px;
              height: 84 * 0.25 * 1px;
              @media screen and (min-width: $bp-tablet) {
                width: 120 * 0.35 * 1px;
                height: 84 * 0.35 * 1px;
              }
            }
          }
        }
        .category {
          width: calc(100% - 80px);
          height: 100%;
          padding-left: 15px;
          align-items: center;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          @media screen and (min-width: $bp-tablet) {
            width: calc(100% - 120px);
          }
          .category-name {
            text-transform: uppercase;
            font-weight: 900;
            font-size: 16px;
            line-height: 0.9;
            @media screen and (min-width: $bp-tablet) {
              font-size: 22px;
            }
            .alert {
              position: relative;
              font-size: 14px;
              margin: 20px 0 auto 0;
              text-transform: none;
              line-height: 1.2;
              &:before {
                position: absolute;
                top: 50%;
                right: 100%;
                color: $color-alert;
                content: "\f06a";
                font-size: 20px;
                font-family: "Font Awesome 5 Pro";
                transform: translate(-12px, -50%);
              }
            }
            span {
              display: block;
            }
          }
        }
        .wrapp-cta {
          position: absolute;
          bottom: 10px;
          right: 10px;
          width: auto;
          text-align: right;
          @media screen and (min-width: $bp-tablet) {
            bottom: 20px;
            right: 20px;
          }
          .btn {
            text-align: center;
            margin-left: auto;
            margin-right: 0;
            span {
              margin: auto;
            }
          }
        }
      }
    }
    &:hover {
      .card_inner {
        box-shadow: $box-shadow;
        @include scaleElt(1.05);
      }
    }
  }

  &-light {
    .card {
      &_inner {
        background-color: $color-sea;
        color: $color-deepblue-100;
        &:hover {
          background-color: $color-sky;
        }
      }
    }
  }
  &-medium {
    .card {
      &_inner {
        background-color: $color-ocean;
        &:hover {
          background-color: $color-oceanBright;
        }
      }
    }
  }
  &-dark {
    .card {
      &_inner {
        background-color: $color-deepblue-100;
      }
    }
  }

  &-admin {
    .card {
      &_inner {
        background-color: $color-ocean;
        &:before {
          content: "Dev only";
          position: absolute;
          top: 4px;
          right: 4px;
          border-radius: 3px;
          font-weight: 900;
          text-transform: uppercase;
          font-size: 12px;
          background-color: gold;
          color: $color-deepblue-100;
          padding: 5px 15px;
        }
      }
    }
  }
}
