@import "../../../../assets/scss/__mandatory";

.tool_resume {
  .tool_lp_inner {
    padding: 0;
    display: flex;
    flex-flow: wrap;
    @media screen and (min-width: $bp-tablet) {
      padding: 0 45px;
    }
    @media screen and (min-width: $bp-desk-small) {
      padding: 0;
    }
  }
  &__logo {
    width: 100%;
    background-color: white;
    @media screen and (min-width: $bp-tablet) {
      max-width: 280px;
    }
    @media screen and (min-width: $bp-desk-small) {
      max-width: 368px;
    }
    .logo_inner {
      width: 100%;
      min-height: calc(100vw * 9 / 16);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      @media screen and (min-width: $bp-tablet) {
        min-height: 0;
        padding: 15px 30px;
      }
      img {
        display: block;
        max-width: 100%;
        margin: auto;
      }
    }
    & + .tool_resume__metas {
      @media screen and (min-width: $bp-tablet) {
        padding-left: 15px;
        width: calc(100% - 280px);
      }
      @media screen and (min-width: $bp-desk-small) {
        width: calc(100% - 368px);
      }
    }
  }
  &__metas {
    width: 100%;
    padding: 20px;
    @media screen and (min-width: $bp-tablet) {
      padding: 0;
    }
    .meta {
      position: relative;
      padding: 10px 0;

      &:not(.meta_title) {
        &:before {
          content: "";
          background-color: $color-ocean;
          width: 100%;
          height: 1px;
          display: block;
          margin-bottom: 10px;
        }
      }
      &_title {
        &:after {
          content: "";
          width: 100%;
          height: 4px;
          background-color: white;
          margin-top: 5px;
          display: block;
        }
      }
      &_label {
        font-size: 12px;
        color: $color-sea;
        @media screen and (min-width: $bp-desk-small) {
          font-size: 14px;
        }
        & + .meta_value {
          margin-top: 5px;
        }
      }
      &_value {
        font-family: $font-title-wf;
        font-size: 18px;
        font-weight: 900;
        @media screen and (min-width: $bp-desk-small) {
          font-size: 22px;
        }
      }
    }
    h1 {
      font-family: $font-title-wf;
      font-size: 24px;
      font-weight: 900;
      @media screen and (min-width: $bp-desk-small) {
        font-size: 28px;
      }
    }
  }
  .row {
    &_metas {
      display: flex;
      flex-flow: wrap;
    }
    & + .row {
      margin-top: 15px;
    }
  }
  .w100 {
    width: 100%;
  }
  .w50 {
    @media screen and (min-width: $bp-tablet) {
      &:nth-child(odd) {
        padding-right: 5px;
      }
      &:nth-child(even) {
        padding-left: 5px;
      }
    }
  }
  .w50 {
    width: 100%;
    @media screen and (min-width: $bp-tablet) {
      width: 50%;
    }
  }
  .w40 {
    width: 40%;
  }
  .w35 {
    width: 35%;
  }
  .w30 {
    width: 30%;
  }
  .w25 {
    width: 25%;
  }
  .metas_group {
    display: flex;
    width: 100%;
    .meta {
      &:not(.gigf-remove-margin):last-child {
        margin-left: 15px;
      }
    }
  }
  .legend {
    margin: 10px 0 15px;
    font-style: italic;
    font-size: 12px;
  }
}
