@import "../../../../../assets/scss/_mandatory";

.projects-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &_inner {
    background-color: $white;
    max-width: 640px;
    width: 100%;
    padding: 24px 24px;
    border-radius: 24px;

    .separator {
      width: 100%;
      height: 1px;
      background-color: $color-softGrey;
      margin: 10px 0;
    }

    .modal {
      .label {
        font-family: $font;
        font-size: 12px;
        font-weight: $ft-bold;
        color: $color-deepblue-100;
      }

      &_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: $ft-black;
          text-transform: uppercase;
        }
        .btn {
          text-align: left;
          padding: 0 10px;
          .btn-icon {
            margin-left: 10px;
          }
        }
      }

      &_settings {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .left,
        .right {
          width: 50%;
        }

        .left {
          .toggle-container {
            display: flex;
            align-items: center;
            margin: 10px 0 0;
            .toggle {
              justify-content: flex-end;
              margin: 0 10px;
              &-switcher {
                pointer-events: none;
                position: relative;
                background-color: $color-deepblue-100;
                width: 30px;
                height: 20px;
                border: 2px solid $color-deepblue-100;
                border-radius: 15px;
                cursor: pointer;
                transition: 0.3s all ease-in-out;
                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 4px;
                  transform: translateY(-50%);
                  background-color: $white;
                  width: 10px;
                  height: 10px;
                  border-radius: 20px;
                  transition: 0.3s all ease-in-out;
                }
              }
              &-input {
                opacity: 0;
                width: 30px;
                height: 20px;
                position: absolute;
                @media (any-hover: hover) {
                  &:hover {
                    cursor: pointer;
                  }
                }
                &:checked {
                  & + .toggle-switcher {
                    background-color: $color-successBright;
                    border-color: $color-successBright;
                    transition: 0.3s all ease-in-out;
                    &::before {
                      background-color: $white;
                      left: 12px;
                      transition: 0.3s all ease-in-out;
                    }
                  }
                }
              }
            }
            span {
              font-family: $font;
              font-size: 14px;
              color: #829395;
              &.active {
                font-weight: $ft-bold;
                color: $color-deepblue-100;
              }
            }
          }
        }

        .right {
          .selector {
            width: 100%;
            margin: 10px 0 0;
            .select {
              .btn {
                max-width: none;
                text-align: left;
                padding: 0 5px 0 15px;
              }
              .drop {
                max-width: none;
              }
              .drop-select {
                ul {
                  max-height: 200px;
                  overflow-y: scroll;
                }
              }
            }
          }
          &.disabled {
            pointer-events: none;
            .label {
              color: #829395;
            }
            .btn-select {
              background-color: $color-seaLight;
              border-color: $color-softGrey;
              color: $color-softGrey;
            }
          }
        }
      }

      &_list {
        .search {
          width: 100%;
          text-align: right;
          margin-top: 10px;
          position: relative;
          &:after {
            content: "\f002";
            font-family: $font-icon;
            font-weight: $ft-black;
            font-size: 14px;
            color: $color-ocean;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
          input {
            height: 28px;
            padding: 0px 10px;
            border-radius: 20px;
            border: 2px solid #d5e0e1;
            width: 100%;
            font-size: 14px;
            color: $color-ocean;
            background-color: white;
            transition: all 0.25s ease;
            &::placeholder {
              font-size: 14px;
              color: $color-ocean;
            }
          }
        }

        .select-all {
          position: relative;
          max-width: 80px;
          margin: 10px 0;
          &-input {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            @media (any-hover: hover) {
              &:hover {
                cursor: pointer;
              }
            }
            &:checked {
              & + .box {
                i {
                  color: white;
                }
              }
            }
          }
          .box {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background-color: $color-ocean;
            border: 2px solid $color-cobalt;
            border-radius: 5px;
            transition: 0.3s all ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
            i {
              font-size: 10px;
              color: transparent;
            }
          }
          .label {
            margin-left: 20px;
          }
        }

        .list {
          max-height: 350px;
          overflow-y: scroll;
          .row {
            width: 100%;
            min-height: 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            background-color: $color-seaLight;
            border-radius: 0px 25px 25px 0px;

            .id,
            .name,
            .status {
              font-size: 12px;
              width: 100%;
              color: $color-deepblue-100;
            }

            .id {
              max-width: 30px;
              width: 100%;
              font-family: $font;
            }
            .name {
              width: calc(100% - 30px - 30px - 30px);
              font-weight: $ft-bold;
            }
            .status {
              max-width: 30px;
              width: 100%;
              font-weight: $ft-black;
              text-align: right;
              i {
                color: $color-deepblue-100;
              }
              &:before {
                content: "";
                font-family: $font-icon;
                font-size: 12px;
                width: 100%;
                height: 100%;
                display: block;
                transition: 0.1s all ease-in-out;
              }
            }

            div + div {
              margin-left: 10px;
              @media screen and (min-width: $bp-tablet) {
                margin-left: 15px;
              }
            }

            & + .row {
              margin-top: 2px;
            }

            &.checked {
              background-color: $color-successBright;
              .status {
                &:before {
                  content: "\f00c";
                  transition: 0.1s all ease-in-out;
                }
              }
              @media (any-hover: hover) {
                &:hover {
                  background-color: $color-alert;
                  color: $white;
                  .id,
                  .name,
                  .status {
                    color: $white;
                  }
                  .status {
                    &:before {
                      content: "\f068";
                      transition: 0.1s all ease-in-out;
                    }
                  }
                }
              }
            }

            &:not(&.checked) {
              @media (any-hover: hover) {
                &:hover {
                  cursor: pointer;
                  background-color: $color-deepblue-100;
                  color: $white;
                  .id,
                  .name,
                  .status {
                    color: $white;
                  }
                  .status {
                    &:before {
                      content: "\f067";
                      transition: 0.1s all ease-in-out;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_foot {
        display: flex;
        justify-content: flex-end;
        .btn {
          text-align: left;
          padding: 0 10px;
          margin-top: 20px;
          .btn-icon {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
