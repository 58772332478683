@import "../../../../assets/scss/__mandatory";

.grid {
  width: 100%;
  &_inner {
    max-width: 940px;
    margin: 0 auto;
    padding: 0 0 30px 0;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
  &-tool {
    &_sdg-contribution {
      .card {
        &.card {
          &-empty {
            .card {
              &_inner {
                color: $color-deepblue-100;
                padding: 0;
                align-items: flex-start;
                .edito {
                  font-size: 14px;
                  line-height: 1.3;
                }
              }
            }
          }
          &:not(.card-empty) {
            .card {
              &_inner {
                &:not(.cant-select) {
                  cursor: pointer;
                  &:hover {
                    @include scaleElt(1.1);
                  }
                }
              }
            }
          }
        }
        &-sdg {
          width: 100/6 * 1%;

          @each $color, $hexaColor in $listColorESG {
            .sdg-#{$color} {
              background-color: $hexaColor;
            }
          }
          .card {
            &_inner {
              position: relative;
              min-height: 140px;
              padding: 10px;
              @include transitionElt(all, 0.3s, ease);
              &.cant-select {
                filter: grayscale(1);
                pointer-events: none;
                cursor: not-allowed;
              }

              .icon {
                width: 60px;
                height: 60px;
                overflow: hidden;
                margin: 0 0 0 auto;
                img {
                  width: 100%;
                }
              }
              &:not(.card-empty) {
                .edito {
                  position: relative;
                  z-index: 5;
                  height: 50%;
                  .sdg-number {
                    font-size: 14px;
                    & + .sdg {
                      margin-top: 5px;
                    }
                  }
                  .sdg-name {
                    font-size: 12px;
                    font-weight: 900;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.page {
  &-tool {
    &-sgd-contribution {
      min-height: calc(100vh - 140px);
      .page {
        &_inner {
          height: 100%;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          justify-content: center;
        }
        &__title {
          color: $color-primary;
        }
      }

      .container-list {
        position: relative;
        width: 100%;
        padding-top: 2px;
      }

      .list {
        position: relative;
        width: 100%;
        // z-index: 25;
        &.list-main {
          &:not(.selection) {
            position: absolute;
            z-index: 120;
            &.open {
              display: block;
              background: $color-seaLight;
              max-height: 560px;
              overflow: scroll;
            }
            &:not(.open) {
              display: none;
            }
          }
        }
        & + .list {
          margin-top: 2px;
        }
        li {
          position: relative;
          width: 100%;
          .inner {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            flex-flow: wrap;
            height: 30px;
            @include border-radius(15px);
            padding: 3px;
          }
          &:not(.cant-select) {
            .inner {
              cursor: pointer;
            }
          }
          .btn-close-list {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 30px;
            z-index: 5;
          }
          .btn-reset {
            position: absolute;
            top: 50%;
            right: 0;
            width: 32px;
            transform: translateY(-50%);
            color: $color-alert;
            text-align: center;
            z-index: 10;
            i {
              margin: auto;
            }
          }
          &:not(.active) {
            .inner {
              color: $color-deepblue-100;
              background-color: white;
            }
            .btn {
              color: $color-deepblue-100;
              &.btn-reset {
                color: $color-alert;
              }
            }
            &.selection {
              &:hover {
                .action {
                  .btn {
                    color: white;
                  }
                  i {
                    transform: translateX(0px);
                  }
                }
              }
            }
          }

          &:hover,
          &.active {
            .inner {
              color: white;
              background-color: $color-deepblue-100;
            }
            .action {
              .btn {
                color: white;
              }
            }

            .name {
              font-weight: 900;
            }
          }
          &.prestine {
            .resume {
              font-weight: 400;
            }

            &:not(.active) {
              .action {
                .btn {
                  color: $color-deepblue-100;
                }
              }
              &:hover {
                .action {
                  .btn {
                    color: white;
                  }
                }
              }
            }
          }
        }

        .icon {
          position: relative;
          width: 24px;
          height: 24px;
          @include border-radius(50%);
          overflow: hidden;
          .sdg-icon {
          }
          img {
            width: 100%;
          }
        }
        .resume {
          font-size: 16px;
          font-weight: 700;
        }
        .action {
          padding: 0 10px;
          text-align: center;
          font-size: 14px;
          i {
            @include transitionElt(transform, 0.3s, ease);
          }
          .btn {
            min-height: 0;
            padding: 0;
            margin: auto;
            .btn-icon {
              display: flex;
              flex-flow: wrap;
              margin: auto;
            }
          }
        }
        .name {
          padding: 0 15px;
          font-size: 16px;
        }
        &.list-indicators {
          .resume {
            font-size: 14px;
          }
        }

        &.list-targets,
        &.list-indicators {
          .resume {
            padding-left: 10px;
            min-width: 124px;
          }
          .name {
            font-size: 12px;
          }
        }
        &.list-sdgs {
          li {
            &:hover,
            &.active {
              .name {
                font-weight: 900;
              }
            }
            &:hover {
              .inner {
                cursor: pointer;
              }
            }
            &.cant-select {
              filter: grayscale(1);
              pointer-events: none;
              cursor: not-allowed;
              opacity: 0.9;
            }
            & + li {
              &:not(:empty) {
                margin-top: 2px;
              }
            }
          }
          .resume {
            padding: 0 10px;
            min-width: 100px;
          }
        }
        &.list-indicators,
        &.list-targets {
          .name {
            width: calc(100% - 124px - 60px - 5px);
            height: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;

            span {
              display: block;
              width: 100%;
              white-space: nowrap;
              margin: auto 0;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .group {
        padding: 10px 0;
        .label {
          position: relative;
          font-style: italic;
          border-bottom: 1px solid $color-sea;
          padding-bottom: 10px;
          margin-bottom: 10px;
          font-size: 12px;
          color: $color-deepblue-100;
          .btn {
            min-height: 0;
            padding: 0;
            &.btn-reset {
              position: absolute;
              display: flex;
              right: 0;
              top: 0;
              width: 15px;
              min-width: 0;
              height: 15px;
              transform: translateY(-0.5px);
              color: $color-error;
              justify-content: center;
            }
          }
        }
        &.group-filters {
          .list {
            &.list-filters {
              &.main {
                &.list-filters-inline {
                  display: flex;
                  padding: 0;
                  color: $color-deepblue-100;
                  li {
                    width: auto;
                    & + li {
                      margin-left: 5px;
                      border-top: none;
                    }
                  }
                }
              }
            }
          }
          .selector .select {
            position: relative;
            .btn-select {
              width: 100%;
            }
            .drop-select {
              &.large {
                ul {
                  &.lvll0 {
                    height: 420px;
                  }
                }
              }
              ul {
                &.lvl0 {
                  max-height: 420px;
                  overflow: scroll;
                  li {
                    &.item {
                      padding: 0 10px;
                      &.selected {
                        font-weight: bold;
                        color: $color-sky;
                      }
                      &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                        cursor: not-allowed;
                      }
                    }
                  }
                }
                &.lvl1 {
                  li {
                    .item {
                      &.selected {
                        font-weight: bold;
                        color: $color-sky;
                      }
                      &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                        cursor: not-allowed;
                      }
                    }
                  }
                }
                &.lvl2 {
                  // padding: 0 0 20px 0;
                  li {
                    position: relative;
                    &.hide-comparaison {
                      &:after {
                        content: "";
                        display: block;
                        width: calc(100% - 30px);
                        height: 1px;
                        background-color: $color-sea;
                        margin: 5px 0 15px auto;
                      }
                    }
                    // & + li {
                    //   margin-top: 15px;
                    // }
                    &.item {
                      .icon {
                        img {
                          width: 14px;
                          height: 14px;
                        }
                      }
                      &.selected {
                        color: $color-sky;
                        font-weight: $ft-bold;
                      }
                      &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                        cursor: not-allowed;
                      }
                    }
                  }
                }
              }
            }
          }
          .input {
            position: relative;
            width: 100%;
            input {
              &[type="search"] {
                width: 100%;
                max-width: none;
                border: 2px solid #d5e0e1;
                font-size: 12px;
                background-color: white;
                transition: all 0.25s ease;
                padding: 0px 28px 0 10px;
                border-radius: 20px;
                &.selected {
                  border-color: $color-mandarine;
                }
              }
            }
          }
        }
      }

      // :host ::ng-deep {
      //     @import "./../../../../../_highcharts/highcharts";
      //     @import "./../../../../../_highcharts/custom";
      // }

      .legends {
        width: 100%;
        @media screen {
          border-bottom: 1px solid $color-sea;
        }
        .inner-legends {
          padding: 5px 0;
          display: flex;
          flex-flow: wrap;
          align-items: center;
        }
      }
      .own-tooltip {
        position: relative;
        .tooltip {
          color: #000;
          font-size: 13px;

          &.tooltip-remote {
            .tooltip-icon {
              cursor: pointer;
              & + .tooltip-label {
                margin-left: 5px;
              }
            }
            .tooltip-label {
              cursor: pointer;
              font-size: 13px;
            }
            &:hover {
              & + .tooltip-bubble {
                pointer-events: initial;
                opacity: 1;
                transform: translateY(0);
              }
            }
          }
          &.tooltip-bubble {
            transform: translateY(-100%);
            position: absolute;
            bottom: 100%;
            left: -10px;
            pointer-events: none;
            opacity: 0;
            padding-bottom: 12px;
            width: auto;
            min-width: 280px;
            padding-right: 20px;
            transform: translateY(-30px);
            @include transitionElt(all, 0.25s, ease);
            .inner-tooltip {
              position: relative;
              background-color: white;
              padding: 15px;
              box-shadow: 0 10px 50px rgba($color-primary-dark, 0.6);
              @include border-radius(1px);
              &:before {
                content: "";
                position: absolute;
                top: 100%;
                left: 10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 7px 0 7px;
                border-color: white transparent transparent transparent;
              }
            }
          }
        }
      }

      .list {
        &.list-explanations {
          li {
            width: 100%;
            & + li {
              margin-top: 5px;
            }
          }
          @media print {
            padding-top: 20px;
          }
        }

        .level {
          .inner {
            display: flex;
            flex-flow: wrap;
            align-items: center;
          }
          .level-name {
            width: 60px;
            padding-right: 5px;
            font-weight: 700;
            font-size: 13px;

            i {
              font-size: 10px;
            }
          }
          .level-explanation {
            width: calc(100% - 65px);
            font-size: 13px;
          }
        }
      }

      .input-autocomplete {
        height: 28px;
        padding-right: 28px;
        &::-ms-clear {
          display: none;
          width: 0;
          height: 0;
        }
        &::-ms-reveal {
          display: none;
          width: 0;
          height: 0;
        }
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }
        &:focus,
        &:active {
          & + .wrapp-autocomplete {
            transform: translateY(0px);
            opacity: 1;
            pointer-events: initial;
          }
        }
      }
      .input-wrapp-autocomplete {
        position: relative;
        width: 100%;
        .btn-reset {
          position: absolute;
          top: 50%;
          right: 0;
          width: 32px;
          transform: translateY(-50%);
          color: $color-alert;
          opacity: 1;
          text-align: center;
          i {
            margin: auto;
          }
        }
      }

      .wrapp-autocomplete {
        position: absolute;
        bottom: 100%;
        left: 0;
        z-index: 50;
        padding-bottom: 20px;
        pointer-events: none;
        opacity: 0;
        transform: translateY(-30px);
        @include transitionElt(all, 0.25s, ease);
        &:hover {
          transform: translateY(0px);
          opacity: 1;
          pointer-events: initial;
        }
        .inner-autocomplete {
          position: relative;
          background-color: white;
          padding: 15px;
          box-shadow: 0 10px 50px rgba($color-primary-dark, 0.6);
          &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 10px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 7px 0 7px;
            border-color: white transparent transparent transparent;
          }
          ul {
            width: 100%;
            height: 100%;
            max-height: 240px;
            overflow: scroll;
            li {
              padding: 5px 15px;
              font-size: 13px;
              color: $color-deepblue-100;
              background-color: white;
              @include border-radius(30px);
              display: flex;
              flex-flow: wrap;
              cursor: pointer;
              span {
                display: block;
                margin: auto 0;
              }
              &.selected,
              &:hover {
                color: white;
                background-color: $color-deepblue-100;
              }
              &.selected {
                font-weight: 700;
              }
              & + li {
                //margin-top: 5px;
              }
            }
          }
        }
      }

      .drop {
        &.uk-dropdown-bottom-left {
          top: auto !important;
          bottom: 100%;
        }
      }

      .btn.btn-select {
        & + .drop {
          &.uk-open {
            ul.group.lvl0 {
            }
          }
        }
      }
    }
  }
}

.tool {
  &-sgd-contribution {
    width: 100%;
    max-width: 960px;
    margin: 0 auto 40px auto;

    .col-body {
      position: relative;
    }
    &.selector-open-big {
      .col-body {
        min-height: 540px;
      }
    }
    &.selector-open {
      li {
        .btn-close-list {
          pointer-events: initial;
        }
      }
      .col-body {
        &:before {
          // content: "";
          // position: absolute;
          // top: 0;
          // left: 0;
          // width: 100%;
          // height: 100%;
          // z-index: 20;
        }
        .list {
          &.list-main {
            &.selection {
              //opacity: 0.25;
              //pointer-events: none;
            }
          }
        }
        .container-list {
          .list {
            background-color: rgba($color-seaLight, 0.8);
          }
        }
      }
    }
    &:not(.selector-open) {
      li {
        .btn-close-list {
          pointer-events: none;
        }
      }
    }
    .grid {
      width: 100%;
      .inner {
        width: 100%;
        max-width: 980px;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
      }
    }

    .cmp {
      width: 100%;
      &.cmp-chart {
        .inner-chart {
          width: 100%;
        }
        &:not(.open) {
          @media screen {
            display: none;
          }
        }
        #wrapGraph {
          width: 100%;
          &[data-highcharts-chart="0"] {
            display: none;
          }
          @media screen {
            margin-top: 15px;
          }
          @media print {
            display: block;
            //height: 340px;
            padding: 0 15px;
          }
        }
      }
    }

    .row {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      .inner-row {
        width: 100%;
      }

      .col {
        width: 100%;
      }
    }

    .row.row-filters {
      @media screen {
        margin-top: 40px;
      }
      .col {
        width: 100%;
        @media screen and (min-width: $bp-tablet) {
          width: 25%;
        }
        &:first-child {
          @media screen {
            padding-right: 15px;
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          padding: 0 7.5px;
        }
        &:last-child {
          padding-left: 15px;
        }
      }
    }
  }
}

.btn-radio {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  min-height: 24px;

  input {
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    opacity: 0;
    z-index: -1;
  }
  label {
    &.radio-like {
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid $color-ocean;
      background-color: white;
      width: 20px;
      height: 20px;
      @include border-radius(50%);
      &:before {
        content: "";
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        transform: translateY(-50%) translateX(-50%);
        background-color: $color-ocean;
        @include border-radius(50%);
        opacity: 0;
      }
    }
    &.radio-label {
      display: inline-flex;
      width: auto;
      flex-flow: wrap;
      font-size: 12px;
      font-weight: 700;
      margin: auto auto auto 0;
      min-height: 16px;
      cursor: pointer;
      span {
        display: block;
        width: 100%;
        margin: auto;
        line-height: 1.2;
        padding: 0 5px;
      }
    }
  }
  &.inline {
    padding-left: 18px;
    input {
      &:not(:checked) {
        & + label {
          &.radio-like {
            & + .radio-label {
              font-weight: 400;
            }
          }
        }
      }
      &:checked {
        & + label {
          &.radio-like {
            border-color: $color-ocean;
            &:before {
              opacity: 1;
            }
            & + .radio-label {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  &:not(.inline) {
    padding-left: 28px;
    &.radio-label {
      @include border-radius(25px);
    }
    label {
      &.radio-label {
        padding: 2px 6px;
      }
    }
    input {
      &:checked {
        & + label {
          &.radio-like {
            & + .radio-label {
              background-color: $color-ocean;
              color: white;
            }
          }
        }
      }
    }
  }
}
