.defs-svg {
  visibility: hidden;
  display: block;
  height: 0;
  width: 0;
  overflow: hidden;
  @media print {
    display: none;
  }
}

svg {
  &.svg-icon {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}

svg {
  &.ui-legend-size {
    width: 11 * 1 * 1px;
    height: 11 * 1 * 1px;
  }
  &.ui-arrow-swipe {
    width: 30 * 1 * 1px;
    height: 12 * 1 * 1px;
  }
}

.svg {
  &__download {
    width: 23px;
    height: 16px;
  }
}

.svg {
  &-logo {
    &-meridiam {
      width: 198 * 0.9 * 1px;
      height: 54 * 0.9 * 1px;
      &.l {
        width: 198 * 1.1 * 1px;
        height: 54 * 1.1 * 1px;
      }
      &.xl {
        width: 198 * 1.2 * 1px;
        height: 54 * 1.2 * 1px;
      }
    }
  }
}
