@import "../../../../assets/scss/__mandatory";

.footer {
  position: relative;
  width: 100%;
  min-height: 140px;
  z-index: 20;
  background-color: #fff;
  padding: 20px 0 30px 0;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    display: block;
    background-color: $color-ocean;
  }
  &_inner {
    width: 100%;
    max-width: 960px;
    margin: auto;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    padding: 0 10px;
  }
  &__links {
    width: 40%;
    color: $color-deepblue-100;
    padding: 0 0 20px 0;
    @media screen and (min-width: $bp-tablet) {
      width: 75%;
      padding-bottom: 0;
    }
    .list {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      @media screen and (min-width: $bp-tablet) {
        width: 100/3 * 1 * 1%;
      }
      // @media screen and (min-width: $bp-tablet) {
      //   -webkit-column-count: 3;
      //   -moz-column-count: 3;
      //   column-count: 3;
      // }
    }
    li {
      &.lvl0 {
        width: 100/1 * 1%;
        text-align: left;
        @media screen and (min-width: $bp-tablet) {
          text-align: left;
          width: 100/1 * 1%;
        }
        ul {
          width: 100%;
          li {
            width: 100%;
          }
        }
      }
      &:not(.lvl0) {
        & + li {
          margin-top: 8px;
        }
      }
    }
    .link {
      display: inline-block;
      min-height: 20px;
      font-size: 16px;
      color: inherit;
      @include transitionElt(color, 0.25s, ease);
      &:hover {
        color: $color-ocean;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  &__brand {
    width: 60%;
    margin: auto;
    @media screen and (min-width: $bp-tablet) {
      width: 25%;
    }
    .logo {
      text-align: center;
      width: 100%;
      &_brand {
        width: 174px;
        height: 42px;
      }
    }
  }
}
