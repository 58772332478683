.row {
  &-checkbox {
    .input {
      position: relative;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      input {
        &[type="checkbox"] {
          appearance: none;
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;
          padding: 0;
        }
        &:checked {
          & + .checkbox_like {
            &:before {
              opacity: 1;
            }
          }
          & + .checkbox-label {
          }
          &[readonly] {
            pointer-events: none;
            cursor: none;
            & + .checkbox_like {
              background-color: $color-input-automated-bg;

              border-color: $color-input-border-automated;
              color: $color-input-automated;
              background-color: $color-input-automated-bg;
              &:before {
                opacity: 1;
                background-color: $color-input-automated-bg;
              }
            }
          }
        }
        &:not(:checked) {
          &[readonly] {
            pointer-events: none;
            cursor: none;
            border-color: white;
            & + .checkbox_like {
              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
      .checkbox {
        &_like,
        &_label {
          cursor: pointer;
        }
        &_like {
          position: absolute;
          top: 0;
          left: 0;
          border: $checkbox-border-size solid $color-checkbox-off;
          background-color: $color-input-bg;
          width: 20px;
          height: 20px;
          @include border-radius(50%);
          &:before {
            content: "";
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            transform: translateY(-50%) translateX(-50%);
            background-color: $color-checkbox-active;
            @include border-radius(50%);
            opacity: 0;
          }
        }
        &_label {
          margin: auto;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          min-height: 20px;
          line-height: 1.3;
          font-size: 14px;
          margin: auto;
          padding-left: 25px;
          padding-right: 5px;
          font-weight: 700;
          color: $color-primary-25;
          span {
            display: inline-flex;
            margin: auto 0;
            font-weight: $ft-medium;
          }
          a {
            font-weight: $ft-bold;
            color: white;
          }
        }
      }
    }
  }
}
