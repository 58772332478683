@import "../../../../assets/scss/__mandatory";

.tool-survey-management {
  background-color: white;
  color: $color-deepblue-100;
  max-width: 940px;
  padding: 20px;
  margin: 0 auto 80px auto;
  width: 100%;
  .content-head {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .title {
      width: 100/3 * 2 * 1%;
      h2 {
        font-weight: $ft-black;
      }
      .subtitle {
        color: $color-ocean;
        font-size: 12px;
        font-weight: 700;
        .survey-status {
          & + .survey-status {
            &:before {
              content: "/";
              padding: 0 5px;
            }
          }
        }
      }
    }
    .tool-search {
      width: 100/3 * 1 * 1%;
      text-align: right;
      input {
        height: 28px;
        padding: 0px 10px;
        border-radius: 20px;

        border: 2px solid #d5e0e1;
        width: 100%;
        font-size: 12px;
        font-weight: $ft-bold;
        background-color: white;
        transition: all 0.25s ease;
      }
    }
  }
  .loader {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
  .own-table {
    width: 100%;

    .table {
      width: 100%;
      .row {
        position: relative;
        width: 100%;
        .inner-row {
          width: 100%;
          height: 100%;
          display: flex;
          flex-flow: wrap;
          align-items: center;
        }
        .col {
          height: 100%;
          .cel {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            span {
              margin: auto;
              &.last-container {
                display: block;
                width: 100%;
              }
            }
          }
          &.col-1 {
            width: 40%;
          }
          &.col-2 {
            width: 25%;
          }
          &.col-3 {
            width: 20%;
          }
          &.col-4 {
            width: 15%;
          }
        }
      }
      &.table-head {
        border-bottom: 1px $color-ocean solid;
        padding: 5px 5px 5px 10px;
        font-size: 12px;
        font-style: italic;
      }
      &.table-body {
        padding: 10px 0;
        .row {
          height: 40px;
          padding: 0 5px;
          @include border-radius(0 20px 20px 0);
          &:nth-child(odd) {
            background-color: rgba(#ecf4f9, 1);
          }
          &:hover {
            background-color: $color-deepblue-100;
            .col {
              &.col-1 {
                color: white;
              }
              &.col-2,
              &.col-3 {
                background-color: $color-citron;
              }
              &.col-4 {
                color: white;
                .action-label {
                  opacity: 1;
                }
              }
            }
            .progress-container {
              &:before {
                background-color: $color-alert;
              }
            }
          }
        }
        .col {
          font-size: 12px;
          &.col-1 {
            font-weight: 700;
            .cel {
              span {
                width: 100%;
                margin: auto;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
          &.col-2 {
            @include border-radius(20px 0 0 20px);
            padding-left: 20px;
            .cel {
              width: 100%;
              display: flex;
              flex-flow: wrap;
              align-items: center;
              .progress-container {
                position: relative;
                width: 100px;
                height: 3px;
                margin: auto;
                &:before {
                  width: 100%;
                }
              }
              .completion {
                width: calc(100% - 100px);
                padding-left: 5px;
                span {
                  width: auto;
                  display: inline-block;
                  font-size: 14px;
                }
              }
            }
          }
          &.col-3 {
            @include border-radius(0 20px 20px 0);
            padding-right: 20px;
          }
          &.col-4 {
            text-align: right;
            .action {
              width: 100%;
              display: flex;
              flex-flow: wrap;
            }
            .action-label {
              font-size: 12px;
              font-weight: 700;
              width: calc(100% - 30px) !important;
              padding-right: 5px;
              opacity: 0;
            }
            .btn {
            }
          }
        }
      }
    }

    &.table-survey-projects {
    }
    .btn-filter {
      &:not(.uk-active) {
        background-color: $color-sea;
      }
      &.uk-active {
        background-color: $color-deepblue-100;
        color: white;
        cursor: default;
      }
      .btn-label {
        font-style: italic;
      }

      .btn-icon {
        margin: auto;
        font-size: 12px;
      }
      & + .btn-filter {
        margin-left: 2px;
      }
      &.circle {
        @include border-radius(50%);
      }
    }
    .survey-status {
      display: flex;
      width: 100%;
      flex-flow: wrap;
      span {
        &.status-icon {
          width: 16px !important;
          font-size: 16px;
        }
        &.status-label {
          width: calc(100% - 24px) !important;
          font-size: 12px;
          padding-left: 5px;
        }
      }
      &.status-validated {
        .status-icon {
          color: $color-success;
        }
      }
      &.status-submitted {
        color: $color-alert;
        .status-label {
          font-weight: 700;
        }
      }
      &.status-in_progress {
      }
      &.status-not_started {
      }
      &.status-completed {
      }
    }
  }

  .progress-container {
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 3px;
    }
    &:before {
      background-color: $color-ocean;
    }
    &:after {
      background-color: $color-deepblue-100;
    }
  }

  @for $i from 0 through 100 {
    .progress-container {
      &[data-percent="#{$i}"] {
        &:after {
          width: $i * 1%;
        }
      }
    }
  }
}

.filter-label {
  padding-right: 10px;
}

.select-filter {
  position: relative;

  .btn-select {
    color: white;
    background-color: $color-ocean;
    border: 2px solid $color-deepblue-100;
    @include border-radius(15px);
    padding: 0 5px 0 10px;
    min-height: 28px;
    .btn-label {
      width: calc(100% - 30px);
      font-size: 13px;
      & + .btn-icon {
        width: 20px;
        margin-left: 10px;
        &:before {
          content: "\f0d7";
          font-family: "Font Awesome 5 Pro";
        }
      }
      &:not(.uk-active) {
        display: none;
      }
    }

    & + .drop-filter {
      background-color: transparent;
      z-index: 20;
      padding: 0;
      &.uk-open {
        ul {
          margin-top: -40px;
        }
      }
      ul {
        width: auto;
        background-color: $color-cobalt;
        color: white;

        @include border-radius(15px);
        box-shadow: 0 2px 5px rgba($color-deepblue-100, 0.5);
        li {
          width: 100%;
          min-height: 24px;
          display: flex;
          align-items: center;
          text-align: left;
          span {
            display: block;
            margin: auto 0;
            padding: 0 20px;
          }
          &:hover,
          &.uk-active {
            cursor: pointer;
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
