@import "../../../../../assets/scss/__mandatory";

.dataviz {
  &-wrapper {
    position: relative;
    @media print {
      padding-top: 20mm;
      height: 262mm;
      page-break-after: avoid;
    }
  }
}

.pdf {
  &-howto {
    position: absolute;
    bottom: 10mm;
    left: 20mm;
    font-family: $font-title-wf;
    color: $color-deepblue-100;
    text-align: center;
    padding: 0 0 10px 0;
    &_inner {
      position: relative;
      width: 100%;
    }
    .howto {
      &-circles {
        position: relative;
        width: 100%;
        &_inner {
          position: relative;
          width: 100px;
          height: 100px;
          clip-path: polygon(50% 100%, 0% 0%, 100% 0);
          margin: 0 auto;
          border-radius: 50%;
          &:before {
            content: "";
            background-color: $color-seaLight;
            width: 200px;
            height: 200px;
            position: absolute;
            bottom: 0;
            left: 50%;
            border-radius: 50%;
            transform: translateX(-50%) translateY(50%);
          }
          &:after {
            content: "";
            background-color: white;
            width: 50px;
            height: 50px;
            position: absolute;
            bottom: 0;
            left: 50%;
            border-radius: 50%;
            transform: translateX(-50%) translateY(50%);
          }
        }
        .circle {
          position: absolute;
          top: 0%;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          height: 100px;
          svg {
            display: block;
          }
          circle {
            fill: currentColor;
            margin: 0 auto;
            display: block;
          }
          &-1 {
            color: $color-deepblue-100;
          }
          &-2 {
            color: $color-ocean;
            clip-path: polygon(50% 100%, 50% 0%, 100% 0);
          }
          &-goals {
            color: $green;
            svg {
              fill-opacity: 0.5;
            }
          }
        }
      }

      &-graph {
        &_inner {
          display: flex;
          flex-flow: wrap;
          &.split {
            .circle-1 {
              clip-path: polygon(50% 100%, 0% 0%, 50% 0);
            }
            &:not(.with-goals) {
              .serie-1 {
                text-align: right;
                top: 30%;
                left: auto;
                right: 100%;
                bottom: auto;
                transform: none;
                padding-right: 5px;
                &:after {
                  content: "";
                  width: 23px;
                  height: 1px;
                  background-image: linear-gradient(
                    to left,
                    #2b77a6 40%,
                    rgba(43, 119, 166, 0) 20%
                  );
                  background-position: left;
                  background-size: 3px 1px;
                  background-repeat: repeat-x;
                  position: absolute;
                  top: 50%;
                  right: -32%;
                  left: auto;
                  bottom: auto;
                  transform: none;
                }
              }
            }
          }
          &.with-goals {
            .serie-1 {
              text-align: right;
              color: $color-deepblue-100;
              top: 30%;
              right: 100%;
              left: auto;
              bottom: auto;
              transform: none;
              span {
                padding-right: 5px;
              }
              &:after {
                content: "";
                width: 23px;
                height: 1px;
                background-image: linear-gradient(
                  to left,
                  #2b77a6 40%,
                  rgba(43, 119, 166, 0) 20%
                );
                background-position: left;
                background-size: 3px 1px;
                background-repeat: repeat-x;
                position: absolute;
                top: 50%;
                right: -32%;
                left: auto;
                bottom: auto;
                transform: none;
              }
            }
          }
        }
      }
      &-series {
        width: 100%;
        .serie {
          position: absolute;
          left: 50%;
          display: flex;
          flex-flow: column nowrap;
          font-size: 11px;
          display: block;
          span {
            white-space: nowrap;
            display: block;
            width: 100%;
          }
          &-1 {
            text-align: center;
            color: $color-deepblue-100;
            bottom: 100%;
            transform: translateY(-30px) translateX(-50%);
            &:after {
              content: "";
              width: 1px;
              height: 40px;
              background-image: linear-gradient(
                to bottom,
                $color-ocean 40%,
                rgba($color-ocean, 0) 20%
              );
              background-position: left;
              background-size: 1px 3px;
              background-repeat: repeat-y;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%) translateY(6px);
            }
          }
          &-2 {
            text-align: left;
            color: $color-ocean;
            top: 30%;
            left: 100%;
            span {
              padding-left: 10px;
            }
            &:after {
              content: "";
              width: 23px;
              height: 1px;
              background-image: linear-gradient(
                to left,
                #2b77a6 40%,
                rgba(43, 119, 166, 0) 20%
              );
              background-position: left;
              background-size: 3px 1px;
              background-repeat: repeat-x;
              position: absolute;
              top: 50%;
              left: -25%;
            }
          }
          &-goals {
            bottom: 100%;
            transform: translateY(-30px) translateX(-50%);
            background-color: rgba($green, 0.5);
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: center;
            min-height: 28px;
            padding: 0 15px;
            border-radius: 50px;
            span {
              position: relative;
              &:after {
                content: "";
                width: 1px;
                height: 40px;
                background-image: linear-gradient(
                  to bottom,
                  $color-ocean 40%,
                  rgba($color-ocean, 0) 20%
                );
                background-position: left;
                background-size: 1px 3px;
                background-repeat: repeat-y;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%) translateY(6px);
              }
            }
          }
        }
      }
      &-label {
        margin-top: 8px;
        font-size: 18px;
        font-weight: $ft-regular;
      }
    }
  }

  &-legends {
    @media print {
      padding: 10mm 0;
    }
    page-break-after: avoid;
    display: block;
    font-family: $font-title-wf;
    text-align: left;
    &-wrapper {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      border-left: 1px solid $color-sea;
      border-right: 1px solid $color-sea;
      &.split {
        .legends {
          &_inner {
            width: 50%;
          }
        }
      }
    }
    .legends {
      &_inner {
        width: 100%;
        padding: 0 10px;
        &.serie-1 {
          color: $color-deepblue-100;
          h2 {
            span {
              &:before {
                background-color: $color-deepblue-100;
              }
            }
          }
        }
        &.serie-2 {
          border-left: 1px solid $color-sea;
          color: $color-ocean;
          h2 {
            span {
              &:before {
                background-color: $color-ocean;
              }
            }
          }
        }

        h2 {
          font-weight: $ft-black;
          font-size: 16px;
          span {
            display: flex;
            align-items: center;
            &:before {
              content: "";
              width: 12px;
              height: 12px;
              @include border-radius(50%);
              display: block;
              margin-right: 5px;
            }
          }
        }
        .legend {
          &-scope {
            width: 100%;
            text-align: left;
            margin-top: 20px;
            padding-left: 18px;
            h4 {
              width: 100%;
              font-size: 14px;
              text-align: left;
            }
          }
        }
      }
    }
  }
  &-legend {
    width: 100%;
    margin-top: 20px;
    padding-left: 18px;
    & + .pdf-legend {
      margin-top: 10px;
    }
    h3 {
      font-weight: $ft-bold;
      display: flex;
      width: 100%;
      flex-flow: wrap;
      align-items: center;
      font-size: 14px;
      small {
        font-weight: $ft-regular;
      }
      i {
        margin-left: 5px;
      }
      & + ul {
        margin-top: 5px;
      }
    }
    ul {
      width: 100%;
      li {
        width: 100%;
      }
    }
  }
}
