@import "../../../../assets/scss/__mandatory";

$initZoom: 1;

.tool_geographical-overview {
  &:not(.mode-smartphone) {
    @media screen {
      padding: 0 20px;
      @media screen and (min-width: $bp-tablet) {
        padding: 0;
      }
    }
    .tool_lp_inner {
      padding: 0;
    }
  }
  &.mode-smartphone {
    .slideshow {
      //height: 800px;
      .uk-slideshow-items {
        //height: 800px;
        .slide {
          position: absolute;
          &:not(.uk-active) {
            display: none;
          }
          &.uk-active {
            position: relative;
          }
        }
      }
    }

    .tool {
      &__legends {
        margin: 15px 0;
        &.top {
          .legend {
            &_label {
              text-align: center;
              i {
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
        .legend {
          &_icon {
          }
          &_label {
          }
          &.top {
            .legend_label {
              text-align: center;
              display: block;
              width: 100%;
              i {
                display: block;
                width: 100%;
              }
            }
          }
          &:not(.top) {
            .legend_label {
              margin-left: 8px;
              display: inline-block;
              width: auto;
            }
          }
        }
      }
    }
    .btn__map-mobile {
      z-index: 5;
      color: $color-sea;
      background-color: #4085af;
      padding: 0 15px 0 0;
      @include border-radius(15px);

      .btn {
        &__icon {
          border: 2px solid $color-deepblue-100;
          width: 30px;
          height: 30px;
          @include border-radius(50%);
          display: flex;
          flex-flow: wrap;
          align-items: center;
          justify-content: center;
          color: white;
        }
        &__label {
          margin-left: 5px;
          font-weight: 400;
        }
      }
    }
    .title {
      &_country {
        text-align: center;
        margin: 10px;
        font-size: 20px;
        color: $white;
        margin-top: 20px;
        span {
          font-family: "Montserrat";
          font-weight: 900;
          font-size: 20px;
          text-transform: uppercase;
        }
      }
    }
  }
  .tool_lp_inner {
    position: relative;
    overflow: hidden;
    background-color: #4085af;
    min-height: 464px;

    .legends {
      &-bubble {
        text-align: center;
      }
      &.top {
        .legend {
          &__label {
            text-align: center;
            font-size: 11px;
          }
        }
      }
      .legend {
        width: 100%;
        position: relative;
        &_inner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
        &__label {
          width: 100%;
          color: $color-deepblue-100;
          &:nth-child(1) {
            margin-bottom: 4px;
          }
          span {
            display: inline-flex;
            @include border-radius(15px);
            line-height: 15px;
            background-color: rgba(#d5e0e1, 0.75);
            font-size: 12px;
            font-weight: 700;
            padding: 2px 5px;

            strong {
              font-size: 16px;
              font-weight: 900;
            }
            small {
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .highcharts-series {
      @include transitionElt(all, 0.25s, ease);
    }
    &[data-zoomlevel="16"] {
      .btn {
        &__map-zoomout {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
    &[data-zoomlevel="1"] {
      .tool__remote {
        &.remote-open {
          @media screen and (min-width: $bp-tablet) {
            bottom: 0;
          }
          .toggle_label_on {
            display: block;
          }
        }
      }
    }
    &[data-zoomlevel="#{$initZoom}"] {
      //.highcharts-series-1,
      .highcharts-series-2,
      .highcharts-series-4 {
        opacity: 0 !important;
        pointer-events: none;
      }

      .tool__remote {
        background-color: rgba($color-ocean, 0);
        box-shadow: 0 0 10px rgba($color-deepblue-100, 0);
        .remote__toggle,
        .remote_inner .tool__remote__chart .remote__chart_inner {
          display: none;
        }
      }

      .btn {
        &__map-continent {
          display: none;
        }
        &__map-zoomin {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
    &:not([data-zoomlevel="#{$initZoom}"]) {
      .highcharts-series-0,
      .highcharts-series-3 {
        opacity: 0 !important;
        pointer-events: none;
      }
    }
    .btn__map-continent {
      @media print {
        display: none;
      }
      position: absolute;
      top: 30px;
      left: 30px;
      z-index: 5;
      color: $color-sea;
      background-color: #4085af;
      padding: 0 15px 0 0;
      @include border-radius(15px);

      .btn {
        &__icon {
          border: 2px solid $color-deepblue-100;
          width: 30px;
          height: 30px;
          @include border-radius(50%);
          display: flex;
          flex-flow: wrap;
          align-items: center;
          justify-content: center;
        }
        &__label {
          margin-left: 5px;
          font-weight: 400;
        }
      }
    }
    .chart-map {
      .highcharts-background {
        stroke-width: 0;
        fill: none;
      }
      .highcharts-reset-zoom {
        display: none;
      }
      .highcharts-map-series .highcharts-point {
        stroke-width: 0.25px !important;
        stroke: $color-deepblue-100 !important;
      }
      .highcharts-point {
        &.highcharts-color-0,
        &.highcharts-color-1 {
          // fill: red;
          &.highcharts-null-point {
            fill: $color-ocean !important;
          }
          &:not(.highcharts-null-point) {
            // fill: red;
          }
        }
        &.highcharts-color-1 {
          // fill: red;
          &.highcharts-null-point {
            fill: $color-ocean !important;
          }
          &:not(.highcharts-null-point) {
            // fill: red;
          }
        }
        &.highcharts-color-2 {
          // fill: red;
          &.highcharts-null-point {
            fill: none;
          }
          &:not(.highcharts-null-point) {
            fill: $color-sky !important;
          }
        }
        &.highcharts-color-3 {
          fill: white;
          fill-opacity: 1;
          stroke-width: 0;
          &:hover {
            fill: $color-deepblue-100 !important;
          }
        }
        &.highcharts-color-4 {
          fill: white !important;
          fill-opacity: 0.9;
          stroke-width: 0;
          &:hover {
            fill: $color-deepblue-100 !important;
          }
        }
      }

      .highcharts-data-label-color-3,
      .highcharts-data-label-color-4 {
        &:hover {
          .legends {
            .legend {
              &__label {
                span {
                  background-color: white;
                }
              }
            }
          }
        }
      }

      .highcharts-map-navigation {
        &.highcharts-zoom-in,
        &.highcharts-zoom-out {
          //   display: none;
        }
      }

      .highcharts-halo {
        fill: white;
        fill-opacity: 1;
      }

      .highcharts-data-labels {
        &.highcharts-series-3,
        &.highcharts-series-4 {
          pointer-events: none;
        }
      }
    }
  }
  .tool {
    &__content {
      padding: 0;
    }
  }
  .tool__remote {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    max-width: 330px;
    min-height: 218px;
    background-color: rgba($color-ocean, 0.7);
    box-shadow: 0 0 10px rgba($color-deepblue-100, 0.7);
    @include transitionElt(all, 0.25s, ease);
    @media print {
      display: none;
    }
    .remote_inner {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 40px;
      .tool {
        &__remote__chart {
          .btn {
            width: 24px;
            height: 24px;
            min-width: 0;
            min-height: 0;
            padding: 0;
            background-color: white;
            @include border-radius(50%);

            &__icon {
              display: flex;
              flex-flow: wrap;
              align-items: center;
            }
            &__center-map {
              margin-left: 0;
              margin-right: auto;
            }

            &__map {
              &-zoomout {
                margin-left: 0;
                margin-right: 0;
              }
              &-zoomin {
                margin-left: auto;
                margin-right: 5px;
              }
            }
            .ui-svg {
              width: 16px;
              height: 16px;
              margin: auto;
            }
          }
          & .remote__chart_inner {
            width: 100%;
            max-width: 90px;
            display: flex;
            align-items: center;
          }
        }
        &__title {
          padding: 10px 0;
          pointer-events: none;
        }
        &__legends {
          pointer-events: none;
          color: $color-sea;
          font-size: 12px;
          .legend {
            display: flex;
            align-items: center;
            &_icon {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              border: 1px solid currentColor;
              @include border-radius(50%);
              padding: 4px;
              & + .legend_label {
                margin-left: 8px;
              }
            }
          }
        }
        &__actions {
          color: $color-sea;
          a,
          .btn {
            color: $color-sea;
          }
        }
      }
    }
    .link {
      &-toggle {
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        padding: 0 10px;
        min-height: 36px;
        display: flex;
        flex-flow: wrap;
        align-items: center;
      }
    }
    &.remote-open {
      bottom: -224px;
      .toggle_label_on {
        display: none;
      }
    }
    &:not(.remote-open) {
      .toggle_label_off {
        display: none;
      }
    }
  }
  .chart-own-bubble {
    .chart {
      &_inner {
      }
    }
    .list {
      &_item {
        & + .list_item {
          margin-top: 20px;
        }
      }
      &-countries {
        display: none;
      }
      &-regions {
        &.list-open {
          .list {
            &-countries {
              display: block;
            }
          }
        }
        &:not(.list-open) {
        }
      }
    }
    .bubble {
      padding: 10px 0;
      &.bubble-regions {
        cursor: pointer;
        @for $s from 1 through 6 {
          &.bubble-#{$s} {
            .bubble_inner {
              &:before {
                width: (90 * (3 / $s)) * 1px;
                height: (90 * (3 / $s)) * 1px;
              }
            }
          }
        }
      }

      &.bubble-countries {
        @for $s from 1 through 100 {
          &.bubble-#{$s} {
            .bubble_inner {
              &:before {
                width: (80 * (2 / $s)) * 1px;
                height: (80 * (2 / $s)) * 1px;
              }
            }
          }
        }
      }

      &_inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: wrap;
        margin: 0 auto;
        &:before {
          content: "";

          position: relative;
          @include border-radius(50%);
          background-color: white;
          text-align: center;
        }
      }

      .data {
        &_wrapper {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: 5;
        }
        width: 100%;
        margin: 0 auto;
        font-family: $font-title-wf;
        text-align: center;
        span {
          display: inline-flex;
          margin: auto;
          @include border-radius(15px);
          line-height: 15px;
          background-color: rgba(#d5e0e1, 0.75);
          font-size: 12px;
          font-weight: 700;
          padding: 2px 5px;
        }
        & + .data {
          margin-top: 3px;
        }
        &_value {
          span {
            font-size: 16px;
            font-weight: 900;
          }
        }
        &_name {
        }
        &_subindicator {
          span {
            font-size: 10px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
