.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: $dropdown-z-index;
  // /* 3 */
  // box-sizing: border-box;
  //min-width: $dropdown-min-width;
  // /* 4 */
  //padding: $dropdown-padding;
  // background: $dropdown-background;
  // color: $dropdown-color;
  //@if(mixin-exists(hook-dropdown)) {@include hook-dropdown();}
  &.uk-open {
    display: block;
  }
}
