textarea {
  @include border-radius(20px);
  background-color: $color-primary-25;
  border: $input-border-size solid $color-primary-25;
  color: $color-primary;
  box-shadow: none;
  min-height: 140px;
  line-height: $input-height;
  width: 100%;
  padding: 0 10px;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  resize: none;
  width: 100%;
  @media screen and (min-width: $bp-tablet) {
    height: $input-heightDesk;
    line-height: $input-heightDesk;
  }
  &:focus,
  &:active {
    border-color: $color;
  }
}
