@import "../../../../assets/scss/__mandatory";

.project-details-reporting-docs {
  &_head,
  &_foot {
    .is-leader-view {
      .additional-docs {
        padding: 0 40px 24px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        &_inner {
          padding: 15px 40px;
          background: $color-sea;
          border-radius: 23px;
          width: 100%;
          h4 {
            border-bottom: 2px solid $white;
            padding: 10px 0;
            font-family: $font-title-wf;
            font-weight: $ft-black;
            font-size: 20px;
            line-height: 1.5;
            text-transform: uppercase;
          }
          p {
            margin: 10px 0;
          }
          .btn {
            .btn-label {
              font-size: 14px;
            }
          }
          .files {
            &-list {
              margin: 10px 0 0 16px;
            }
            &-item {
              list-style-type: disc;
              font-weight: bold;
              font-size: 14px;
              color: $color-deepblue-100;
              i {
                color: $color-alert;
                font-size: 16px;
                margin-left: 5px;
                cursor: pointer;
              }
              & + .files-item {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .is-hub-view {
    }
  }

  &_content {
    padding: 24px 40px 0;
    display: flex;
    flex-flow: column nowrap;

    .list {
      margin-bottom: 20px;
      .row {
        width: 100%;
        min-height: 110px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 10px 0;

        div + div {
          margin-left: initial;
          @media screen and (min-width: $bp-tablet) {
            margin-left: initial;
          }
        }

        .file-name,
        .description,
        .due-date,
        .save,
        .infos,
        .download,
        .upload,
        .actions {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          font-family: $font;
        }

        .description,
        .due-date,
        .save,
        .link,
        .download,
        .actions {
          margin-left: 10px;
          @media screen and (min-width: $bp-tablet) {
            margin-left: 15px;
          }
        }

        &:not(.filters) {
          padding: 10px 15px;
          border-radius: 0px 25px 25px 0px;
        }
        &.filters {
          min-height: initial;
          padding: 5px 15px;
          align-items: flex-end;
          i {
            font-size: 12px;
          }
        }

        &.is-filled {
          background-color: $color-successBright;
        }

        &.is-modified {
          background-color: $color-citron;
        }

        & + .row {
          margin-top: 4px;
        }
      }

      &.is-leader-view {
        .row {
          position: relative;

          .infos,
          .due-date,
          .download,
          .upload,
          .actions {
            align-items: flex-start;
          }
          .infos {
            width: calc(100% - 130px - 65px - 65px - 130px - 45px);
            flex-flow: column nowrap;
            h3 {
              font-size: 18px;
              font-family: $font-title-wf;
              margin-bottom: 30px;
            }
            .link-guide {
              font-weight: $ft-bold;
              font-size: 12px;
              a {
                color: $color-deepblue-100;
                text-decoration: underline;
                margin-left: 5px;
              }
            }
            p {
              margin-top: 5px;
              font-size: 13px;
              line-height: 1.6;
            }
          }
          .due-date {
            max-width: 130px;
            span {
              font-size: 12px;
              text-align: right;
              width: 100%;
            }
          }
          .download {
            max-width: 65px;
          }
          .upload {
            max-width: 65px;
            .is-uploading,
            .uploaded {
              padding: 3px 10px;
              min-width: 40px;
              min-height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: $white;
              color: $color-ocean;
              border: 2px solid $white;
              border-radius: 15px;
              .fa-spinner {
                animation: spin 2s infinite linear;
              }
            }
            @keyframes spin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
          .download,
          .upload {
            justify-content: center;
            text-align: center;
            font-size: 14px;
          }
          .actions {
            max-width: 130px;
            flex-flow: column nowrap;
            margin-top: 5px;
            .action {
              padding: 0;
              font-size: 12px;
              color: $color-deepblue-100;
              text-align: right;
              width: 100%;
              span {
                text-decoration: underline;
                margin-right: 5px;
              }
              & + .action {
                margin-top: 15px;
              }
            }
          }
          &:not(.filters) {
            align-items: flex-start;
            padding: 15px 30px 15px 15px;
            .due-date {
              height: 28px;
              display: flex;
              align-items: center;
              span {
                border-radius: 15px;
                padding: 6px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.passed {
                  background-color: $color-alert;
                }
                &.seven-days-left {
                  background-color: $color-citron;
                }
              }
              .calendar-date {
                border-radius: 15px;
                // padding: 6px 12px;
                // display: flex;
                justify-content: center;
                align-items: center;
                &.passed {
                  background-color: $color-alert;
                }
                &.seven-days-left {
                  background-color: $color-citron;
                }
              }
            }
            .download {
              border-left: 1px solid $color-deepblue-100;
            }
            .upload {
              border-left: 1px solid $color-sea;
            }

            .btn-review {
              position: absolute;
              bottom: 10px;
              right: 10px;
              max-width: 270px;
            }
          }
          &.filters {
            padding: 5px 30px 5px 15px;
            align-items: flex-start;
          }
        }
      }

      &.is-hub-view {
        .row {
          &:not(.filters) {
            flex-flow: column nowrap;
            .top-row,
            .bottom-row {
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
              align-items: stretch;
            }

            .label {
              font-family: $font;
              font-weight: $ft-bold;
              font-size: 12px;
              font-style: italic;
              margin-bottom: 5px;
            }

            .file-name {
              display: flex;
              flex-flow: column nowrap;
              margin-top: 0;
              input[type="text"] {
                width: 100%;
                min-height: 30px;
                padding: 0px 10px;
                border-radius: 8px;
                font-size: 12px;
                color: $color-deepblue-100;
                background-color: white;
                border: none;
                transition: all 0.25s ease;
                margin-bottom: 10px;
                &:focus {
                  font-weight: $ft-regular;
                }
                &::placeholder {
                  font-style: italic;
                  color: #829395;
                }
              }
            }

            .due-date,
            .due-date + .save {
              border-left: 1px solid $color-sea;
              padding-left: 10px;
            }

            &.is-filled {
              border-color: $color-seaLight;
            }
            &.is-modified {
              .due-date,
              .due-date + .save {
                border-color: $white;
              }
            }
          }
          .file-name {
            max-width: 250px;
            font-family: $font-title-wf;
            font-size: 18px;
            margin-top: 5px;
          }
          .description {
            width: calc(100% - 250px - 180px - 60px - 45px);
            textarea {
              border-radius: 8px;
              color: $color-deepblue-100;
              font-size: 12px;
              line-height: 1.6;
              padding: 10px 15px;
              min-height: 120px;
              height: calc(100% - 16px);
              &::placeholder {
                color: #829395;
                font-size: 12px;
              }
              &:focus {
                font-weight: $ft-regular;
              }
            }
          }
          .due-date {
            max-width: 180px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: flex-start;
            .datepicker {
              width: 100%;
              &_inner {
                width: 100%;
                max-width: 240px;
                position: relative;

                input[type="date"] {
                  width: 100%;
                  min-height: 30px;
                  padding: 0px 10px;
                  border-radius: 20px;
                  border: 2px solid $color-softGrey;
                  font-size: 12px;
                  font-weight: $ft-bold;
                  color: $color-ocean;
                  background-color: white;
                  transition: all 0.25s ease;
                  &:focus {
                    border-color: $color-deepblue-100;
                  }
                  &::-webkit-calendar-picker-indicator {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: auto;
                    height: auto;
                    color: transparent;
                    background: transparent;
                    cursor: pointer;
                  }
                  &:after {
                    font-family: $font-icon;
                    font-weight: $ft-black;
                    content: "\f073";
                    color: $color-ocean;
                    cursor: pointer;
                  }
                }

                &-value {
                  font-size: 12px;
                  font-weight: $ft-medium;
                  color: $color-ocean;
                  width: calc(100% - 35px);
                  background-color: $white;
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  height: 20px;
                  display: flex;
                  align-items: center;
                }
              }
              & + .datepicker {
                margin-top: 10px;
              }
            }
          }
          .save {
            max-width: 60px;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            .btn {
              width: 40px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              i {
                font-size: 14px;
              }
            }
            i {
              font-size: 12px;
              text-align: center;
              margin: 5px 0;
              &.disabled {
                opacity: 0.5;
              }
            }
          }
          .link {
            width: calc(100% - 250px - 60px);
            margin-top: 10px;
            input[type="text"] {
              width: 100%;
              min-height: 30px;
              padding: 0px 10px;
              border-radius: 8px;
              font-size: 12px;
              color: $color-deepblue-100;
              background-color: white;
              border: none;
              transition: all 0.25s ease;
              &:focus {
                font-weight: $ft-regular;
              }
              &::placeholder {
                font-style: italic;
                color: #829395;
              }
            }
            .no-template {
              position: relative;
              margin-top: 8px;
              input[type="checkbox"] {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                inset: 0;
                @media (any-hover: hover) {
                  &:hover {
                    cursor: pointer;
                  }
                }
                &:checked {
                  & + .box {
                    background-color: $color-ocean;
                    border: 2px solid $color-cobalt;
                    transition: 0.3s all ease-in-out;
                    i {
                      color: white;
                    }
                  }
                }
              }
              .box {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                background-color: $white;
                border: 2px solid $color-softGrey;
                border-radius: 5px;
                transition: 0.3s all ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
                i {
                  font-size: 10px;
                  color: transparent;
                }
              }
              span {
                font-family: $font;
                font-weight: $ft-bold;
                font-size: 12px;
                line-height: 1.2;
                margin-left: 22px;
              }
            }
          }
        }
      }
    }

    .btn {
      max-width: 210px;
      &.btn-submit {
        align-self: flex-end;
      }
    }
  }
}
