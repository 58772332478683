@import "../../../../assets/scss/__mandatory";

.management {
  &__footer {
    width: 100%;
    max-width: 940px;
    margin: 0 auto 80px auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 10px 0px 10px;
    border-top: 3px solid $color-sea;
  }
}
