@import "../../../../assets/scss/__mandatory";

.table {
  overflow-x: scroll;
  @media screen and (min-width: $bp-tablet) {
    overflow-x: visible;
  }
  @media print {
    font-family: $font-body-wf;
  }
  &_inner {
    position: relative;
    width: 100%;
    display: flex;
    //flex-flow: wrap;
    min-width: 660px;
    @media screen and (min-width: $bp-tablet) {
      min-width: 100%;
    }
    @media screen and (min-width: $bp-desk-small) {
      min-width: 860px;
    }
  }
  &__column {
    width: 100%;
    height: 100%;
    &.fixed {
      position: relative;
      top: 0;
      left: 0;
      span {
        padding-left: 10px;
      }
      @media screen and (min-width: $bp-tablet) {
        position: relative;
      }
      .row {
        &__head {
          &:after {
            width: 100%;
          }
        }
      }
    }
    &:not(.fixed) {
      span {
        &:not(.bar) {
          padding-right: 10px;
        }
      }
      .row__body,
      .row__foot {
        span {
          b {
            font-weight: 900;
            font-family: $font-title-wf;
          }
        }
      }
      &:last-child {
        .row {
          &__head {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .column {
    width: 100%;
    &-fund {
      @media screen {
        max-width: 220px;
      }
      @media screen and (min-width: $bp-tablet) {
        max-width: none;
        width: 30%;
      }
      span {
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
      }
      &:not(.gigf){
        span {
          @media screen {
            max-width: 230px;
          }
        }
      }
      &.gigf {
        span {
          padding-right:8px;
          @media screen {
            max-width: unset;
          }
        }
      }
    }
  }
  &__row {
    width: 100%;
    &-child {
      width: 100%;
      display: flex;
      flex-flow: wrap;
    }
  }
  .row {
    width: 100%;
    &__head {
      display: flex;
      flex-flow: wrap;
      height: 32px;
      align-items: flex-end;
      span {
        font-size: 13px;
        font-style: italic;
      }
      &:after {
        content: "";
        height: 1px;
        width: calc(100% - 20px);
        display: block;
        margin: 7px 0 10px 0;
      }
    }
    &__body,
    &__foot {
      height: 40px;
      display: flex;
      flex-flow: wrap;
      align-items: center;
    }
  }
  .subrow {
    width: 100%;
  }
  span {
    &.bar {
      min-width: 5px;
      height: 100%;
      margin-right: 5px;
      max-height: 16px;
      max-width: calc(100% - 70px);
    }
  }
}

.bar {
  display: block;
  @for $i from 1 through 1000 {
    &[data-percent="#{$i}"] {
      width: $i * 1%;
    }
  }
}

.bar_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .data,
  .value {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    span {
      // padding-right: 10px !important;
      &:before {
        font-size: 10px;
        line-height: 100%;
      }
    }
  }
  .data {
    &:before {
      content: "";
      position: absolute;
      width: 2px;
      height: 24px;
      background-color: white;
      @media print {
        background-color: $color-ocean;
      }
    }
  }
  &.positive {
    .data {
      left: 50%;
      &:before {
        left: 0px;
        transform: translateX(-50%);
      }
      .bar {
        margin-right: 0 !important;
      }
    }
    .value {
      justify-content: flex-end;
      text-align: right;
      right: 50%;
      span {
        padding-right: 5px !important;
        &:before {
          margin-left: 2px;
        }
      }
    }
    &.with-data {
      .value {
        span {
          &:before {
            content: "+";
          }
        }
      }
    }
  }
  &.negative {
    .data {
      right: 50%;
      justify-content: flex-end;
      .bar {
        margin-right: 0 !important;
      }
      &:before {
        transform: translateX(50%);
        right: 0px;
      }
    }
    .value {
      left: 50%;
      span {
        padding-left: 5px !important;
        &:before {
          margin-right: 2px;
        }
      }
    }
    &.with-data {
      .value {
        span {
          &:before {
            // content: "-";
          }
        }
      }
    }
  }
}
