@import "../../../../assets/scss/__mandatory";

.own-html {
  &.wf-active {
    .loader {
      &:not(.on) {
        opacity: 0;
        pointer-events: none;
        .loader__anim {
        }
      }
      &.on {
      }
    }
  }
}

.loader {
  &-main {
    // position: fixed;
    // bottom: 0;
    // right: 0;
    z-index: 4000;
    width: 100%;
    // height: 100vh;
    display: flex;
    flex-flow: wrap;
    align-content: center;
    justify-content: center;
    transition: all 0.25s ease;
  }

  //  &.on {
  .loader__anim {
    opacity: 1;
    animation: 4s scaleUp infinite cubic-bezier(1, -0.25, 0.54, 0.92);

    &[data-color="multicolors"] {
      &:before {
        animation-name: multicolorsColors, rotating;
      }
    }
    &[data-color="blue"] {
      &:before {
        background-color: $color-ocean;
        animation-name: rotating;
      }
    }
    &[data-color="white"] {
      &:before {
        background-color: $white;
        animation-name: rotating;
      }
    }
    &:before {
      animation-duration: 4s;
      animation-timing-function: cubic-bezier(1, -0.25, 0.54, 0.92);
      animation-iteration-count: infinite;
    }
  }
  //  }
  &_inner {
    width: 100%;
    height: 100%;
    // background-color: $color-deepblue-100;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__anim {
    margin: auto;
    width: 40px;
    height: 40px;
    opacity: 0;
    transition: all 0.25s ease;
    &:before {
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: $color-ocean;
      border-radius: 0;
    }
  }
}

@keyframes rotating {
  //Start rotation
  0%, // #1
  33.3%, // #2
  66.5% // #3
  {
    transform: rotate(0);
  }
  // Border changing
  5%, // #1
  38.3%, // #2
  71.5% // #3
  {
    border-radius: 2px;
  }
  25%, // #1
  58.3%, // #2
  91.5% // #3
  {
    border-radius: 0;
  }
  // end rotation & pause
  28.5%, 33.2%, // #1
  61.7%, 66.4%, // #2
  95%, 100% // #3
  {
    transform: rotate(360deg);
  }
  22% {
    border-radius: 10px;
  }
  55.3% {
    border-radius: 10px;
  }
  88.5% {
    border-radius: 10px;
  }
}
@keyframes scaleUp {
  // Scale changing
  15%, // #1
  48.3%, // #2
  81.5% // #3
  {
    transform: scale(1);
  }
  22%, // #1
  55.3%, // #2
  88.5% // // #3
  {
    transform: scale(1.15);
  }
  25%, // #1
  58.3%, // #2
  91.5% // #3
  {
    transform: scale(1);
  }
}

@keyframes multicolorsColors {
  // Background changing #1
  15% {
    background-color: $color-ocean;
  }
  22% {
    border-radius: 10px;
    background-color: $color-citron;
  }
  // Background changing #2
  48.3% {
    background-color: $color-citron;
  }
  55.3% {
    border-radius: 10px;
    background-color: $color-success;
  }
  // Background changing #3
  81.5% {
    background-color: $color-success;
  }
  88.5% {
    border-radius: 10px;
    background-color: $color-ocean;
  }
}
