@import "../../../../assets/scss/__mandatory";

.page {
  &-tool {
    &-sgd-impact-synthesis {
      min-height: calc(100vh - 260px);
    }
  }
}

:global {
  .btn {
    &.download-rosace {
      width: 100px;
    }
  }
}
