$color-white: #ffffff;
$color-black: #000000;

$color-blue-light: #00395e;
$color-blue: #00395e;
$color-blue-dark: #00395e;

$color-red-light: #ae0e26;
$color-red: #d11e39;
$color-red-dark: #990033;

$color-html: #ffffff !default;
$color-body: #ffffff !default;
$color-text: #000 !default;

$color-mainheader-background: transparent !default;
$color-mainheader-border: transparent !default;
$color-mainheader-logo-background: transparent !default;
$color-mainheader-logo: transparent !default;

$color-menu-item-link-background: transparent !default;
$color-menu-item-link-background-current: transparent !default;
$color-menu-item-link-current: transparent !default;
$color-menu-item-link-text: $color-red;
$color-menu-item-link-text-current: $color-white;
$color-menu-item-link-border: transparent !default;

$color-hamburger: $color-white default;
$color-hamburger-bg: $color-red default;
$color-hamburger-open: $color-red-dark default;
$color-hamburger-bg-open: $color-white default;

$color-overlay: rgba(67, 37, 237, 0.5); // RVB = 67,37, 237

$color-footer-background: transparent !default;

// FORM
$color-form: #ffffff !default;
$color-form-text: #ffffff !default;
$color-form-title: #ffffff !default;
$color-form-input: #0d2138 !default;
$color-form-input-bg: transparent !default;
$color-form-input-border: #ffffff !default;
$color-form-input-border-focus: #eeeeee !default;
$color-form-select: #dddc5f !default;
$color-form-select-bg: #ffffff !default;
$color-form-submit: #00deff !default;
$color-form-submit-bg: transparent !default;
$color-form-submit-border: transparent !default;
$color-form-placeholder: #ffffff !default;
$color-form-icon: #e6e6e6 !default;
$color-form-label: #0d2138 !default;
$color-form-required: #ffffff !default;
$color-form-error: #ad0404 !default;
$color-form-error-text: #ffffff !default;
$color-form-warning: #000 !default;
$color-form-info: #000 !default;
$color-autofill: transparent !default;

$color-cookies-consent-link: $color-white;

$color-success: #00bf8f;
$color-warning: #ffcc00;
$color-error: #cb2027;
$color-imbd: #edc32c;
//$color-facebook: #3746a2;
$color-facebook: #4325ed; // CUSTOM
//$color-twitter: #55b9ed;
$color-twitter: #00deff; // CUSTOM
$color-youtube: #bb0000;
$color-instagram: #125688;
//$color-veplus: #t;
$color-vine: #00bf8f;
//$color-linkedin: #007bb5;
$color-linkedin: #4325ed; // CUSTOM
$color-pinterest: #cb2027;
$color-whatsapp: #4dc247;
$color-snapchat: #fffc00;
$color-success: #00bf8f;
$color-warning: #ffcc00;
$color-error: #cb2027;
