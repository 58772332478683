@import "../../../../assets/scss/__mandatory";

.tool {
  width: 100%;
  &_inner {
    width: 100%;
  }
  &_lp {
    @media print {
      margin: 0 auto;
    }
    .chart__own {
      // @media print {
      //   max-width: 700px;
      //   background: yellow;
      //   .highcharts-root,
      //   .highcharts-background {
      //     max-width: 700px;
      //   }
      // }
    }
    &_inner {
      @media screen {
        max-width: 940px;
      }
    }
    & + {
      .tool_lp {
        @media screen {
          margin-top: 30px;
        }
        @media screen and (min-width: $bp-tablet) {
          margin-top: 20px;
        }
        @media print {
          color: $color-deepblue-100;
        }
      }
    }
    .tool {
      &__title {
        @media screen {
          text-align: center;
        }
        @media screen and (min-width: $bp-tablet) {
          text-align: left;
        }
        h1,
        h2 {
          font-weight: 900;
          font-size: 20px;
          text-transform: uppercase;
          @media screen and (min-width: $bp-tablet) {
            font-size: 24px;
          }
          &:not(.white) {
            color: $color-deepblue-100;
          }
          &.white {
            @media screen {
              color: white;
            }
          }
        }
        h1,
        h2,
        h3,
        h4 {
          font-family: $font-title-wf;
        }
        h4 {
          font-size: 13px;
          @media screen and (min-width: $bp-tablet) {
            font-size: 14px;
          }
        }
        h3 {
          & + h4 {
            margin-top: 5px;
          }
        }
      }
      &__content {
        width: 100%;
        @media print {
          height: fit-content;
        }

        &:not(.white) {
          color: $color-deepblue-100;
        }
        &.white {
          color: white;
        }
      }
      &__actions {
        margin-top: 20px;
        display: none;
        @media screen and (min-width: $bp-tablet) {
          display: block;
        }
        &:not(.white) {
          color: $color-deepblue-100;
        }
        &.white {
          color: white;
        }
        .export {
          display: inline-flex;
          .selector {
            display: flex;
            align-content: center;
          }
          .label {
            margin: auto 0;
            font-size: 12px;
            & + .select {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  &_lp_inner {
    @media screen {
      padding: 15px;
    }
    @media screen and (min-width: $bp-tablet) {
      padding: 40px;
    }
  }
  &__legends {
    padding: 5px 0;
    margin-bottom: 10px;
    .legend {
      font-size: 13px;
      &.with-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .legend_icon {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          border: 1px solid currentColor;
          @include border-radius(50%);
          padding: 4px;
          @media print {
            border: 1px solid $color-deepblue-100;
          }
          & + .legend_label {
            margin-left: 8px;
          }
        }
      }
      &_label {
        font-family: $font-title-wf;
        & + .legend_icon {
          margin-left: 5px;
        }
      }
    }
  }
  .legends-smartphone {
    @media screen and (min-width: $bp-tablet) {
      display: none;
    }
  }
  // .highcharts-root {
  //   width: 100% !important;
  // }
}
