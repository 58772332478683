@import "../../../../assets/scss/__mandatory";

.tool_key-metrics {
  padding: 0 20px;
  @media print {
    font-family: $font-body-wf;
  }
  @media screen and (min-width: $bp-tablet) {
    padding: 0;
  }

  .tool_lp_inner {
    position: relative;
    background-color: $color-seaLight;
    @import "../../../../assets/scss/custom/tables/base";
    @import "../../../../assets/scss/custom/tables/lp/key-metrics";

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      pointer-events: none;
      height: 100%;
      background: -moz-linear-gradient(
        90deg,
        rgba(16, 45, 64, 0) 0%,
        rgba(16, 45, 64, 0.5) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(16, 45, 64, 0) 0%,
        rgba(16, 45, 64, 0.5) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(16, 45, 64, 0) 0%,
        rgba(16, 45, 64, 0.5) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#102d40",endColorstr="#102d40",GradientType=1);
      @media screen and (min-width: $bp-tablet) {
        content: initial;
      }
    }
    .tool {
      &__title {
        @media screen {
          text-align: left;
        }
      }
    }
    .tool__legends {
      color: $color-deepblue-100;
    }
    .tool__content {
      padding: 20px 0;
    }
  }

  .legend {
    margin: 10px 0 15px;
    font-style: italic;
    font-size: 12px;
  }
}

// .tool_meridiam_funds {
//   padding: 0 20px;
//   @media print {
//     font-family: $font-body-wf;
//   }
//   @media screen and (min-width: $bp-tablet) {
//     padding: 0;
//   }
//   .tool_lp_inner {
//     position: relative;
//     background-color: $color-seaLight;
//     @import "../../../../assets/scss/custom/tables/base";
//     @import "../../../../assets/scss/custom/tables/lp/meridiam-funds";
//     &:after {
//       content: "";
//       position: absolute;
//       right: 0;
//       top: 0;
//       width: 30px;
//       pointer-events: none;
//       height: 100%;
//       background: -moz-linear-gradient(
//         90deg,
//         rgba(16, 45, 64, 0) 0%,
//         rgba(16, 45, 64, 0.5) 100%
//       );
//       background: -webkit-linear-gradient(
//         90deg,
//         rgba(16, 45, 64, 0) 0%,
//         rgba(16, 45, 64, 0.5) 100%
//       );
//       background: linear-gradient(
//         90deg,
//         rgba(16, 45, 64, 0) 0%,
//         rgba(16, 45, 64, 0.5) 100%
//       );
//       // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#102d40",endColorstr="#102d40",GradientType=1);
//       @media screen and (min-width: $bp-tablet) {
//         content: initial;
//       }
//     }
//     .tool__legends {
//       color: $color-deepblue-100;
//     }
//     .tool__content {
//       padding: 20px 0;
//     }
//   }
// }
