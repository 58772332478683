@import "../../../../assets/scss/__mandatory";

.filters {
  &-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 20px;

    .selector,
    .tool-search,
    .project-completed {
      width: calc(50% - 5px);
    }

    .selector {
      &-label {
        font-weight: $ft-bold;
        font-size: 12px;
        margin-bottom: 10px;
      }
      .select {
        position: relative;
        .btn {
          width: 100%;
          max-width: unset;
        }
        .drop {
          width: 100%;
          max-width: unset;
          .group {
            padding: 10px 0;
            max-height: 200px;
            overflow-y: scroll;
            .item {
              padding: 0 10px;
              span {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .tool-search {
      text-align: right;
      margin-top: 15px;
      .input {
        position: relative;
        input {
          height: 26px;
          padding: 0px 10px;
          border-radius: 20px;
          border: 2px solid #d5e0e1;
          width: 100%;
          font-size: 14px;
          color: $color-ocean;
          background-color: white;
          transition: all 0.25s ease;
          &::placeholder {
            font-size: 14px;
            color: $color-ocean;
          }
          &:focus,
          &.filled {
            background-color: $color-seaLight;
          }
        }
        &-icon {
          font-weight: $ft-black;
          font-size: 14px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          .fa-search {
            color: $color-ocean;
          }
          .fa-times {
            color: $color-alert;
            cursor: pointer;
          }
        }
      }
    }

    .project-completed {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      color: $color-ocean;
    }
  }
}
