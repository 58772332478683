@import "../../../../assets/scss/__mandatory";

.management {
  &__title {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10px 15px 10px;
    &_text {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      color: $color-deepblue-100;
      .title {
        font-family: $font-body-wf;
        font-weight: $ft-regular;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
      }
      .subtitle {
        font-weight: $ft-bold;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
