@import "../../../../../assets/scss/__mandatory";

.table-key-metrics {
  &.table {
    .table_inner {
      min-width: 760px;
      @media screen and (min-width: $bp-tablet) {
        min-width: 100%;
      }
      @media screen and (min-width: $bp-desk-small) {
        min-width: 860px;
      }
    }

    .column {
      &-fund {
        min-width: 250px;
        &.gigf {
          width: 40%;
        }
      }
      &-vintage {
        width: 15%;
        min-width: 90px;
        @media screen and (min-width: $bp-tablet) {
          min-width: 0;
          width: 9%;
        }
        .row__body {
          color: $color-ocean;
        }
        &.gigf {
          width: 12%;
        }
      }
      &-commitment {
        min-width: 120px;
        width: 20%;
        @media screen and (min-width: $bp-tablet) {
          min-width: 0;
          width: 14%;
        }
        .row__body {
          color: $color-ocean;
        }
      }
      &-drawn, &-status {
        min-width: 90px;
        width: 15%;
        @media screen and (min-width: $bp-tablet) {
          min-width: 0;
          width: 19%;
        }
        &.gigf {
          width: 24%;
        }
      }
      &-drawn {
        .row__body {
          color: $color-ocean;
        }
      }
      &-status {
        .row__body {
          font-size: 14px;
          font-weight: 700;
          color: $color-deepblue-100;
        }
      }
      &-irr {
        min-width: 160px;
        width: 20%;
        @media screen and (min-width: $bp-tablet) {
          min-width: 0;
          width: 18%;
        }
      }
      &-tvpi {
        width: 20%;
        min-width: 70px;
        @media screen and (min-width: $bp-tablet) {
          min-width: 0;
          width: 7%;
        }
        &.gigf {
          width: 10%;
        }
      }
      &-sdg-rating {
        width: 20%;
        min-width: 110px;
        @media screen and (min-width: $bp-tablet) {
          min-width: 0;
          width: 15%;
        }
      }
      &-trajectory {
        width: 15%;
        @media screen and (min-width: $bp-tablet) {
          width: 8%;
        }
      }
    }

    .row {
      &__head {
        @media screen and (min-width: $bp-tablet) {
          height: 48px;
        }
        @media screen and (min-width: $bp-desk-small) {
          height: 32px;
        }
        &:after {
          content: "";
          background-color: $color-deepblue-100;
        }
      }

      &__body {
        &:nth-child(odd) {
          background-color: white;
          @media print {
            background-color: $color-seaLight;
          }
        }
      }
      &__foot {
        color: white;
        background-color: $color-ocean;
      }
    }
  }
}
