@import "../../../../assets/scss/__mandatory";
@import "../../../../assets/scss/custom/sliders/_react-slider-stick";

.tool_impact-indicator {
  padding: 0 20px;
  @media screen and (min-width: $bp-tablet) {
    padding: 0;
  }
  .tool_lp_inner {
    background-color: $color-sky;
    overflow: hidden;
    padding-bottom: 30px;
  }

  .slider {
    width: 100%;
    height: 100%;
    padding: 20px 0;
    &_inner {
      position: relative;
      padding: 20px 0;
      text-align: center;
      @media screen and (min-width: $bp-tablet) {
        text-align: left;
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-deepblue-100;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }
    .slide {
      width: 100%;
      height: 100%;
      &__head {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 15px;
        flex-flow: wrap;
      }
      &___icon {
        width: 100%;
        color: $color-ocean;
        text-align: center;
        @media screen and (min-width: $bp-tablet) {
          text-align: left;
          width: 40px;
        }
        i {
          font-size: 28px;
          margin: 0 auto;
        }
        svg {
          &.icon-key-impact-global {
            width: 36px;
            height: 36px;
            transform: translateX(-2px);
          }
        }
        & + .slider___title {
          @media screen and (min-width: $bp-tablet) {
            margin-left: 10px;
          }
        }
      }
      &___title {
        width: 100%;
        @media screen and (min-width: $bp-tablet) {
          width: calc(100% - 50px);
        }
        h3,
        h4 {
          text-transform: uppercase;
          font-size: 16px;
        }
        h3 {
          font-family: $font-title-wf;
          font-weight: 900;
        }
        h4 {
          color: $color-ocean;
        }
      }
      &__body {
        position: relative;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        padding: 0 30px;
        @media screen and (min-width: $bp-tablet) {
          padding: 0 10px;
        }
        &:before {
          position: absolute;
          top: 0;
          left: 50%;
          width: 1px;
          height: 100%;
          background-color: $color-deepblue-100;
          @media screen and (min-width: $bp-tablet) {
            content: "";
          }
        }
        .data {
          width: 100%;
          padding-bottom: 20px;
          @media screen and (min-width: $bp-tablet) {
            width: 50%;
            padding-left: 30px;
          }
          &_label {
            font-size: 12px;
            color: $color-deepblue-100;
            & + .data_value {
              margin-top: 5px;
            }
            @media screen and (min-width: $bp-tablet) {
              font-size: 14px;
            }
          }
          &_value {
            font-family: $font-title-wf;
            font-size: 18px;
            font-weight: 900;
            color: white;
            @media screen and (min-width: $bp-tablet) {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  .btn {
    &-slider {
      position: absolute;
      top: 50%;
      @include border-radius(60px);
      background-color: $color-ocean;
      border: 2px solid $color-deepblue-100;
      color: $color-ocean;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-prev {
        left: 0;
        margin-left: -20px;
        transform: translateY(-50%) translateX(-50%);
        @media screen and (min-width: $bp-tablet) {
          margin-left: -40px;
        }
        .btn {
          &_icon {
            transform: translateX(10px) translateY(-50%);
          }
        }
      }
      &-next {
        right: 0;
        margin-right: -20px;
        transform: translateY(-50%) translateX(50%);
        @media screen and (min-width: $bp-tablet) {
          margin-right: -40px;
        }
        .btn {
          &_icon {
            transform: translateX(-10px) translateY(-50%);
          }
        }
      }
      .btn {
        &_icon {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          color: white;
          font-size: 30px;
          text-align: center;
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    display: flex !important;
    justify-content: center;
    transform: translateY(40px);
    li {
      padding: 0 2px;
      button {
        position: relative;
        width: 13px;
        height: 13px;
        background-color: $color-deepblue-100;
        border: none;
        @include border-radius(50%);
        text-indent: 9999px;
        padding: 0;
        &:before {
          content: "";
          position: absolute;
          top: 3px;
          left: 3px;
          margin: 0;
          padding: 0;
          display: block;
          width: 7px;
          height: 7px;
          @include border-radius(50%);
          background-color: white;
          opacity: 0;
        }
      }
      &.slick-active {
        button {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}
