@import "../../../../../assets/scss/__mandatory";

.projects {
  width: 100%;
  color: $color-deepblue-100;
  background-color: white;
  @include border-radius(20px);
  padding: 10px 30px;
  &-wrapper {
    margin-top: 5px;
  }
  &-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      display: flex;
      align-items: center;
      &_label {
        font-size: 16px;
        font-weight: $ft-bold;
      }
      &_icon {
        margin-left: 5px;
      }
      & + .btn {
        .btn {
          &_label {
            font-size: 12px;
            font-weight: $ft-regular;
            font-style: italic;
          }
        }
      }
    }
  }
  &-remote {
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
    appearance: none;
    display: block;
    &:checked {
      & + .projects {
        &-body {
          display: block;
        }
      }
    }
  }
  &-body {
    display: none;
    padding-top: 20px;
  }
  &-list {
    max-height: 280px;
    overflow: scroll;
    li {
      display: flex;
      width: 100%;
      min-height: 24px;
      align-items: center;
      background-color: $color-seaLight;
      padding: 0 10px;
      @include border-radius(15px);
      span {
        font-weight: $ft-bold;
        font-size: 12px;
        display: block;
        margin: auto 0;
      }
      & + li {
        margin-top: 5px;
      }
    }
  }
}
