@import "../../../../assets/scss/__mandatory";
@import "../../../../assets//scss//custom//modal/base";

#modalPageNeedSupport {
  .btn-close {
    right: 20px;
    top: 20px;
    .btn-label {
      color: $color-deepblue-100;
    }
    .btn-icon {
      border-color: $color-deepblue-100;
    }
  }
  .uk-modal-dialog {
  }
  .own-modal-content {
    width: 100%;
    margin-top: 0;
    max-width: 734px !important;
    background-color: white;
    padding: 20px;

    align-items: flex-start;
  }

  .uk-modal {
    &-body {
      .row {
        textarea,
        input {
          width: 100%;
          border: 2px solid #d5e0e1;
          font-size: 12px;
          font-weight: 700;
          background-color: white;
          transition: all 0.25s ease;
          padding: 10px;
          border-radius: 10px;
        }
        & + .row {
          margin-top: 10px;
        }
      }
      textarea {
        border: 2px solid #d5e0e1;
        width: 100%;
        font-size: 12px;
        font-weight: 700;
        background-color: white;
        transition: all 0.25s ease;
        padding: 10px;
        border-radius: 10px;
        min-height: 190px;
      }
      .wrapp-cta {
        margin-top: 10px;
        .btn-primary {
          min-height: 30px;
          color: white;
          background-color: $color-ocean;
          padding: 0 25px;
          border-radius: 15px;
        }
      }
    }
  }
}
