@import "../../../../assets/scss/__mandatory";

.indicator {
  &-section {
    &.project-stage {
      .indicator-section_head {
        border-top: none;
      }
    }

    &.construction,
    &.expected-fc-date {
      &.visible {
        display: block;
      }
      &.invisible {
        display: none;
      }
    }

    &.section-project-stage {
      display: none;
    }

    &_head {
      border-top: 2px solid $color-deepblue-100;
      padding: 10px 0;
      margin: 0 40px;
      &__title {
        font-family: $font-title-wf;
        font-weight: $ft-black;
        font-size: 20px;
        line-height: 1.5;
      }
      &__radio {
        .label {
          font-size: 16px;
          margin: 5px 0 10px 0;
        }
        .radio {
          &_container {
            display: flex;
          }
          &_item {
            display: flex;
            align-items: center;
            label {
              font-size: 14px;
              margin-right: 5px;
              display: flex;
              align-items: center;
              position: relative;
              &::after {
                content: "";
                width: 16px;
                height: 16px;
                display: block;
                border: 2px solid $color-sea;
                border-radius: 10px;
                margin-left: 5px;
              }
            }
            input[type="radio"] {
              display: none;
              &:checked {
                & + label {
                  font-weight: $ft-bold;
                  &::before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    background-color: $white;
                    position: absolute;
                    right: 7px;
                    top: 7px;
                    border-radius: 6px;
                  }
                  &::after {
                    background-color: $color-deepblue-100;
                    border-color: $color-deepblue-100;
                  }
                }
              }
            }
            & + .radio_item {
              margin-left: 10px;
            }
          }
        }
      }
      &__toggle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .label {
          font-size: 12px;
          margin-right: 10px;
          text-align: right;
        }
        .value {
          font-size: 14px;
        }
        .toggle {
          margin: 0 5px;
          &-switcher {
            pointer-events: none;
            position: relative;
            background-color: $white;
            width: 30px;
            height: 20px;
            border: 2px solid #829395;
            border-radius: 15px;
            cursor: pointer;
            transition: 0.3s all ease-in-out;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 12px;
              transform: translateY(-50%);
              background-color: #829395;
              width: 10px;
              height: 10px;
              border-radius: 20px;
              transition: 0.3s all ease-in-out;
            }
          }
          &-input {
            opacity: 0;
            width: 30px;
            height: 20px;
            position: absolute;
            @media (any-hover: hover) {
              &:hover {
                cursor: pointer;
              }
            }
            &:checked {
              & + .toggle-switcher {
                border-color: $color-successBright;
                background-color: $color-successBright;
                transition: 0.3s all ease-in-out;
                &::before {
                  background-color: $white;
                  left: 4px;
                  transition: 0.3s all ease-in-out;
                }
              }
            }
          }
        }
      }
    }

    &_content {
      border-top: 2px solid $color-softGrey;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: stretch;
      margin: 0 40px;
      .part {
        width: 50%;
        padding: 20px 0;
        &_label {
          font-style: italic;
          font-size: 12px;
          margin-bottom: 5px;
        }
        .indicators_item_group {
          margin-top: 5px;
          .indicator_item_line {
            float: left;
            margin-right: 10px;
            width: 130px;
          }
        }
        .indicators {
          &_list {
          }
          &_item {
            font-size: 14px;
            line-height: 2.15;

            .selector,
            .text,
            .text-unit,
            .datepicker,
            .upload,
            .tag {
              .label {
                font-weight: $ft-bold;
                font-size: 12px;
                margin-bottom: 0;
              }
            }

            .selector {
              .select {
                position: relative;
                .btn {
                  width: 100%;
                  max-width: 240px;
                }
                .drop {
                  max-width: 240px;
                  .group {
                    padding: 10px 0;
                    .item {
                      padding: 0 10px;
                      span {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }

            .text,
            .text-unit {
              width: 100%;
              display: flex;
              flex-flow: column nowrap;
              justify-content: flex-start;
              align-items: flex-start;
              input[type="text"],
              input[type="number"] {
                width: 100%;
                min-height: 30px;
                padding: 0px 10px;
                border-radius: 20px;
                border: 2px solid $color-softGrey;
                font-size: 14px;
                font-weight: $ft-bold;
                color: $color-ocean;
                background-color: white;
                transition: all 0.25s ease;
                &::placeholder {
                  color: $color-ocean;
                  font-size: 14px;
                }
                &:focus {
                  border-color: $color-deepblue-100;
                }
              }
              .with-unit-container {
                display: flex;
                width: 100%;
                input[type="number"] {
                  text-align: right;
                  max-width: 230px;
                }
                .unit {
                  font-size: 14px;
                  display: block;
                  margin-left: 5px;
                  white-space: nowrap;
                }
              }
            }

            .datepicker {
              width: 100%;
              &_inner {
                width: 100%;
                max-width: 240px;
                position: relative;

                input[type="date"] {
                  width: 100%;
                  min-height: 30px;
                  padding: 0px 10px;
                  border-radius: 20px;
                  border: 2px solid $color-softGrey;
                  font-size: 14px;
                  font-weight: $ft-bold;
                  color: $color-ocean;
                  background-color: white;
                  transition: all 0.25s ease;
                  &:focus {
                    border-color: $color-deepblue-100;
                  }
                  &::-webkit-calendar-picker-indicator {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: auto;
                    height: auto;
                    color: transparent;
                    background: transparent;
                    cursor: pointer;
                  }
                  &:after {
                    font-family: $font-icon;
                    font-weight: $ft-black;
                    content: "\f073";
                    color: $color-ocean;
                    cursor: pointer;
                  }
                }

                &-value {
                  font-size: 14px;
                  font-weight: $ft-medium;
                  color: $color-ocean;
                  width: calc(100% - 35px);
                  background-color: $white;
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  height: 20px;
                  display: flex;
                  align-items: center;
                }
              }
            }

            .upload {
              &_head {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              .btn,
              .is-uploading {
                padding: 3px 10px;
                width: 40px;
                max-height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
              }
              .is-uploading {
                background-color: $white;
                color: $color-ocean;
                border: 2px solid $white;
                border-radius: 15px;
                font-size: 16px;
                .fa-spinner {
                  animation: spin 2s infinite linear;
                }
              }
              @keyframes spin {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(360deg);
                }
              }
              .files-item {
                span {
                  margin-right: 5px;
                }
                i {
                  @media (any-hover: hover) {
                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
            }

            .tag {
              @import "../../../../assets/scss/custom/mui/MuiAutoCompleteSelect.scss";
            }

            & + .indicators_item {
              margin-top: 5px;
            }
          }
        }
        .about {
          border: 1px solid $color-softGrey;
          border-radius: 15px;
          color: $color-ocean;
          font-size: 14px;
          padding: 10px 15px;
          min-height: 150px;
          height: calc(100% - 16px);
          &::placeholder {
            color: $color-ocean;
            font-size: 14px;
          }
          &.filled {
            background-color: $color-seaLight;
            color: $color-deepblue-100;
          }
        }

        & + .part {
          margin-left: 30px;
        }
      }
    }

    &.board-members {
      .indicator-section_content {
        flex-flow: column nowrap;
        .part {
          width: 100%;
          & + .part {
            margin-left: 0;
            padding: 0 0 20px 0;
          }

          .indicators {
            &_list {
              border-radius: 15px;
              background-color: $color-softGrey;
              padding: 10px 20px 15px 20px;
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              align-items: center;
              & + .indicators_list {
                margin-top: 10px;
              }
            }
            &_item {
              &:nth-child(1),
              &:nth-child(2) {
                width: calc(50% - 5px);
              }
              &:nth-child(3),
              &:nth-child(4) {
                width: calc(30% - 5px);
              }
              &:nth-child(5),
              &:nth-child(6) {
                width: calc(20% - 10px);
              }
              &:nth-child(2),
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6) {
                margin-left: 10px;
              }
              & + .indicators_item {
                margin-top: 0;
              }

              &.full {
                width: 100%;
              }
            }
          }

          .about {
            min-height: 100px;
          }
        }
        .btn-add {
          max-width: 240px;
          .btn-label {
            text-align: left;
            margin: initial;
          }
        }
        .indicators_list {
          position: relative;
          .btn-delete {
            position: absolute;
            right: 0;
            top: 0;
            max-width: 240px;
            .btn-label {
              text-align: left;
              margin: initial;
            }

            .btn-icon {
              font-size: 16px;
            }
          }
        }
      }
    }

    &.insurance-data {
      .indicator-section_content {
        flex-flow: column nowrap;
        .part {
          width: 100%;
          .indicators {
            &_list {
              border-radius: 15px;
              background-color: $color-softGrey;
              padding: 10px 20px 15px 20px;
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              align-items: center;
              & + .indicators_list {
                margin-top: 10px;
              }
            }
            &_item {
              &:nth-child(1) {
                width: calc(60% - 5px);
              }
              &:nth-child(2) {
                width: calc(40% - 5px);
              }
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                width: calc(33% - 5px);
              }
              &:nth-child(2),
              &:nth-child(4),
              &:nth-child(5) {
                margin-left: 10px;
              }
              & + .indicators_item {
                margin-top: 0;
              }
            }
          }
          .about {
            min-height: 100px;
          }
        }
        .btn-add {
          max-width: 240px;
          .btn-label {
            text-align: left;
            margin: initial;
          }
          & + .indicators_list {
            margin-top: 20px;
          }
        }
        .indicators_list {
          position: relative;
          .part {
            padding: 10px 0 0 0;
          }
          &.claims {
            .indicators_list {
              align-items: flex-start;
            }
            .indicators_item {
              &:nth-child(1) {
                width: calc(50% - 10px);
              }
              &:nth-child(2) {
                width: calc(50% - 10px);
                margin-left: 20px;
              }
            }
          }
          .btn-delete {
            position: absolute;
            right: 0;
            top: 0;
            max-width: 240px;
            .btn-label {
              text-align: left;
              margin: initial;
            }
            .btn-icon {
              font-size: 16px;
            }
          }
          & + .indicators_list {
            margin-top: 20px;
          }
        }
      }
    }

    &_foot {
      position: relative;
      margin-top: 8px;
      background-color: $color-seaLight;
      border-radius: 20px;
      padding: 5px 40px;
      input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        @media (any-hover: hover) {
          &:hover {
            cursor: pointer;
          }
        }
        &:checked {
          & + .box {
            background-color: $color-ocean;
            border: 2px solid $color-cobalt;
            transition: 0.3s all ease-in-out;
            i {
              color: white;
            }
          }
        }
      }
      .box {
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-color: $white;
        border: 2px solid $color-softGrey;
        border-radius: 5px;
        transition: 0.3s all ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        i {
          font-size: 10px;
          color: transparent;
        }
      }
      span {
        font-family: $font;
        font-weight: $ft-bold;
        font-size: 12px;
        line-height: 1.2;
        margin-left: 22px;
      }
      &.is-valid {
        background-color: $color-successBright;
      }
    }

    &.is-valid {
      background-color: #d4fff2;
      border-radius: 20px;
      &.board-members .indicator-section_content .part .indicators_list,
      &.insurance-data .indicator-section_content .part .indicators_list {
        background-color: $color-sea;
      }
    }

    & + .indicator-section {
      margin-top: 24px;
    }
  }
}
