@import "../../../../assets/scss/__mandatory";
@import "../../../../assets/scss/_highcharts/highcharts";
@import "../../../../assets/scss/_highcharts/custom";

.tool_impact-returns {
  @media screen {
    padding: 0 20px;
  }
  @media screen and (min-width: $bp-tablet) {
    padding: 0;
  }
  .tool_lp_inner {
    @media screen {
      background-color: $color-successBright;
    }
    @media print {
      //      height: auto;
    }
  }
  $colors: $color-deepblue-100 #f7a35c #8085e9 #f15c80 #e4d354 #2b908f #f45b5b;
  @import "../../../../assets/scss/_highcharts/custom";

  .highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.9;
    &.highcharts-color-0 {
      fill: white;
      @media screen {
        stroke: $color-successBright;
      }

      @media print {
        stroke: $color-ocean;
      }
      stroke-width: 2px;
      &:hover {
        fill-opacity: 1;
        fill: $color-deepblue-100;
      }
    }
  }
  .highcharts-halo {
    .highcharts-color-0 {
      &:hover {
        fill: $color-deepblue-100;
      }
    }
  }
  .highcharts-column-series {
    rect.highcharts-point {
      outline-width: 0;
      outline-color: $color-deepblue-100;

      &:hover {
        fill: white;
        outline-color: white;
      }
    }
  }
  .highcharts-yaxis-labels {
    fill: $color-deepblue-100;
    color: $color-deepblue-100;
  }
  .highcharts-axis-line {
    stroke: $color-deepblue-100;
    stroke-width: 2px;
  }
  .highcharts-axis {
    &.highcharts-xaxis {
      stroke: $color-deepblue-100;
      stroke-width: 2px;
    }
  }
  .highcharts-xaxis-labels {
    span {
      &:first-child {
        .chart__label_x {
          span {
            @media screen and (min-width: $bp-tablet) {
              display: none !important;
            }
          }
        }
      }
      overflow: visible !important;
    }
  }
  .chart__label {
    &.chart__label_x {
      &.last-label {
        text-align: right;
        @media screen and (min-width: $bp-tablet) {
          transform: translateX(-25px);
        }
        span {
          transform: translateX(-10px) translateY(5px);
          @media screen and (min-width: $bp-tablet) {
            transform: translateX(0px) translateY(0px);
          }
          span {
            text-align: right;
            line-height: 1;
            &.label_text {
              width: 50px;
              word-break: break-word;
              white-space: break-spaces;
            }
          }
        }
      }
    }
    &-sdg {
      @media screen and (min-width: $bp-tablet) {
        white-space: nowrap;
      }
    }
  }
  .tool {
    &__content {
      padding: 10px 0 0 0;
    }
  }
  // .filter {
  //   &__bar {
  //     padding: 20px 0 30px 0;
  //   }
  // }
  .select-sdg {
    .drop {
      max-width: 94px;
      min-width: 94px;
    }
  }
  .filters {
    &-bar {
      width: 100%;
      padding: 20px 0 30px 0;
      &_inner {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: wrap;
      }
      .select {
        display: flex;
        align-items: center;
        &_label {
          font-size: 12px;
          & + .btn {
            margin-left: 10px;
          }
        }
        &-sdg {
          width: 100%;
          @media screen and (min-width: $bp-tablet) {
            width: auto;
          }
          ul {
            width: 94px;
            li {
              min-height: 16px;
              font-size: 12px;
              &.current {
                font-weight: bold;
              }
            }
          }
        }
      }
      .tool {
        &__legends {
          color: $color-deepblue-100;
          font-size: 12px;
          width: 100%;
          @media screen and (min-width: $bp-tablet) {
            width: auto;
            padding-left: 20px;
          }
          .legend {
            display: flex;
            align-items: center;
            &_icon {
              color: white;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              border: 1px solid currentColor;
              @include border-radius(50%);
              padding: 4px;
              & + .legend_label {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.tooltip-chart-impact-returns {
  color: $color-deepblue-100;
  padding: 20px 10px !important;
  .line {
    font-size: 14px;
    & + .line {
      margin-top: 5px;
    }
    b {
      font-weight: bold;
    }
    &-1 {
      color: $color-deepblue-100;
      font-weight: 700;
      font-size: 14px;
    }
    &-2 {
      color: $color-ocean;
    }
    &-3 {
      color: $color-deepblue-100;
    }
    &-4 {
      color: $color-ocean;
    }
    &-5 {
      color: $color-ocean;
    }
    .type {
      &_icon {
        font-size: 18px;
        img {
          width: 18px;
          height: 18px;
          filter: invert(0.7) sepia(2) saturate(3) hue-rotate(160deg);
        }
        & + .type_name {
          margin-left: 5px;
        }
      }
      &_name {
      }
    }
  }
  .tooltip {
    &_icon {
      font-size: 12px;
    }
    &_value {
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
