@import "../../../../assets/scss/__mandatory";

.project-details-assumptions-leader {
  &_head,
  &_foot {
    .download-csv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 24px 80px;
    }
  }

  &_content {
    padding: 0 80px 20px 80px;
    background-color: $color-cobalt;
    color: $white;
    .item {
      padding: 20px 0 40px 0;

      .title {
        font-family: $font-title-wf;
        font-weight: $ft-black;
        font-size: 20px;
        line-height: 1.5;
      }
      .description {
        font-size: 16px;
        line-height: 1.25;
        margin-bottom: 20px;
      }
      .img {
        img {
          width: 100%;
        }
      }
      & + .item {
        border-top: 2px solid $color-deepblue-100;
      }
    }
  }
}
