@import "../../../../assets/scss/__mandatory";
@import "../../../../assets/scss/custom/ui/modal";

.modal-team {
  .uk-modal-dialog {
    width: 100%;
  }
  .own-modal-content {
    position: relative;
    width: 100%;
    margin-top: 0;

    background-color: white;
    padding: 0px !important;
    max-width: 734px !important;
  }

  .btn-close {
    right: 20px !important;
    top: 20px !important;
    .btn-label {
      color: $color-deepblue-100;
    }
    .btn-icon {
      border-color: $color-deepblue-100;
    }
  }
  .uk-modal-header,
  .uk-modal-footer {
    padding: 20px !important;
  }
  .uk-modal-body {
    position: relative;
    padding: 0 20px 10px 20px !important;
    color: $color-text;
    min-height: 300px !important;
    max-height: 300px !important;
    // &:after {
    //   content: "";
    //   width: 100%;
    //   height: 20px;
    //   pointer-events: none;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   @include bg-gradient(180deg, rgba(white, 0), rgba(white, 1));
    // }
  }

  .own-table {
    width: 100%;
    ul {
      width: 100%;
      &.table-head {
        .row {
          border-bottom: 1px solid $color-ocean;
          min-height: 24px;
        }
        .col {
          .cel {
            text-align: left;
            font-style: italic;
            font-size: 12px;
            padding: 0;
          }
        }
      }
      li {
        display: flex;
        align-items: center;
        min-height: 40px;

        .form-user {
          display: flex;
          width: 100%;
        }

        &.row-empty {
          justify-content: center;
          div,
          button {
            width: 100%;
            text-align: center;
            color: $color-alert;
            font-style: italic;
            font-size: 14px;
            &:hover {
              color: $color-cobalt;
            }
          }
        }
        & + li {
          border-top: 1px solid $color-softGrey;
        }
        .col {
          margin: auto;

          .cel {
            display: flex;
            align-items: center;
            width: 100%;
            span {
              display: block;
              width: 100%;
              i {
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
}

.wrapp-manager {
  width: 100%;
  &.manager-team {
    .own-table {
      ul {
        &.table-head {
        }
        &.table-body {
          .col {
            &.col-5,
            &.col-6 {
              &:hover {
                color: $color-alert;
              }
            }
            .cel {
              font-size: 12px;
            }
          }
          li {
            &:hover {
              &:not(.row-empty) {
                background-color: $color-softGrey;
              }
            }
          }
        }
        li {
          &.myself {
          }
          &.row-empty {
            min-height: 100px;
            font-style: italic;
          }
          &.row-add {
            ng-form {
              width: 100%;
              display: flex;
            }
            .btn-select {
              min-width: 80px;
            }
          }
          app-team-user {
            width: 100%;
            display: flex;
            align-items: center;
            min-height: 40px;
          }
        }
      }
    }
  }
}

.modal-team {
  .col {
    margin: auto;
    span {
      display: inline-block;
      width: 100%;
      // padding: 0 10px 0 0s;
    }
    .cel {
      text-align: left;
      font-size: 12px;
      font-weight: 700;
      padding: 0 5px;
    }
    &.col-1 {
      width: 15%;
    }
    &.col-2 {
      width: 15%;
    }
    &.col-3 {
      width: calc(100% - 30% - 120px - 40px - 50px);
    }
    &.col-4 {
      width: 120px;
      div {
        width: 100%;
      }
      .btn-select {
        width: 100%;
      }
    }
    &.col-5 {
      width: 40px;
      text-align: center;
      .fa-times {
        font-size: 14px;
      }
    }
    &.col-6 {
      width: 50px;
      span {
        text-align: right;
      }
    }
  }
}
