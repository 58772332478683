.line {
  display: block;
}

.no-zindex {
  z-index: initial !important;
}

.upp {
  text-transform: uppercase;
}
