@import "../../../../assets/scss/__mandatory";

.project-details-assumptions-hub {
  padding: 24px 40px;
  &_head {
    border-bottom: 2px solid $color-deepblue-100;
    padding-bottom: 5px;
    h2 {
      font-family: $font-title-wf;
      font-weight: $ft-black;
      font-size: 20px;
      line-height: 1.5;
    }
    h3 {
      font-size: 16px;
      line-height: 1.25;
    }
  }

  &_content {
    margin-bottom: 30px;
    .item {
      padding: 20px 0;
      border-bottom: 2px solid $color-deepblue-100;
      .action {
        position: relative;
        margin-bottom: 10px;
        button,
        label {
          padding: 0;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 0;
          .btn {
            &-label {
              font-style: italic;
              font-size: 12px;
            }
            &-icon {
              margin-left: 5px;
              .fa-sync-alt {
                color: $color-ocean;
              }
              .fa-times-circle {
                color: $color-alert;
              }
            }
          }
          & + button {
            margin-left: 15px;
          }
        }
      }
      .label {
        font-style: italic;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        input {
          height: 30px;
          padding: 0px 15px;
          border-radius: 15px;
          border: 1px solid #d5e0e1;
          width: 100%;
          font-family: $font;
          font-size: 14px;
          font-weight: $ft-bold;
          color: $color-deepblue-100;
          background-color: white;
          transition: all 0.25s ease;
          &::placeholder {
            font-size: 14px;
            font-weight: $ft-regular;
            color: $color-ocean;
          }
          &:focus {
            border-color: $color-deepblue-100;
          }
        }
      }
      .description {
        margin: 20px 0;
        textarea {
          border-radius: 15px;
          border: 1px solid #d5e0e1;
          color: $color-deepblue-100;
          font-family: $font;
          font-size: 14px;
          font-weight: $ft-bold;
          line-height: 1.6;
          padding: 10px 15px;
          min-height: 170px;
          &::placeholder {
            font-size: 14px;
            font-weight: $ft-regular;
            color: $color-ocean;
          }
          &:focus {
            border-color: $color-deepblue-100;
          }
        }
      }
      .img {
        padding: 5px 15px;
        min-height: 32px;
        background: $color-seaLight;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          list-style: disc;
          font-weight: $ft-bold;
          font-size: 14px;
          margin-left: 15px;
        }
        .actions {
          display: flex;
          button,
          label {
            padding: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            .btn {
              &-label {
                font-style: italic;
                font-size: 12px;
              }
              &-icon {
                margin-left: 5px;
                .fa-sync-alt {
                  color: $color-ocean;
                }
                .fa-times-circle {
                  color: $color-alert;
                }
              }
            }
            & + button {
              margin-left: 15px;
            }
          }
        }
      }
    }
    .btn {
      margin-top: 10px;
    }
  }

  &_foot {
    .additional-docs {
      display: flex;
      justify-content: center;
      align-items: center;
      &_inner {
        padding: 15px 40px;
        background: $color-seaLight;
        border-radius: 23px;
        width: 100%;
        h4 {
          border-bottom: 2px solid $white;
          padding: 10px 0;
          font-family: $font-title-wf;
          font-weight: $ft-black;
          font-size: 20px;
          line-height: 1.5;
          text-transform: uppercase;
        }
        p {
          margin: 10px 0;
        }
        .btn {
          .btn-label {
            font-size: 14px;
          }
          &.disabled {
            background-color: $color-softGrey;
            color: #829395;
            border-color: $color-softGrey;
            pointer-events: none;
            cursor: default;
          }
        }
        .files {
          &-list {
            margin: 10px 0 0 16px;
          }
          &-item {
            list-style-type: disc;
            font-weight: bold;
            font-size: 14px;
            color: $color-deepblue-100;
            i {
              color: $color-alert;
              font-size: 16px;
              margin-left: 5px;
              cursor: pointer;
            }
            & + .files-item {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
