@import "../../../../assets/scss/__mandatory";
@import "../../../../assets/scss/core/buttons";

.hub-toolbar {
  position: relative;
  background: $color-seaLight;
  .inner {
    position: relative;
    display: flex;
    width: 100%;
    background-color: $color-ocean;
    color: $color-deepblue-100;
    max-width: 960px;
    min-height: 60px;
    font-size: 14px;
    margin: auto;
    flex-flow: wrap;
    align-items: center;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      display: block;
    }
    &:after {
      right: 100%;
      width: calc((100vw - 960px) / 2);
      background-color: $color-deepblue-100;
    }
    &:before {
      width: 30px;
      left: 100%;
      background-color: $color-ocean;
      @include border-radius(0 30px 30px 0);
    }
    .menu {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      flex-flow: wrap;
      min-height: 60px;
      background-color: $color-deepblue-100;
      align-items: center;
      margin-left: 0;
      &:before {
        content: "";
        position: absolute;
        width: 30px;
        padding: 0 40px 0 20px;
        left: 100%;
        top: 0;
        height: 100%;
        background-color: $color-deepblue-100;
        @include border-radius(0 30px 30px 0);
      }
      .btn {
        margin: auto;
        color: white;
      }
    }
  }

  .selector {
    display: flex;
    align-items: center;
    .label {
      & + .label-year {
        padding-left: 5px;
      }
    }
    .select {
      margin-left: 6px;
      position: relative;
      height: 30px;
      color: #fff;

      select {
        color: inherit;
        font-size: 0.7em;
        font-weight: 700;
        border: none;
        box-shadow: none;
        border-radius: 0;
        background: $blue;
        height: 100%;
        width: 100%;
        cursor: pointer;
        outline: none;
        padding-right: 25px;
        padding-bottom: 2px;
        padding-left: 12px;
        border: 2px solid $dark-blue;
        border-radius: 15px;

        -moz-appearance: none;

        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #000;
        }

        -webkit-appearance: none;

        &::-ms-expand {
          display: none;
        }
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 10px;
      background-color: #fff;
      width: 350px;
      min-width: 200px;
      border-radius: 15px;
      input {
        width: 100%;
      }

      i {
        margin-right: 10px;
        color: $blue;
      }
    }
  }

  .export {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-left: 6px;
      span {
        font-weight: 700;
      }
    }
  }
}

.uk-offcanvas {
  &:not(.uk-open) {
    .uk-offcanvas-bar {
      @media screen and (min-width: $bp-tablet) {
        left: -640px;
      }
    }
  }
  &.uk-open {
    &.uk-offcanvas-overlay {
      &:before {
        background-color: rgba($color-deepblue-100, 0.8);
      }
    }
  }
  .uk-offcanvas-bar {
    @media screen and (min-width: $bp-tablet) {
      width: 640px;
    }
  }
  .own-offcanvas {
    background-color: white;
    color: $color-text;
    display: flex;
    flex-flow: wrap;
  }
  .inner-offcanvas {
    position: relative;
    margin: 60px 0 auto auto;
    width: 100%;
    max-width: 330px;
    padding-top: 60px;
    .btn-close {
      position: absolute;
      top: 15px;
      left: 0;
    }
  }
  .offcanvas-head {
    font-size: 14px;
    line-height: 1.1;
    padding: 8px 0;
    .brand {
    }
    .survey {
      font-weight: 900;
    }
  }
  .offcanvas-body {
    padding: 20px 0;
    h4 {
      font-style: italic;
      font-size: 12px;
      padding: 5px 0;
    }
    ul {
      li {
        div {
          display: flex;
          width: 100%;
          min-height: 40px;
          padding: 0 15px;
          span {
            display: block;
            margin: auto 0;
            font-size: 14px;
          }
        }
        &.current {
          background-color: rgba($color-deepblue-100, 1);
          color: white;
          font-weight: 700;
          pointer-events: none;
        }
        &:not(.current) {
          &:hover {
            cursor: pointer;
            background-color: $color-citronBright;
          }
        }
      }
    }
  }
}

.btn.btn-close-burger {
  @include border-radius(50%);
  padding: 0;
  min-width: 30px;
  color: white;
  border: 2px solid $color-deepblue-100;
  background-color: $color-ocean;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-icon {
    @include transitionElt(all, 0.25s, ease);
    i {
      display: block;
      line-height: 1;
      font-size: 14px;
    }
  }
  &:hover {
    background-color: $color-deepblue-100;
    .btn-icon {
      @include rotateElt(90deg);
    }
  }
}
