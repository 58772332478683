@import "../../../../../assets/scss/__mandatory";

.label-calendar-date {
  &.soon,
  &.passed {
    :global {
      // .react-add-to-calendar {
      //   &__button {
          border-radius: 30px!important;
        }
    //   }
    // }
  }
  &.soon {
    :global {
      // .react-add-to-calendar {
      //   &__button {
          // color: $color-alert;
          // background-color: white;
          background-color: #f2e068!important;
      //   }
      // }
    }
  }
  &.passed {
    // :global {
      // .react-add-to-calendar {
      //   &__button {
          pointer-events: none!important;
          background-color: $color-alert!important;
          color: white!important;
          span {
            i {
              display: none!important;
            }
          }
      //   }
      // }
    // }
  }
  .calendar-date {
    width: 100%;
    position: relative;
    z-index: 1;
    &.init,
    &.soon,
    &.passsed {
      width: 100%;
    }
    &.init {
      :global {
        .react-add-to-calendar {
          &__button {

          }
        }
      }
    }
    &.soon,
    &.passed {
      :global {
        // .react-add-to-calendar {
        //   &__button {
            border-radius: 30px;
          }
      //   }
      // }
    }
    &.soon {
      :global {
        // .react-add-to-calendar {
        //   &__button {
            // color: $color-alert;
            // background-color: white;
            background-color: #f2e068;
        //   }
        // }
      }
    }
    &.passed {
      :global {
        // .react-add-to-calendar {
        //   &__button {
            pointer-events: none;
            background-color: $color-alert;
            color: white;
            span {
              i {
                display: none;
              }
            }
        //   }
        // }
      }
    }
    :global {
      .react-add-to-calendar {
        &__wrapper {
          position: relative;
        }
        &__button {
          position: relative;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 28px;
          font-weight: $ft-light;
          color: $color-primary;
          span {
            display: block;
            padding: 0 !important;
            font-size: 12px;
            color: inherit;
          }
          i {
            position: absolute;
            left: 8px;
            top: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            transform: translateY(-50%);
            align-items: center;
            justify-content: center;
          }
        }
        &__dropdown {
          position: absolute;
          background-color: $color-primary;
          border-radius: 15px;
          color: white;
          a {
            color: inherit;
          }
          ul {
            overflow: hidden;
            li {
              width: 100%;
              display: flex;
              flex-flow: wrap;
              align-items: center;
              font-size: 12px;
              a {
                display: block;
                width: 100%;
                padding: 0 15px;
                min-height: 28px;
                line-height: 28px;
                white-space: nowrap;
                &:hover {
                  font-weight: $ft-bold;
                }
              }
            }
          }
        }
      }
    }
  }
}