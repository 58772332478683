@import "../../../../../assets/scss/__mandatory";

.cmp {
  margin-left: 15px;

  &.cmp-chart {
    position: relative;
    .inner-chart {
      position: relative;
      color: $color-ocean;
    }
    .inner {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      text-align: left;
      padding: 0 15px;
      width: 100%;
    }
    .chart-head {
      width: 100%;
      .inner {
        height: 60px;
      }
      @include border-radius(10px 10px 0 0);
      .icon {
        position: relative;
        width: 60px;
        height: 60px;
        .sdg-icon {
          position: absolute;
          height: 50px;
          width: 50px;
          top: 50%;
          left: 50%;
          @include border-radius(50%);
          transform: translateX(-50%) translateY(-50%);
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .select {
        width: 80px;
        .btn {
          width: 100%;
          &.btn-select {
            & + .drop {
              width: 80px;
            }
          }
        }
        & + .label {
          margin-left: 20px;
        }
      }
      .label {
        width: calc(100% - 60px - 100px);
        font-weight: 700;
        font-size: 16px;
        color: $color-deepblue-100;
      }
    }
    .chart-back {
      background-color: $color-softGrey;
      .inner {
        height: 50px;
        color: $color-deepblue-100;
        justify-content: space-between;
      }
      .btn-back {
        .btn-label {
          color: $color-deepblue-100;
        }
      }
      .indications {
        font-style: italic;
        font-size: 12px;
        text-align: right;
      }
    }
    .chart-content {
      background-color: white;
      .no-datas {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        min-height: 380px;
        color: $color-alert;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        p {
          & + p {
            margin-top: 10px;
          }
        }
        .link {
          color: inherit;
          font-weight: 700;
          font-style: italic;
          .link-label {
            font-size: 18px;
            text-decoration: underline;
          }
        }
      }
      .list-targets {
        padding: 20px 0;
        .target {
          & + .target {
            margin-top: 20px;
          }
        }
      }
    }
    .target {
      position: relative;
      padding: 5px 0;
      &:hover {
        .inner-target {
          &:not(.open) {
            &:before {
              opacity: 1;
            }
          }
        }
      }
      .inner-target {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        font-size: 12px;
        &:before {
          content: "";
          background-color: $color-softGrey;
          position: absolute;
          width: calc(100% + 30px);
          height: 100%;
          top: 0;
          left: -15px;
          opacity: 0;
          @include transitionElt(all, 0.3s, ease);
        }
        &:not(.open) {
          & + .wrapp-subtarget {
            opacity: 0;
            .list-subtargets {
              display: none;
            }
          }
        }
        &.open {
          & + .wrapp-subtarget {
            padding: 34px 0 0 0;
            .list-subtargets {
              padding: 20px 30px;
            }
          }
        }
      }
      .target-num,
      .target-content {
        position: relative;
      }
      .target-num {
        color: $color-sky;
        font-weight: 700;
        width: 30px;
        padding-right: 5px;
        text-align: right;
      }
      .target-content {
        color: $color-deepblue-100;
        width: calc(100% - 35px);
      }
      .target-title {
        cursor: pointer;
      }
      .wrapp-subtarget {
        position: relative;
        .btn-close-indicators {
          position: absolute;
          right: 15px;
          top: 34px;
          @include border-radius(3px 3px 0 0);
          background-color: $color-softGrey;
          font-size: 12px;
          transform: translateY(-100%);
          padding: 3px 5px;
          color: $color-deepblue-100;
          font-weight: 700;
          cursor: pointer;
          i {
            &.fas {
              margin-left: 8px;
              color: $color-alert;
            }
          }
        }
        .list-subtargets {
          position: relative;
          @include border-radius(10px);
          background-color: $color-softGrey;
          font-size: 12px;
          &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 0 0 20px;
            border-color: transparent transparent transparent $color-softGrey;
            position: absolute;
            bottom: 100%;
            left: 40px;
          }
          li {
            & + li {
              margin-top: 20px;
            }
          }
        }
      }
    }
    .subtarget {
      .subtarget-title {
        color: $color-deepblue-100;
        font-weight: 700;
        .subtarget-num {
          & + span {
            padding-left: 10px;
          }
        }
      }
    }
    .score-bar {
      padding: 5px 0;
      .bar {
        position: relative;
        width: 0%;
        height: 14px;
        @include transitionElt(all, 1.2s, ease);
        & + .bar {
          margin-top: 2px;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        &:after {
          height: 100%;
          position: absolute;
          top: 0;
          left: 100%;
          font-weight: 700;
          line-height: 14px;
          padding: 0 5px;
        }
        &.bar1 {
          &:before {
            background-color: $color-deepblue-100;
          }
          &:after {
            color: $color-deepblue-100;
          }

          span {
            color: $color-deepblue-100;
          }
        }
        &.bar2 {
          &:before {
            background-color: $color-ocean;
          }
          &:after {
            color: $color-ocean;
          }

          span {
            color: $color-ocean;
          }
        }

        .noData {
          display: block;
          width: 50px;
        }

        @for $s from 0 through 500 {
          &[data-score="#{$s}"] {
            //&:before {
            width: $s / 5 * 1%;
            //}
            $m: $s / 100;
            &:after {
              content: "#{$m}";
              //left: $s / 5 * 1%;
              @if $s > 100 {
                transform: translateX(-100%);
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.info-sdg {
  width: 100%;
  max-width: 260px;
  display: flex;
  flex-flow: wrap;
  align-items: flex-end;
  margin: 48px auto 0 auto;
  padding-bottom: 40px;
  // @media screen and (min-width: $breakpoint-desk-large) {
  //     margin-top: 0px;
  //     position: absolute;
  //     bottom: 0%;
  //     left: 100%;
  //     transform: translateX(-50px) translateY(25%);
  // }
  // @media screen and (min-width: $breakpoint-desk-xlarge) {
  //     transform: translateX(-25%) translateY(50%);
  // }
  .info-head {
    width: 100%;
    .icon {
      .sdg-icon {
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        @include border-radius(50%);
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .label {
      font-size: 12px;
      font-weight: 700;
      color: $color-deepblue-100;
      padding: 15px 0 5px 0;
      text-align: center;
      margin: 0 auto;
      max-width: 200px;
    }
  }
  .info-body {
    width: 100%;
    margin: 10px auto;
    padding: 5px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $color-sea;
    border-bottom: 1px solid $color-sea;
    .scores {
      position: relative;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      text-align: center;
      justify-content: center;
      .score {
        position: relative;
        display: flex;
        flex-flow: wrap;
        width: 100%;
        text-align: center;
        justify-content: center;
        span {
          display: block;
        }
        .label {
          width: 100%;
          font-size: 12px;
          & + .value {
            margin-top: 5px;
          }
        }
        .values {
          width: 100%;
          margin: auto;
          span {
            display: block;
          }
        }
        .value {
          font-weight: 700;
        }
      }
      &:not(.with-comparaison) {
        .score {
          width: 100%;
          .value {
            font-size: 24px;
          }
        }
      }
      &.with-comparaison {
        .score {
          width: 50%;
          min-height: 48px;
          .value {
            font-size: 16px;
          }
          &.score1 {
            text-align: right;
            padding-right: 20px;
          }
          &.score2 {
            text-align: left;
            padding-left: 20px;
            color: $color-ocean;
          }
        }
        .square {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 25px;
          height: 25px;
          @include border-radius(4px);
          transform: rotate(45deg) translateX(-50%) translateY(-50%);
          overflow: hidden;
          transform-origin: 0% 0%;
          .inner-square {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 36px;
            height: 36px;
            transform: rotate(-45deg) translateX(0%) translateY(-25px);
            display: block;
            &:before,
            &:after {
              position: absolute;
              top: 50%;
              content: "";
              width: 50%;
              height: 100%;
              display: block;
            }
            &:before {
              background-color: $color-deepblue-100;
              right: 50%;
              //transform-origin: 100% 50%;
              transform: rotate(0) translateY(-50%);
            }
            &:after {
              background-color: $color-ocean;
              left: 50%;
              //transform-origin: 0% 50%;
              transform: rotate(0) translateY(-50%);
            }
          }
        }
      }
    }
  }
}

@each $color, $hexaColor, $completHexaColor in $listColorESG {
  .cmp {
    &.chart-bar {
      &[data-sdg="#{$color}"] {
        .chart-head {
          background-color: $hexaColor;
          .label {
            color: $completHexaColor;
          }
        }
      }
    }
  }
}

.btn {
  &.btn-select {
    color: white;
    background-color: $color-ocean;
    border: 2px solid $color-deepblue-100;
    @include border-radius(15px);
    padding: 0 5px;
    min-height: 28px;
  }
}
