@import "../../../../assets/scss/__mandatory";

.layout {
  &-user {
    width: 100%;
    min-height: calc(100vh - 140px);
    display: flex;
    flex-flow: wrap;
  }
}
