input {
  &[type="file"] {
    @media screen and (min-width: $bp-tablet) {
    }
    &:focus,
    &:active {
      border-color: $color;
    }
    & + .btn {
    }
  }
}
