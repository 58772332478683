@import "../../../../assets/scss/__mandatory";

.page_header {
}

.back-btn {
  .btn {
    max-width: 250px;
  }
}

.page_content {
  background-color: white;
  color: $color-deepblue-100;
  max-width: 940px;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  &-inner {
    .section-title {
      font-size: 16px;
      font-weight: $ft-black;
      text-transform: uppercase;
    }

    .italic {
      font-family: $font;
      font-size: 12px;
    }

    .separator {
      width: 100%;
      height: 1px;
      background: $color-softGrey;
      margin: 30px 0;
    }

    .row-input {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      .label {
        font-weight: $ft-bold;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 5px;
      }
      input[type="text"] {
        width: 100%;
        min-height: 30px;
        padding: 0px 10px;
        border-radius: 20px;
        border: 2px solid $color-softGrey;
        font-size: 14px;
        font-weight: $ft-bold;
        color: $color-deepblue-100;
        background-color: white;
        transition: all 0.25s ease;
        &.is-modified {
          background-color: $color-sea;
        }
        &:placeholder-shown {
          font-size: 14px;
          color: $color-ocean;
          background-color: white;
        }
      }
      .selector {
        width: 100%;
        .select {
          .btn {
            max-width: none;
            text-align: left;
            padding: 0 5px 0 15px;
            &.is-modified {
              background-color: $color-citron !important;
              color: $color-deepblue-100;
            }
          }
          .drop {
            max-width: none;
          }
          .drop-select {
            ul {
              max-height: 200px;
              overflow-y: scroll;
            }
          }
        }
      }

      & + .row-input {
        margin-top: 15px;
      }
    }

    .last-accessed {
      height: 30px;
      display: flex;
      justify-content: center;
    }

    .toggle {
      justify-content: flex-end;
      &-switcher {
        pointer-events: none;
        position: relative;
        background-color: $color-successBright;
        width: 30px;
        height: 20px;
        border: 2px solid $color-successBright;
        border-radius: 15px;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translateY(-50%);
          background-color: white;
          width: 10px;
          height: 10px;
          border-radius: 20px;
          transition: 0.3s all ease-in-out;
        }
      }
      &-input {
        opacity: 0;
        width: 30px;
        height: 20px;
        position: absolute;
        @media (any-hover: hover) {
          &:hover {
            cursor: pointer;
          }
        }
        &:checked {
          & + .toggle-switcher {
            background-color: white;
            border-color: #829395;
            transition: 0.3s all ease-in-out;
            &::before {
              background-color: #829395;
              left: 12px;
              transition: 0.3s all ease-in-out;
            }
          }
        }
      }
    }

    .left,
    .right {
      width: 50%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }
    .right {
      margin-left: 20px;
    }
  }
}
