@import "../../../../assets/scss/__mandatory";

.chart {
  &-bars {
    position: relative;
    width: 100%;
  }
  &__head {
    position: absolute;
    width: 100%;
    height: 60px;
    z-index: 15;
    .inner {
      padding-left: 0;
      .icon {
        position: relative;
        width: 60px;
        height: 60px;
        .sdg-icon {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          height: 50px;
          width: 50px;
          top: 50%;
          left: 50%;
          @include border-radius(50%);
          transform: translateX(-50%) translateY(-50%);
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .select {
      width: 88px;
      .btn {
        width: 100%;
        &.btn-select {
          & + .drop {
            width: 80px;
          }
        }
      }
      & + .label {
        margin-left: 20px;
      }
    }
    .label {
      width: calc(100% - 60px - 108px);
      font-weight: 700;
      font-size: 16px;
      color: $color-deepblue-100;
    }
  }
  .inner {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    text-align: left;
    padding: 0 15px;
    width: 100%;
  }
  &.chart-bars {
    .chart__head {
      .label {
        font-size: 14px;
        margin-left: 10px;
      }
    }
    .chart-content {
      .no-datas {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        min-height: 380px;
        color: $color-alert;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        p {
          & + p {
            margin-top: 10px;
          }
        }
        .link {
          color: inherit;
          font-weight: 700;
          font-style: italic;
          .link-label {
            font-size: 18px;
            text-decoration: underline;
          }
        }
      }
      .list-targets {
        width: 100%;
        padding: 20px 0;
        .target {
          & + .target {
            margin-top: 20px;
          }
        }
      }
    }
    .target {
      position: relative;
      padding: 5px 0;
      &:hover {
        .inner-target {
          &:not(.open) {
            &:before {
              opacity: 1;
            }
          }
        }
      }
      .inner-target {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        font-size: 12px;
        &:before {
          content: "";
          background-color: $color-softGrey;
          position: absolute;
          width: calc(100% + 30px);
          height: 100%;
          top: 0;
          left: -15px;
          opacity: 0;
          @include transitionElt(all, 0.3s, ease);
        }
        &:not(.open) {
          & + .wrapp-subtarget {
            opacity: 0;
            .list-subtargets {
              display: none;
            }
          }
        }
        &.open {
          & + .wrapp-subtarget {
            padding: 34px 0 0 0;
            .list-subtargets {
              padding: 20px 30px;
            }
          }
        }
      }
      .target-num,
      .target-content {
        position: relative;
      }
      .target-num {
        color: $color-sky;
        font-weight: 700;
        width: 30px;
        padding-right: 5px;
        text-align: right;
      }
      .target-content {
        color: $color-deepblue-100;
        width: calc(100% - 35px);
      }
      .target-title {
        cursor: pointer;
      }
      .wrapp-subtarget {
        position: relative;
        .btn-close-indicators {
          position: absolute;
          right: 15px;
          top: 34px;
          @include border-radius(3px 3px 0 0);
          background-color: $color-softGrey;
          font-size: 12px;
          transform: translateY(-100%);
          padding: 3px 5px;
          color: $color-deepblue-100;
          font-weight: 700;
          cursor: pointer;
          i {
            &.fas {
              margin-left: 8px;
              color: $color-alert;
            }
          }
        }
        .list-subtargets {
          position: relative;
          @include border-radius(10px);
          background-color: $color-softGrey;
          font-size: 12px;
          &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 0 0 20px;
            border-color: transparent transparent transparent $color-softGrey;
            position: absolute;
            bottom: 100%;
            left: 40px;
          }
          li {
            & + li {
              margin-top: 20px;
            }
          }
        }
      }
    }
    .subtarget {
      .subtarget-title {
        color: $color-deepblue-100;
        font-weight: 700;
        .subtarget-num {
          & + span {
            padding-left: 10px;
          }
        }
      }
    }
    .score-bar {
      padding: 5px 0;
      .bar {
        position: relative;
        width: 0%;
        height: 14px;
        @include transitionElt(all, 1.2s, ease);
        & + .bar {
          margin-top: 2px;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        &:after {
          height: 100%;
          position: absolute;
          top: 0;
          left: 100%;
          font-weight: 700;
          line-height: 14px;
          padding: 0 5px;
        }
        &.bar1 {
          &:before {
            background-color: $color-deepblue-100;
          }
          &:after {
            color: $color-deepblue-100;
          }
        }
        &.bar2 {
          &:before {
            background-color: $color-ocean;
          }
          &:after {
            color: $color-ocean;
          }
        }
        @for $s from 0 through 500 {
          &[data-score="#{$s}"] {
            //&:before {
            width: $s / 5 * 1%;
            //}
            $m: $s / 100;
            &:after {
              content: "#{$m}";
              //left: $s / 5 * 1%;
              @if $s > 100 {
                transform: translateX(-100%);
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.chart {
  &-bars {
    @each $color, $hexaColor, $completHexaColor in $listColorESG {
      &[data-sdg="#{$color}"] {
        .chart__head {
          background-color: $hexaColor;
          .label {
            color: $completHexaColor;
          }
        }
      }
    }
  }
}
