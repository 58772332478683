@import "../../../../../assets/scss/_mandatory";

.user-settings-spv-manager {
  .list {
    color: $color-deepblue-100;
    .row {
      width: 100%;
      min-height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 15px;

      div + div {
        margin-left: 10px;
        @media screen and (min-width: $bp-tablet) {
          margin-left: 15px;
        }
      }

      .project {
        width: calc(100% - 140px - 30px - 30px);
        @media screen and (min-width: $bp-tablet) {
          max-width: none;
        }
      }
      .group {
        width: 140px;
      }
      .delete {
        width: 30px;
        text-align: center;
      }

      &:not(.filters) {
        background-color: $white;
        border-radius: 0px 25px 25px 0px;

        .project {
          font-weight: bold;
          font-size: 12px;
        }
        .delete {
          button {
            color: $color-alert;
          }
        }

        & + .row {
          margin-top: 2px;
        }
      }

      &.filters {
        font-family: $font;
        .label {
          display: block;
          font-size: 12px;
          font-style: italic;
          margin-bottom: 5px;
        }
      }

      &.warn {
        background-color: rgba($color-alert, 0.25);
      }
      &.is-modified {
        background-color: $color-citron;
      }
    }
  }
}
