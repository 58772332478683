@import "../../../../../assets/scss/_mandatory";

$varPI: 3.14159265358979;

$coeffSmall: 0.75;

$chartRadialW: 360;
$chartRadialH: 360;
$radiusCircle: 210;
$chartRadialMissingPart: 50;
$fontSizeLegend: 10;
$fontSizeSDGTitle: 16;

$chartRadialW_print: 480;
$chartRadialH_print: 480;
$radiusCircle_print: 210;
$chartRadialMissingPart_print: 80;
$fontSizeLegend_print: 11;
$fontSizeSDGTitle_print: 24;

$chartRadialW_print_extend: 360;
$chartRadialH_print_extend: 360;
$radiusCircle_print_extend: 210;
$chartRadialMissingPart_print_extend: 60;
$fontSizeLegend_print_extend: 11;
$fontSizeSDGTitle_print_extend: 24;

$chartRadialNbIndicator: 17;

$chartRadialGraduation: 6;
$scoreMax: 5;

.cmp {
  &.chart-radial {
    @media print {
      margin-top: 40px;
      margin-bottom: 120px;
    }
  }
}

//@media screen {
.container-charts {
  position: relative;
  min-height: 350px;
  @media screen and (min-width: $bp-tablet) {
    min-height: 430px;
  }

  @media print {
    min-height: $chartRadialH_print + 80 * 1 * 1px;
    &.wrapp-pdf-extend {
      min-height: $chartRadialH_print + 200 * 1 * 1px;
    }
  }

  .cmp {
    &.chart-radial {
      position: absolute;
      //top:0;
      left: 50%;
      transform: translateX(-50%);

      &.pdf-extend {
        @media print {
          top: 0%;
          //transform: translateX(-50%) translateY(-50%);
        }
      }
    }
    &.chart-bar {
      @include transitionElt(all, 1s, ease);
    }
  }
  &.mode-radar {
    .cmp {
      &.chart-radial {
        opacity: 1;
        z-index: 20;
        @media screen and (min-width: $bp-desk-large) {
          margin-bottom: 60px;
        }
        @media screen and (min-width: $bp-desk-xlarge) {
          margin-bottom: 100px;
        }
      }
      &.chart-bar {
        opacity: 0;
        transform: translateY($chartRadialH * $coeffSmall * 1px);
        @media screen and (min-width: $bp-tablet) {
          transform: translateY($chartRadialH * 1 * 1px);
        }
      }
    }
  }
  &:not(.mode-radar) {
    .cmp {
      &.chart-radial {
        opacity: 0;
        //height: 0;
        margin: 15px auto;
        pointer-events: none;
        .inner-chart {
          // opacity: 0;
        }
      }
      &.chart-bar {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
//}

.chart-radial {
  @media screen {
    width: $chartRadialW * $coeffSmall * 1px;
    height: $chartRadialH * $coeffSmall * 1px;
    margin: 34px auto;
    @include transitionElt(all, 0.5s, ease);
    @media screen and (min-width: $bp-tablet) {
      width: $chartRadialW * 1 * 1px;
      height: $chartRadialH * 1 * 1px;
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  &:before {
    width: 100%;
    height: 1px;
  }
  &:after {
    width: 1px;
    height: 100%;
  }
  .inner-chart {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: $chartRadialH * $coeffSmall * 1px;
    @include border-radius(50%);
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    @include transitionElt(opacity, 0.25s 0.25s, ease);
    @media screen and (min-width: $bp-tablet) {
      min-height: $chartRadialH * 1 * 1px;
    }
  }
  .graduations {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    width: $chartRadialW * $coeffSmall * 1px;
    height: $chartRadialH * $coeffSmall * 1px;
    transform: translateX(-50%) translateY(-25%);
    @media screen and (min-width: $bp-tablet) {
      width: $chartRadialW * 1 * 1px;
      height: $chartRadialH * 1 * 1px;
    }
    span {
      display: block;
      @include border-radius(50%);
      border-top: 1px dotted $color-ocean;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5;
      transform: translateX(-50%) translateY(-50%);
      pointer-events: none;
    }
  }
  .bars {
    position: relative;
    width: 100%;
    height: 100%;
    @include border-radius(50%);
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .legends-extension {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    .inner-legends-extension {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      &:not(:empty) {
        &:before {
          content: "";
          height: 100px;
          width: 2px;
          position: absolute;
          left: 50%;
          bottom: 100%;
          transform: translateY(-18px) translateX(-1px);
        }
      }
    }
    .icon {
      width: 70px;
      height: 70px;
      // @include border-radius(50%);
      // overflow: hidden;
      margin: auto;
      transform: translateY(-50%) translateX(-50%);
      img {
        display: block;
        width: 100%;
      }
    }

    .scores {
      width: 120px;
      height: 40px;
      padding: 0 5px;
      transform: translateX(-50%) translateY(-36px);
      font-weight: 700;
      font-size: 11px;
      line-height: 1.1;
      color: $color-deepblue-100;
      display: flex;
      align-items: flex-end;
      flex-flow: wrap;
      justify-content: center;
      .label {
        width: 100%;
        text-align: center;
        display: block;
        font-family: $font-title-wf;
        font-size: 10px;
      }
      .inner-scores {
        position: relative;
        display: inline-flex;
        flex-flow: wrap;
        justify-content: center;
        width: auto;
        padding: 2px 10px;
        margin-top: 0;
        margin-bottom: auto;
        background-color: $color-seaLight;
        border-radius: 20px;
        .score2 {
          .value {
            color: $color-ocean;
          }
        }
        .score {
          font-family: $font-title-wf;
          .values {
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            align-items: center;
          }
          .value {
            & + .score-goal {
              display: inline-block;
              // margin-left: 4px;
              // transform: translateY(0px);
            }
          }
        }
        .score-goal {
          position: absolute;
          left: 100%;
          top: 50%;
          transform: translateX(3px) translateY(-50%);
          color: $color-deepblue-100;
          background-color: rgba($color-success, 0.5);
          border-color: rgba($color-success, 0.5);
          min-height: 16px;
          line-height: 16px;
          @include border-radius(15px);
          padding: 0 10px;
          font-weight: 400;
        }
      }
      &.with-comparaison {
        .score {
          width: auto;
          &.spacer {
            padding: 0 5px;
          }
          &.score1 {
            text-align: right;
          }
          &.score2 {
            text-align: left;
          }
        }
      }
    }
    .legend-extend {
      //height: 50%;
      width: 56px;
      height: 0;
      //height: 66px;
      //position: absolute;
      top: -125px;
      left: 50%;
      position: absolute;
      //width: 100%;
      //width: $chartRadialW * $varPI  / $chartRadialNbIndicator * 1px ;
      //margin: 0 auto;
      transform-origin: 0 0;
      //transform: translateX(-50%);
      //margin-left: - $chartRadialW * $varPI  / $chartRadialNbIndicator * 0.5 * 1px;
    }
  }
  &.with-comparaison {
    .inner-circle {
    }
    .circle {
      width: 50%;
      overflow: hidden;
    }
    .circle1 {
      left: 0;
      //        clip-path: polygon(0 0, 100% 100%, 100% 0);
      clip-path: polygon(100% 100%, 0 1%, 100% 0);

      .inner-circle {
        //   left: 0%;
      }
      .graduations {
        @media screen {
          margin-left: ($chartRadialW * $coeffSmall) * $varPI /
            $chartRadialNbIndicator * 0.25 * 1px;
        }
        @media screen and (min-width: $bp-tablet) {
          margin-left: $chartRadialW * $varPI / $chartRadialNbIndicator * 0.25 *
            1px;
        }
        @media print {
          margin-left: $chartRadialW_print * $varPI / $chartRadialNbIndicator *
            0.25 * 1px;
        }
      }
    }
    .circle2 {
      left: 50%;
      //            clip-path: polygon(0 0, 0% 100%, 100% 0);
      clip-path: polygon(0 100%, 0 0, 100% 1%);
      .inner-circle {
        //  left: 50%;
      }
      .graduations {
        @media screen {
          margin-left: (-$chartRadialW * $coeffSmall) * $varPI /
            $chartRadialNbIndicator * 0.25 * 1px;
        }
        @media screen and (min-width: $bp-tablet) {
          margin-left: -$chartRadialW * $varPI / $chartRadialNbIndicator * 0.25 *
            1px;
        }
        @media print {
          margin-left: -$chartRadialW_print * $varPI / $chartRadialNbIndicator *
            0.25 * 1px;
        }
      }
    }
  }
  .overbar .arc-sdg,
  .bar,
  .goal {
    height: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    width: ($chartRadialW * $coeffSmall) * $varPI / $chartRadialNbIndicator *
      1px;
    margin-left: (-$chartRadialW * $coeffSmall) * $varPI /
      $chartRadialNbIndicator * 0.5 * 1px;
    transform-origin: 50% 100%;
    transform: translateY(-50%) translateX(-50%);
    @media screen and (min-width: $bp-tablet) {
      width: $chartRadialW * $varPI / $chartRadialNbIndicator * 1px;
      margin-left: -$chartRadialW * $varPI / $chartRadialNbIndicator * 0.5 * 1px;
    }
  }

  .goal,
  .bar {
    clip-path: polygon(50% 100%, 0% 0%, 100% 0);
    overflow: hidden;
    &[data-value="nodata"] {
      pointer-events: none;
    }
  }
  .goal {
    //z-index: 5;
    pointer-events: none;
    .circle:not([data-score=""]) {
      &:before {
        content: initial;
      }
    }
  }
  .overbar {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    &.overbar1 {
      pointer-events: none;
      z-index: 100;
      //height: 100%;
      //margin-top: -18px;
      //margin-top: -18px;
    }
    &.overbar2 {
      pointer-events: none;
      height: calc(100% + 36px);
      width: calc(100% + 36px);
    }
    &.overbar3 {
      pointer-events: none;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      height: ($chartRadialMissingPart * $coeffSmall) * 2 * 1px;
      width: ($chartRadialMissingPart * $coeffSmall) * 2 * 1px;
      z-index: 1500;
      background-color: $color-seaLight;
      @include border-radius(50%);
      transform: translateX(-50%) translateY(-50%);
      @media print {
        background-color: white;
      }
      @media screen and (min-width: $bp-tablet) {
        height: $chartRadialMissingPart * 2 * 1px;
        width: $chartRadialMissingPart * 2 * 1px;
      }
      &:after {
        content: "SDG";
        margin: auto;
        font-family: $font-title-wf;
        font-size: $fontSizeSDGTitle * 1px;
        font-weight: 900;
        z-index: 1000;
        color: $color-deepblue-100;
      }
    }
    .legend-sdg {
      display: flex;
      flex-flow: wrap;
      position: absolute;
      width: 100%;
      height: 100%;
      &:before {
        position: absolute;
        z-index: 150;
        top: 0%;
        margin-top: 5px;
        line-height: 1;
        left: 0;
        width: 100%;
        display: block;
        text-align: center;
        font-size: $fontSizeLegend * 1px;
        font-weight: 700;
        color: $color-ocean;
        font-family: $font-title-wf;
      }
      &:not([data-score=""]) {
        &:before {
          color: $color-deepblue-100;
        }
      }
    }
    .arc-sdg {
      display: block;
      //border-top: 6px solid;
      clip-path: polygon(-3% 0%, 50% 100%, 103% 0%);
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        top: 0%;
        left: 50%;
        z-index: -1;
        width: ($chartRadialW + 40) * $coeffSmall * 1px;
        height: ($chartRadialH + 40) * $coeffSmall * 1px;
        @include border-radius(50%);
        transform: translateX(-50%) translateY(0%);
        @media screen and (min-width: $bp-tablet) {
          width: ($chartRadialW + 40) * 1 * 1px;
          height: ($chartRadialH + 40) * 1 * 1px;
        }
      }
    }
    .border-sdg {
      height: ($chartRadialW + 36) / 2 * $coeffSmall * 1px;
      position: absolute;
      z-index: 200;
      top: 0;
      left: 50%;
      transform-origin: 50% 100%;
      transform: translateY(-50%) translateX(-50%);
      margin-left: -1.5px;
      z-index: 100;
      width: 3px;
      background-color: $color-seaLight;
      pointer-events: none;
      @media screen and (min-width: $bp-tablet) {
        height: ($chartRadialW + 36) / 2 * 1 * 1px;
      }
      @media print {
        background-color: white;
      }
    }
  }

  .inner-bar {
    position: relative;
    width: 100%;
    height: 100%;
    //overflow: hidden;
  }
  .circle {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 9;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    //display: none;
    overflow: hidden;
    svg {
      position: relative;
      z-index: 10;
      width: $chartRadialW * $coeffSmall * 1px;
      height: $chartRadialH * $coeffSmall * 1px;
      transform: translateX(-50%) translateY(0%);
      @media screen and (min-width: $bp-tablet) {
        width: $chartRadialW * 1 * 1px;
        height: $chartRadialH * 1 * 1px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0%;
      left: 50%;
      width: $chartRadialW * $coeffSmall * 1px;
      height: $chartRadialW * $coeffSmall * 1px;
      @include border-radius(50%);
      transform: translateX(-50%) translateY(0%);
      pointer-events: none;
      @media screen and (min-width: $bp-tablet) {
        width: $chartRadialW * 1 * 1px;
        height: $chartRadialW * 1 * 1px;
      }
    }

    &.circle1 {
      .inner-circle {
        //background-color: green;
      }
      svg {
        margin-left: ($chartRadialW * $coeffSmall) * $varPI /
          $chartRadialNbIndicator * 0.5 * 1px;
        @media screen and (min-width: $bp-tablet) {
          margin-left: $chartRadialW * $varPI / $chartRadialNbIndicator * 0.5 *
            1px;
        }
      }
      circle {
        fill: $color-deepblue-100;
        &.circle-goal {
          fill: $color-success;
          fill-opacity: 0.5;
        }
      }
    }
    &.circle2 {
      .inner-circle {
        //background-color: orange;
      }
      circle {
        fill: $color-ocean;
      }
    }
    .inner-circle {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
    &:not([data-score=""]) {
      &:before {
        background-color: white;
      }
      .inner-circle {
        circle {
          cursor: pointer;
        }
      }
    }
    &[data-score=""] {
      &:before {
      }
    }
    .inner-graduations {
      position: relative;
      width: 100%;
      height: 100%;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      overflow: hidden;
    }
  }

  $listIndicatorsNorth: 1, 2, 3, 4, 14, 15, 16, 17;
  @each $elt in $listIndicatorsNorth {
    .legend-extend#{$elt} {
      .scores {
        transform: translateX(-50%) translateY(-150px);
        .inner-scores {
          margin-bottom: 0;
        }
      }
    }
  }

  @for $i from 1 through $chartRadialNbIndicator {
    //.overbar span:nth-child(#{$i}),
    .goal#{$i},
    .bar#{$i} {
      @include rotateElt(360 / $chartRadialNbIndicator * ($i - 0.5) * 1deg);
      //transform: translateY(-50%) translateX(-50%) rotate(360/$chartRadialNbIndicator * ( $i - 0.5 ) * 1deg)
    }
    .overbar .legend-sdg#{$i},
    .overbar .arc-sdg#{$i} {
      @include rotateElt(360 / $chartRadialNbIndicator * ($i - 0.5) * 1deg);
    }
    .overbar .border-sdg#{$i} {
      //@include rotateElt(360/$chartRadialNbIndicator * $i * 1deg);
      transform: translateX(-50%)
        translateY(-100%)
        rotate(360 / $chartRadialNbIndicator * $i * 1deg);
    }
    .overbar .legend-sdg#{$i} {
      &:before {
        content: "#{$i}";
      }
    }
    @if $chartRadialNbIndicator < $i and $i > 5 {
      .overbar .legend-sdg {
        &:before {
          @include rotateElt(180deg);
        }
      }
    }

    .inner-legends-extension {
      &:nth-child(#{$i}) {
        @include rotateElt(360 / $chartRadialNbIndicator * ($i - 0.5) * 1deg);
      }
    }
    .legend-extend#{$i} {
      transform: rotate(-(360 / $chartRadialNbIndicator * ($i - 0.5)) * 1deg)
        translateY(0%)
        translateX(0%);
    }
  }

  @media print {
    margin: auto;

    &.pdf-extend {
      margin: 150px auto auto auto;
      width: $chartRadialW_print_extend * 1 * 1px;
      height: $chartRadialH_print_extend * 1 * 1px;
      .graduations {
        width: $chartRadialW_print_extend * 1 * 1px;
        height: $chartRadialH_print_extend * 1 * 1px;
      }
      .bars {
        overflow: hidden;
      }
      .goal,
      .bar {
        width: $chartRadialW_print_extend * $varPI / $chartRadialNbIndicator *
          1px;
        margin-left: -$chartRadialW_print_extend * $varPI /
          $chartRadialNbIndicator * 0.5 * 1px;
      }
      .overbar .arc-sdg,
      .goal,
      .bar {
        width: $chartRadialW_print_extend * $varPI / $chartRadialNbIndicator *
          1px;
        margin-left: -$chartRadialW_print_extend * $varPI /
          $chartRadialNbIndicator * 0.5 * 1px;
      }
      .overbar {
        &.overbar3 {
          height: $chartRadialMissingPart_print_extend * 2 * 1px;
          width: $chartRadialMissingPart_print_extend * 2 * 1px;
          &:after {
            font-size: $fontSizeSDGTitle_print_extend * 1px;
          }
        }
        .legend-sdg {
          &:before {
            font-size: $fontSizeLegend_print_extend * 1px;
          }
        }
        .arc-sdg {
          &:after {
            width: $chartRadialW_print_extend + 40 * 1 * 1px;
            height: $chartRadialH_print_extend + 40 * 1 * 1px;
          }
        }
        .border-sdg {
          height: ($chartRadialW_print_extend + 36) / 2 * 1px;
        }
      }
      .circle {
        svg {
          width: $chartRadialW_print_extend * 1 * 1px;
          height: $chartRadialH_print_extend * 1 * 1px;
        }
        &:before {
          width: $chartRadialW_print_extend * 1 * 1px;
          height: $chartRadialH_print_extend * 1 * 1px;
        }
        &.circle1 {
          svg {
            margin-left: $chartRadialW_print_extend * $varPI /
              $chartRadialNbIndicator * 0.5 * 1px;
          }
        }
        &[data-score=""] {
          &:before {
            background-color: $color-seaLight;
          }
        }
      }
      .chart-foot {
        margin-top: 320px;
      }
    }
    &:not(.pdf-extend) {
      width: $chartRadialW_print * 1 * 1px;
      height: $chartRadialH_print * 1 * 1px;
      .graduations {
        width: $chartRadialW_print * 1 * 1px;
        height: $chartRadialH_print * 1 * 1px;
      }
      .bars {
        overflow: hidden;
      }
      .goal,
      .bar {
        width: $chartRadialW_print * $varPI / $chartRadialNbIndicator * 1px;
        margin-left: -$chartRadialW_print * $varPI / $chartRadialNbIndicator *
          0.5 * 1px;
      }
      .overbar .arc-sdg,
      .goal .bar {
        width: $chartRadialW_print * $varPI / $chartRadialNbIndicator * 1px;
        margin-left: -$chartRadialW_print * $varPI / $chartRadialNbIndicator *
          0.5 * 1px;
      }
      .overbar {
        &.overbar3 {
          height: $chartRadialMissingPart_print * 2 * 1px;
          width: $chartRadialMissingPart_print * 2 * 1px;
          &:after {
            font-size: $fontSizeSDGTitle_print * 1px;
          }
        }
        .legend-sdg {
          &:before {
            font-size: $fontSizeLegend_print * 1px;
          }
        }
        .arc-sdg {
          &:after {
            width: $chartRadialW_print + 40 * 1 * 1px;
            height: $chartRadialH_print + 40 * 1 * 1px;
          }
        }
        .border-sdg {
          height: ($chartRadialW_print + 36) / 2 * 1px;
        }
      }
      .circle {
        svg {
          width: $chartRadialW_print * 1 * 1px;
          height: $chartRadialH_print * 1 * 1px;
        }
        &:before {
          width: $chartRadialW_print * 1 * 1px;
          height: $chartRadialH_print * 1 * 1px;
        }
        &.circle1 {
          svg {
            margin-left: $chartRadialW_print * $varPI / $chartRadialNbIndicator *
              0.5 * 1px;
          }
        }
        &[data-score=""] {
          &:before {
            background-color: $color-seaLight;
          }
        }
      }

      .chart-foot {
        margin-top: 100px;
      }
    }
  }
}

$opacityHoverRadar: 0.1;

.chart-radial {
  @for $sdg from 1 through $chartRadialNbIndicator {
    &[data-sdg-hovered="#{$sdg}"] {
      .inner-chart {
        &:hover {
          .bar {
            &.bar#{$sdg} {
              opacity: 1;
            }
            &:not(.bar#{$sdg}) {
              opacity: $opacityHoverRadar;
            }
          }
          .goal {
            &.goal#{$sdg} {
              opacity: 1;
            }
            &:not(.goal#{$sdg}) {
              opacity: $opacityHoverRadar;
            }
          }

          .arc-sdg {
            &:not(.arc-sdg#{$sdg}) {
              opacity: $opacityHoverRadar;
            }
            &.arc-sdg#{$sdg} {
              opacity: 1;
            }
          }
          .legend-sdg {
            &:not(.legend-sdg#{$sdg}) {
              opacity: $opacityHoverRadar;
            }
            &.legend-sdg#{$sdg} {
              opacity: 1;
            }
          }
          .overbar {
            .arc-sdg {
              &.arc-sdg#{$sdg} {
                &:after {
                  border-width: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@for $grad from 0 through $chartRadialGraduation {
  .graduations {
    span {
      &:nth-child(#{$grad}) {
        @media screen {
          width: (
              (
                  (
                      (
                          ($chartRadialW * $coeffSmall) -
                            ($chartRadialMissingPart * 2)
                        ) / ($chartRadialGraduation - 1)
                    ) * $grad
                ) + ($chartRadialMissingPart * 2)
            ) * 1px;
          height: (
              (
                  (
                      (
                          ($chartRadialH * $coeffSmall) -
                            ($chartRadialMissingPart * 2)
                        ) / ($chartRadialGraduation - 1)
                    ) * $grad
                ) + ($chartRadialMissingPart * 2)
            ) * 1px;
        }

        @media screen and (min-width: $bp-tablet) {
          width: (
              (
                  (
                      ($chartRadialW - ($chartRadialMissingPart * 2)) /
                        ($chartRadialGraduation - 1)
                    ) * $grad
                ) + ($chartRadialMissingPart * 2)
            ) * 1px;
          height: (
              (
                  (
                      ($chartRadialH - ($chartRadialMissingPart * 2)) /
                        ($chartRadialGraduation - 1)
                    ) * $grad
                ) + ($chartRadialMissingPart * 2)
            ) * 1px;
        }
        // @media print {
        //   width: (
        //       (
        //           (
        //               (
        //                   $chartRadialW_print -
        //                     ($chartRadialMissingPart_print * 2)
        //                 ) / ($chartRadialGraduation - 1)
        //             ) * $grad
        //         ) + ($chartRadialMissingPart_print * 2)
        //     ) * 1px;
        //   height: (
        //       (
        //           (
        //               (
        //                   $chartRadialH_print -
        //                     ($chartRadialMissingPart_print * 2)
        //                 ) / ($chartRadialGraduation - 1)
        //             ) * $grad
        //         ) + ($chartRadialMissingPart_print * 2)
        //     ) * 1px;
        // }
      }
    }
  }
  .chart-radial {
    @media print {
      &.pdf-extend {
        .graduations {
          span {
            &:nth-child(#{$grad}) {
              width: (
                  (
                      (
                          (
                              $chartRadialW_print_extend -
                                ($chartRadialMissingPart_print_extend * 2)
                            ) / ($chartRadialGraduation - 1)
                        ) * $grad
                    ) + ($chartRadialMissingPart_print_extend * 2)
                ) * 1px;
              height: (
                  (
                      (
                          (
                              $chartRadialH_print_extend -
                                ($chartRadialMissingPart_print_extend * 2)
                            ) / ($chartRadialGraduation - 1)
                        ) * $grad
                    ) + ($chartRadialMissingPart_print_extend * 2)
                ) * 1px;
            }
          }
        }
      }
      &:not(.pdf-extend) {
        .graduations {
          span {
            &:nth-child(#{$grad}) {
              width: (
                  (
                      (
                          (
                              $chartRadialW_print -
                                ($chartRadialMissingPart_print * 2)
                            ) / ($chartRadialGraduation - 1)
                        ) * $grad
                    ) + ($chartRadialMissingPart_print * 2)
                ) * 1px;
              height: (
                  (
                      (
                          (
                              $chartRadialH_print -
                                ($chartRadialMissingPart_print * 2)
                            ) / ($chartRadialGraduation - 1)
                        ) * $grad
                    ) + ($chartRadialMissingPart_print * 2)
                ) * 1px;
            }
          }
        }
      }
    }
  }
}

.chart-radial {
  @for $k from 0 through 500 {
    .circle {
      &[data-score="#{$k}"] {
        circle {
          @media screen {
            r: (
                (($radiusCircle - ($chartRadialMissingPart)) / $scoreMax) *
                  $k/100
              ) + $chartRadialMissingPart * 1px;
          }
        }
      }
    }
    @media print {
      &:not(.pdf-extend) {
        .circle {
          &[data-score="#{$k}"] {
            circle {
              r: (
                  ($radiusCircle_print - $chartRadialMissingPart_print) /
                    $scoreMax * $k/100
                ) + $chartRadialMissingPart_print * 1px;
            }
          }
        }
      }
      &.pdf-extend {
        .circle {
          &[data-score="#{$k}"] {
            circle {
              r: (
                  (
                      $radiusCircle_print_extend -
                        $chartRadialMissingPart_print_extend
                    ) / $scoreMax * $k/100
                ) + $chartRadialMissingPart_print_extend * 1px;
            }
          }
        }
      }
    }
  }
}

.overbar {
  .arc-sdg {
    &:after {
      border-top: solid 8px;
      @include transitionElt(all, 0.3, ease);
    }
  }
  @each $color, $hexaColor in $listColorESG {
    .arc-sdg#{$color} {
      &:after {
        border-color: $hexaColor;
      }
    }
  }
}

@each $color, $hexaColor in $listColorESG {
  .inner-legends-extension {
    &:nth-child(#{$color}) {
      &:before {
        background-color: $hexaColor;
      }
    }
  }
}

.chart-foot {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    background-color: white;
    width: 50px;
    height: 50px;
    @include border-radius(50%);
    transform: translateY(-50%) translateX(50%);
  }
  .chart-title {
    position: relative;
    width: 100%;
    &:before {
      //content: "";
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      width: 100%;
      transform: translateY(-50%);
      background-color: $color-deepblue-100;
    }
    &:after {
      //content: "";
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      width: 100px;
      transform: translateY(-50%);
      background-color: white;
      z-index: 5;
    }
    .metas {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      flex-flow: wrap;
      padding-left: 100px;
    }
    &.with-comparaison {
      &:before,
      &:after {
        content: "";
      }
      .title {
        height: 50%;
        &.title1 {
          padding-bottom: 10px;
          .svg {
            transform: translateY(-100%);
            clip-path: polygon(150% 0, 0 100%, 100% 100%);
          }
        }
        &.title2 {
          padding-top: 10px;
          .svg {
            transform: translateY(0%);
            clip-path: polygon(100% 0, 0 0, 150% 100%);
          }
        }
      }
    }
    &:not(.with-comparaison) {
      .title {
        &.title1 {
          .svg {
            transform: translateY(-50%);
            clip-path: polygon(100% 0, 0 50%, 100% 100%);
          }
        }
      }
    }
    .title {
      display: flex;
      width: 100%;
      align-items: center;
      flex-flow: wrap;
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      text-align: left;
      .svg {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 0;
        top: 50%;
      }
      &.title1 {
        color: $color-deepblue-100;
        .svg {
        }
        svg {
          fill: $color-deepblue-100;
          //  transform: translateY(-50%) translateX(0%);
        }
      }
      &.title2 {
        color: $color-ocean;
        svg {
          fill: $color-ocean;
        }
      }
      span {
        display: block;
        width: 100%;
        margin: auto 0;
      }
    }
  }
}
