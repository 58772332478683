@import "../../../../assets/scss/__mandatory";

.tools {
  width: 100%;
  &_inner {
    max-width: 940px;
    margin: 0 auto;
    padding: 0 0 30px 0;
    @media screen and (min-width: $bp-tablet) {
      padding: 30px 0;
    }
  }

  .no-data-available {
    margin-top: 12px;
    color: $color-deepblue-100;

    &.white {
      color: white;
    }
  }
}
