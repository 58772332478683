@import "../../__mandatory";

.card {
  width: 100%;
  padding: 5px 10px;
  @media screen and (min-width: $bp-tablet) {
    padding: 10px;
  }
  &.no-click {
    cursor: not-allowed;
    &.card-dark {
      .inner-card {
        color: $color-ocean;
        .alert {
          color: white;
        }
      }
    }
  }

  &_inner {
  }

  .category-item {
    height: 100%;
  }
}
