@import "../../../../assets/scss/__mandatory";

.page {
  &-auth {
    .page {
      &_inner {
        height: 100%;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
      }
    }
    .box {
      width: 100%;
      max-width: 480px;
      margin: auto;
      text-align: center;
      &_inner {
        padding: 0;
      }
      .logo {
        padding: 15px;
        margin-bottom: 15px;
      }
      &_inner {
        @include border-radius(5px);
        background-color: white;
        box-shadow: $box-shadow;
        .form {
          width: 100%;
          @import "../../../../assets/scss/custom/form/_base";
        }
      }
      &__foot {
        @include border-radius(0 0 5px 5px);
      }
    }
  }
}
