@import "../../../../assets/scss/__mandatory";

$color-toolbar-lp: #284253;
$color-toolbar-lp-text: #587482;

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.header {
  &-wrapper {
    &.uk-sticky {
      &.uk-active {
        &:not(.open) {
          & + .uk-sticky-placeholder {
            & + .tools {
              .filters {
                &-main {
                  &.uk-sticky {
                    &.uk-active {
                      @include transitionElt(top, 0.8s 0.25s, ease);
                    }
                  }
                }
              }
            }
          }
        }
        &.open {
          & + .uk-sticky-placeholder {
            & + .tools {
              .filters {
                &-main {
                  &.uk-sticky {
                    &.uk-active {
                      @include transitionElt(top, 0.8s, ease);
                    }
                    @media screen and (min-width: $bp-tablet) {
                      &.uk-active {
                        @for $i from 1 through 10 {
                          &[data-item-length="#{$i}"] {
                            top: ($i * $heightElementUserMenu + ($paddingMenuUser * 2) + 60) * 1px !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .uk-offcanvas {
      &.uk-open {
        z-index: 1005;
      }
    }
  }
  & + .uk-sticky-placeholder {
    height: 0 !important;
  }
}

.filters {
  &-main {
    &.uk-sticky {
      &.uk-active {
        nav {
          // display: none;
          transform: translateY(-100%);
          opacity: 0;
        }
        @media screen and (min-width: $bp-tablet) {
          left: 50% !important;
          width: 440px !important;
          height: auto;
          bottom: auto;
          top: 0;
          z-index: 1000;
        }
        .filters_inner {
          .filter {
            max-height: 60px;
            // padding-bottom: 5px;
            .filter_child {
              @media screen and (min-width: $bp-tablet) {
                color: $color-deepblue-100;
                padding: 0;
                justify-content: flex-start;
                align-items: center;
                flex-flow: wrap;
              }
              .filter__label {
                padding-top: 5px;
                min-height: 20px;
              }
            }
            &-reset {
              color: $color-blue-dark;
              font-size: 10px;

              i {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.toolbar {
  position: relative;
  width: 100%;
  //z-index: 20;
  &-secondary {
    padding: 20px 0;
    @media screen and (min-width: $bp-tablet) {
      padding: 0;
    }
  }

  &-filters {
    .toolbar_inner {
      position: relative;
      width: 100%;
      display: flex;
      flex-flow: column-reverse;
      @media screen and (max-width: $bp-tablet) {
        &.uk-sticky.uk-active {
          top: unset !important;
          bottom: 0;
        }
      }
      @media screen and (min-width: $bp-tablet) {
        position: relative !important;
        flex-flow: wrap;
        min-height: 100px;
        align-items: center;
        background-color: inherit;
        &.uk-sticky.uk-active {
          .menu {
            opacity: 0;
            pointer-events: none;
          }
        }
      }

      //Quand un filtre est open
      &.menu-open {
        .filters {
          display: flex !important;
          background-color: $color-cobalt;
          //padding-bottom: 80px;
          @media screen and (min-width: $bp-tablet) {
            background-color: transparent;
          }
          .filters {
            &_inner {
              background-color: $color-cobalt;
              @media screen and (min-width: $bp-tablet) {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    .menu {
      z-index: 10;
      position: relative;
      display: flex;
      flex-flow: wrap;
      width: 160px;
      justify-content: flex-end;
      background-color: $color-toolbar-lp;
      padding: 0 20px;
      // height: 100%;
      min-height: 60px;
      @media screen and (min-width: $bp-tablet) {
        width: 30%;
        padding: 0;
      }
      @media screen and (min-width: $bp-desk-small) {
        width: 440px;
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        display: block;
        background-color: $color-toolbar-lp;
      }
      &:after {
        right: 100%;
        width: calc((100vw - 940px) / 2);
      }
      &:before {
        width: 30px;
        left: 100%;
        @include border-radius(0 30px 30px 0);
      }
      .btn {
        position: relative;
        width: auto;
        margin: auto 0 auto 20px;
        padding-left: 0;
        pointer-events: visible;

        @media screen and (min-width: $bp-tablet) {
          cursor: text;
          pointer-events: none;
        }
        &__label {
          font-size: 24px;
          font-weight: 400;
          text-transform: uppercase;
          color: $color-toolbar-lp-text;
        }
        &__icon {
          position: absolute;
          width: 18px;
          height: 18px;
          top: 50%;
          left: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          transform-origin: 50% 50%;
          transform: translateX(10px) translateY(-50%);
          @media screen and (min-width: $bp-tablet) {
            display: none;
          }

          &:before {
            margin: auto;
            content: "\f0c9";
            font-family: $font-icon;
            font-weight: 400;
            font-size: 15px;
          }
          &.menu-open {
            // &.rotate-center {
            //   -webkit-animation: rotate-center 0.5s ease-in-out both;
            //   animation: rotate-center 0.5s ease-in-out both;
            // }
            &:before {
              content: "\f00d";
              font-family: $font-icon;
              font-weight: 400;
              font-size: 18px;
            }
          }
        }
      }
    }

    .filters {
      display: none;
      position: absolute;
      z-index: 8;
      bottom: 0;
      left: 0;
      width: 100%;
      // height: 100%;
      padding: 20px;
      //padding: 20px 20px 80px 20px;
      @media screen and (min-width: $bp-tablet) {
        position: relative;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        padding: 0 0 0 60px;
        width: 70%;
      }
      @media screen and (min-width: $bp-desk-small) {
        width: calc(100% - 440px);
      }
      .filters_inner {
        //    position: relative;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        //     flex-flow: row wrap;
        flex-flow: wrap;
        justify-items: center;
        align-content: center;
        font-size: 12px;
        //padding: 20px 20px 80px 20px;
        @media screen and (min-width: $bp-tablet) {
          position: relative;
        }

        .filter {
          //position: relative;
          width: 100%;
          padding: 20px 20px 0 20px;
          & + .filter {
            padding-bottom: 80px;
            @media screen and (min-width: $bp-tablet) {
              padding-bottom: 0;
            }
          }

          @media screen and (min-width: $bp-tablet) {
            width: 100 / 2 * 1%;
            height: 80px;
            padding: 0;
          }

          .filter_child {
            width: 100%;
            height: 100%;
            padding: 10px 0;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            align-items: flex-start;

            .filter__label {
              position: relative;
              width: 100%;
              text-align: left;
              font-style: italic;
              min-height: 24px;
              margin-bottom: 10px;
              @media screen and (min-width: $bp-tablet) {
                margin-bottom: 0px;
              }

              &:after {
                content: "";
                position: absolute;
                width: 100%;
                bottom: 0%;
                left: 0;
                height: 1px;
                background-color: $color-ocean;
              }
            }
          }

          &-reset {
            // background-color: red;
            position: absolute;
            bottom: 22px;
            right: 25px;
            font-style: italic;
            color: white;
            height: auto;
            width: calc(100px - 24px);
            padding: 5px 0;
            padding-bottom: 0;
            @media screen and (min-width: $bp-tablet) {
              top: 4px;
              right: 0;
              bottom: auto;
            }
            span {
              display: flex;
              flex-flow: revert;
              align-items: center;
              justify-content: center;
              i {
                position: relative;
                margin-left: 5px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 8px;
                &:before {
                  position: absolute;
                  top: 0;
                  left: 50%;
                  width: 12px;
                  height: 12px;
                  background-color: $color-red;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  transform: translate(0, -50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
