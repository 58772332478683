@import "../../../../../assets/scss/_mandatory";

.user {
  &-settings {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .tabs {
      width: 30%;
      margin-top: 10px;
      &-container {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        margin-top: 30px;
      }
      &-item {
        width: 100%;
        height: 50px;
        text-align: left;
        border-radius: 25px 0px 0px 25px;
        padding: 10px 20px 10px 30px;
        i {
          margin-right: 10px;
          &.fa-lock {
            color: #829395;
          }
          &.fa-lock-open {
            color: $color-successBright;
          }
        }
        span {
          color: #829395;
          font-family: $font-title-wf;
          font-weight: $ft-black;
          font-size: 16px;
          text-transform: uppercase;
        }
        &.current {
          background-color: $color-seaLight;
          span {
            color: $color-deepblue-100;
          }
        }
      }
    }

    .content {
      width: 70%;
      background-color: $color-seaLight;
      padding: 24px;

      h3 {
        font-size: 16px;
        text-transform: uppercase;
        color: $color-deepblue-100;
        margin-bottom: 15px;
      }

      .btn {
        text-align: left;
        padding: 0 10px;
        &-label {
          margin: initial;
        }
        &-icon {
          margin-left: 10px;
        }
        &-send {
          max-width: 300px;
          width: 100%;
          margin-bottom: 10px;
        }
      }

      .title-with-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h3 {
          margin-bottom: 0px !important;
        }
      }

      .toggle-container {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .toggle {
          margin-right: 10px;
          &-switcher {
            background-color: #829395;
            border: 2px solid #829395;
            &::before {
              background-color: white;
            }
          }
          &-input {
            &:checked {
              & + .toggle-switcher {
                background-color: $color-successBright;
                border-color: $color-successBright;
                &::before {
                  background-color: white;
                }
              }
            }
          }
          &.both {
            margin: 0 10px;
          }
        }
        .label {
          font-family: $font;
          font-size: 14px;
          font-weight: $ft-bold;
          color: $color-deepblue-100;
        }
      }

      .state {
        font-size: 12px;
        font-family: $font;
        i {
          margin-right: 5px;
        }
        &.warn {
          color: $color-alert;
        }
        &.valid {
          color: $color-deepblue-100;
          i {
            color: $color-successBright;
          }
        }
      }

      .separator {
        margin: 20px 0;
      }
    }
  }
}
