@import "../../../../assets/scss/__mandatory";

.list {
  .row {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    div + div {
      margin-left: 10px;
      @media screen and (min-width: $bp-tablet) {
        margin-left: 15px;
      }
    }

    .project,
    .project-indicators,
    .project-documents,
    .project-downloads,
    .report {
      width: 100%;
      min-height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .project {
      width: calc(100% - 90px - 190px - 60px - 60px - 60px);
    }
    .project-indicators {
      max-width: 90px;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-end;
    }
    .project-documents {
      max-width: 190px;
      width: 100%;
      padding-left: 12px;
    }
    .project-downloads,
    .report {
      max-width: 60px;
      width: 100%;
      padding-left: 12px;
    }

    &:not(.filters) {
      border: 1px solid $color-seaLight;
      background-color: $color-seaLight;
      border-radius: 0px 25px 25px 0px;
      color: $color-deepblue-100;

      .fa-check-circle {
        color: $color-successBright;
      }
      .fa-exclamation-circle {
        color: $color-alert;
      }

      .project {
        font-family: $font;
        font-weight: $ft-bold;
        font-size: 12px;
      }
      .project-indicators,
      .project-documents {
        font-size: 16px;
        font-weight: $ft-black;
      }
      .project-indicators {
        color: $color-deepblue-100;
      }
      .project-documents {
        color: $color-ocean;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .completion {
          &-container {
            width: 100px;
            height: 8px;
            background-color: $white;
          }
          &-bar {
            display: block;
            height: 8px;
            background-color: $color-ocean;
          }
        }
        span:not(.completion-bar) {
          margin-left: 10px;
        }
      }

      .project-documents,
      .project-downloads {
        border-left: 1px solid $color-sea;
      }
      .project-downloads,
      .report {
        display: flex;
        justify-content: center;
      }

      &.warn {
        background-color: rgba($color-alert, 0.25);
        .project-documents {
          color: $color-alert;
          border-left-color: $color-alert;
        }
        .project-downloads {
          border-left-color: $color-alert;
        }
      }

      &.valid {
        background-color: rgba($color-successBright, 0.25);
        .project-documents {
          .completion-bar {
            background-color: $color-successBright;
          }
        }
      }
    }

    &.filters {
      .label {
        font-size: 12px;
        margin-bottom: 5px;
      }
      .project,
      .project-documents {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
      }
      .project-downloads,
      .report {
        text-align: center;
      }
      .btn-filter {
        min-height: 20px;
        background-color: $color-cobalt;
        border: 2px solid $color-cobalt;
        border-radius: 15px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        font-weight: $ft-bold;
        font-size: 12px;
        .btn_icon {
          margin-left: 5px;
        }
        &.btn-invert {
          background-color: $color-softGrey;
          border-color: $color-softGrey;
          color: $color-cobalt;
        }
      }
    }

    & + .row {
      margin-top: 2px;
    }

    &.not-all {
      .project {
        width: calc(100% - 90px - 130px - 60px - 60px - 60px);
      }
      .project-documents {
        max-width: 130px;
        color: $color-deepblue-100;
      }
    }
  }

  .no-result {
    background-color: rgba($color-alert, 0.25);
    border-radius: 25px;
    width: 100%;
    min-height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
