@import "../../../../assets/scss/__mandatory";

// fix send invitation btn
// .blackColor {
//   color: black !important;
// }

.page-user {
  .table {
    max-width: 960px;
    width: 100%;
    margin: auto;
    background-color: $white;

    .btn-primary {
      padding: 0 10px;
      font-size: 14px;
      font-family: $font;
      .btn-icon {
        margin-left: 10px;
      }
    }

    &_header {
      .head {
        padding: 30px 20px 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          color: $color-deepblue-100;
          font-size: 20px;
          font-weight: $ft-black;
          text-transform: uppercase;
        }
        .actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .add {
            margin-left: 10px;
          }
        }
      }

      .exports {
        padding: 20px 20px 15px 20px;
        background-color: $color-softGrey;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        &-item {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: flex-start;
          .label {
            font-family: $font;
            font-weight: $ft-bold;
            font-size: 12px;
            color: $color-deepblue-100;
            margin-bottom: 5px;
          }
          & + .exports-item {
            margin-left: 10px;
          }
        }
      }
    }

    &_content {
      background-color: white;
      color: $color-deepblue-100;
      padding: 20px;
      margin: 0 auto;
      width: 100%;
      .list {
        color: $color-deepblue-100;
        .row {
          width: 100%;
          min-height: 50px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 15px;

          div + div {
            margin-left: 10px;
            @media screen and (min-width: $bp-tablet) {
              margin-left: 15px;
            }
          }

          .name {
            width: calc(100% - 160px - 120px - 100px - 50px - 60px);
            // @media screen and (min-width: $bp-tablet) {
            //   max-width: none;
            // }
          }
          .role {
            width: 160px;
            .selector {
              margin-bottom: 0;
              .btn_label {
                text-transform: capitalize;
              }
            }
          }
          .modules {
            width: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .account-creation {
            width: 100px;
          }
          .status {
            width: 50px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
          }

          &:not(.filters) {
            background-color: $color-seaLight;
            border-radius: 0px 25px 25px 0px;
            .name {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              .avatar {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  font-family: $font;
                  font-weight: $ft-bold;
                  font-size: 12px;
                  text-transform: uppercase;
                  color: $white;
                }
              }
              .user-infos {
                &_name {
                  font-weight: $ft-bold;
                  font-size: 14px;
                }
                &_login {
                  font-family: $font;
                  font-size: 12px;
                  margin-top: 3px;
                }
              }
            }
            .role,
            .account-creation {
              font-family: $font;
              font-size: 12px;
            }
            .modules {
              position: relative;
              .tooltip {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, calc(-100% - 10px));
                z-index: 10;
                padding: 12px;
                width: 150px;
                background: $color-ocean;
                border-radius: 6px;
                box-shadow: 0px 2px 2px rgba(14, 55, 64, 0.5),
                  0px 13px 40px rgba(14, 55, 64, 0.5);
                &:after {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  transform: translate(-50%, 100%);
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 18px 18px 0 18px;
                  border-color: $color-ocean transparent transparent transparent;
                }
                ul {
                  li {
                    font-family: $font;
                    font-weight: $ft-bold;
                    font-size: 12px;
                    color: $white;
                    text-align: center;
                    & + li {
                      margin-top: 5px;
                    }
                  }
                }
                &[aria-hidden="false"] {
                  & + .btn {
                    border-color: $color-deepblue-100;
                    background-color: $color-deepblue-100;
                    color: $white;
                  }
                }
              }
              .btn {
                width: 100%;
                border-color: $color-sea;
                background-color: $color-sea;
                color: $color-deepblue-100;
                &.disabled {
                  border-color: $white;
                  background-color: $white;
                  opacity: 1;
                }
                &.not-clickable {
                  cursor: default;
                }
              }
            }
            .status {
              font-size: 14px;
              &-icon[data-tooltip] {
                position: relative;
                overflow: visible;
                .fa {
                  &-check-circle {
                    color: $color-success;
                  }
                  &-exclamation-circle {
                    color: $color-alert;
                  }
                }
                &:after {
                  content: attr(data-tooltip);
                  position: absolute;
                  top: 0;
                  right: 0;
                  transform: translate(100%, -100%);
                  font-style: italic;
                  font-weight: $ft-bold;
                  font-size: 12px;
                  color: $color-deepblue-100;
                  padding: 2px 8px;
                  background-color: rgba(#fbf1b1, 0.8);
                  width: max-content;
                  pointer-events: none;
                  opacity: 0;
                  transition: 0.3s opacity ease-in-out;
                }
                @media (any-hover: hover) {
                  &:hover {
                    &:after {
                      opacity: 1;
                      transition: 0.3s opacity ease-in-out;
                    }
                  }
                }
              }
            }

            & + .row {
              margin-top: 2px;
            }
          }

          &.filters {
            font-family: $font;
            .label {
              display: block;
              font-size: 12px;
              font-style: italic;
              margin-bottom: 5px;
            }
            .btn-filter {
              min-height: 20px;
              background-color: $color-cobalt;
              border: 2px solid $color-cobalt;
              border-radius: 15px;
              padding: 0 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: $white;
              font-weight: $ft-bold;
              font-size: 12px;
              .btn_label {
                text-align: left;
              }
            }

            .name {
              max-width: calc(100% - 120px - 100px - 40px);
              @media screen and (min-width: $bp-tablet) {
                max-width: none;
              }
            }
            .role {
              .btn-select .btn_icon:before {
                content: "";
              }
            }
            .account-creation {
              margin-right: 0;
              margin-left: auto;
            }

            .status {
              .btn-select .btn_icon:before {
                content: "";
              }
              .drop-select {
                min-width: 120px;
              }
            }
          }

          &.unactive {
            .name,
            .role,
            .account-creation,
            .status {
              color: #829395;
              .avatar {
                background-color: #829395 !important;
              }
            }
          }
          &.blocked {
            background-color: rgba($color-alert, 0.25);
          }
        }
      }
    }
  }

  // .user-invitation-container {
  //   background-color: $white;
  //   border-radius: 2px;
  //   display: flex;
  //   flex-flow: column nowrap;
  //   justify-content: center;
  //   align-items: flex-end;
  //   margin-bottom: 0px;
  //   padding: 10px 18px 10px 20px;
  //   min-width: 60px;

  //   @media screen and (min-width: $bp-tablet) {
  //     flex-flow: row nowrap;
  //     justify-content: space-between;
  //     align-items: center;
  //   }
  //   .send {
  //     .btn-label {
  //       color: $color-black;
  //       text-align: right;
  //     }
  //     margin-top: 10px;
  //     margin-bottom: 10px;
  //     @media screen and (min-width: $bp-smartphone) {
  //       margin-top: 0;
  //     }
  //     @media screen and (min-width: $bp-tablet) {
  //       margin-bottom: 0;
  //     }
  //   }
  // }
}
