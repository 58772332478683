@import "../../../../assets/scss/__mandatory";

.back-btn {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  padding: 0 10px 30px 10px;
  .btn {
    max-width: 220px;
    display: flex;
    justify-content: space-evenly;
    -webkit-appearance: none;
    padding: 0 10px !important;
    &-icon {
      margin-right: 10px;
    }
    &-label {
      margin: 0 !important;
    }
  }
}

.tool-country-data-edit {
  background-color: white;
  color: $color-deepblue-100;
  max-width: 940px;
  padding: 20px;
  margin: 30px auto 0;
  width: 100%;

  .content-head {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 20px 0;
    .id {
      h2 {
        font-size: 16px;
        color: $color-ocean;
      }
    }
    .target {
      h3 {
        font-weight: $ft-black;
        font-size: 20px;
        line-height: 1.4;
        color: $color-deepblue-100;
        max-width: 700px;
      }
    }
    .indicator {
      h4 {
        font-family: $font;
      }
    }
    div + div {
      margin-top: 10px;
    }
  }

  .content-body {
    .label {
      font-size: 12px;
    }
    .list {
      .row {
        width: 100%;
        min-height: 50px;
        display: flex;
        flex-flow: column nowrap;

        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          div + div {
            // margin-left: 10px;
            // @media screen and (min-width: $bp-tablet) {
            //   margin-left: 15px;
            // }
            margin-left: 10px;
          }
        }

        .name,
        .description,
        .link,
        .value,
        .last-year-value,
        .last-update {
          // width: 100%;
          min-height: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: $font;
        }
        .name {
          width: 24%;
          justify-content: space-between;
          .iso-code {
            text-align: right;
          }
        }
        .description {
          width: 22%;
        }
        .link {
          width: 14%;
        }
        .value {
          width: 10%;
        }
        .last-year-value {
          width: 12%;
        }
        .last-update {
          width: 10%;
          justify-content: flex-end;
        }

        &:not(.filters) {
          padding: 10px 15px;
          background-color: $color-seaLight;
          border-radius: 0px 25px 25px 0px;

          .name {
            border-radius: 15px;
            background-color: white;
            font-size: 14px;
            padding: 0px 15px;
            .country {
              font-weight: $ft-bold;
            }
          }

          .description,
          .link {
            position: relative;
            .tooltip {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, calc(-100% - 10px));
              z-index: 10;
              padding: 20px;

              width: 470px;
              background: $color-ocean;
              border-radius: 20px;
              box-shadow: 0px 2px 2px rgba(14, 55, 64, 0.5),
                0px 13px 40px rgba(14, 55, 64, 0.5);
              input,
              textarea {
                width: 100%;
                padding: 10px 15px;
                background: white;
                border: 2px solid #829395;
                border-radius: 15px;
                color: $color-ocean;
                font-size: 14px;
                font-family: $font;
                &::placeholder {
                  color: rgba($color-ocean, 0.7);
                }
                margin-bottom: 15px;
              }

              .btn-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .btn {
                  max-width: 100px;
                  & + .btn {
                    margin-left: 5px;
                  }
                }
              }

              &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 18px 18px 0 18px;
                border-color: $color-ocean transparent transparent transparent;
              }

              &[aria-hidden="false"] {
                & + .btn {
                  background-color: $color-cobalt;
                  color: $white;
                }
              }
            }
            .btn {
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 0 15px;
              &-label {
                margin: 0;
              }
              &.filled {
                background-color: $color-successBright;
                color: $color-deepblue-100;
                @media (any-hover: hover) {
                  &:hover {
                    background-color: $color-cobalt;
                    border-color: $color-cobalt;
                    color: $white;
                    transition: 0.3s all ease-in-out;
                  }
                }
              }
            }
          }

          .value {
            position: relative;
            input {
              max-width: 90px;
              width: 100%;
              border: 2px solid #829395;
              border-radius: 15px;
              background-color: $white;
              padding: 5px;
              font-family: $font;
              font-weight: $ft-bold;
              font-size: 12px;
              color: $color-deepblue-100;
              @media screen and (min-width: $bp-tablet) {
                padding: 5px 10px;
                font-size: 14px;
              }
            }
          }

          .last-year-value {
            .last-year-content {
              border-radius: 15px;
              background-color: white;
              font-size: 14px;
              padding: 0 8px;
              min-height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 65px
            }
            .warning {
              max-width: 25px;
              font-weight: 900;
              font-size: 20px;
              color: $color-alert;
              margin-left: 6px;
              cursor: pointer;
            }
          }

          .last-update {
            // font-weight: $ft-bold;
            font-size: 14px;
            text-align: center;
            // padding-left: 15px;
          }

          .answer-container {
            padding: 10px 0;
            color: $color-deepblue-100;
            width: 100%;
            margin-left: 0;
          
            .choose {
              font-size: 12px;
              font-family: $font;
              margin-left: 30px;

              i {
                display: none;
                color: $color-alert;
                margin-right: 5px;
              }
            }
            .answer-row {
              position: relative;
              min-height: 30px;
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 4px;

              input {
                display: none;
                &.checked {
                  & + label {
                    &:before {
                      opacity: 1;
                      background-color: white;
                    }
                    &:after {
                      background-color: $color-sea;
                      transition: 0.3s all ease-in-out;
                    }
                  }
                }
              }
              label {
                margin-left: 30px;
                span {
                  position: relative;
                  font-weight: $ft-bold;
                  font-size: 14px;
                  z-index: 10;
                }
                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 5px;
                  transform: translateY(-50%);
                  width: 10px;
                  height: 10px;
                  background-color: $color-seaLight;
                  border-radius: 10px;
                  border: 5px solid $color-deepblue-100;
                  opacity: 0;
                  z-index: 2;
                  transition: 0.3s all ease-in-out;
                }
                &:after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 100%;
                  height: 100%;
                  background-color: transparent;
                  border-radius: 15px;
                  z-index: 0;
                  transition: 0.3s all ease-in-out;
                }
              }

              @media (any-hover: hover) {
                &:hover {
                  * {
                    cursor: pointer;
                  }
                  label {
                    &:before {
                      opacity: 1;
                      transition: 0.3s all ease-in-out;
                    }
                  }
                }
              }
            }
          }

          &.warn {
            background-color: rgba($color-alert, 0.25);
            .value {
              input {
                border-color: $color-alert;
                color: $color-alert;
                &::placeholder {
                  font-weight: $ft-regular;
                  color: $color-alert;
                }
              }
            }

            .answer-container {
              .choose {
                color: $color-alert;
                i {
                  display: inherit;
                }
              }
              .answer-row {
                label {
                  &:before {
                    width: 16px;
                    height: 16px;
                    border: 2px solid $color-alert;
                    opacity: 1;
                  }
                }
              }
            }
          }

          &.is-modified {
            background-color: rgba($color-citron, 0.5) !important;
          }
        }

        &.filters {
          .row-content {
            padding: 10px 0;
          }
          i {
            font-size: 12px;
          }
          .btn-filter {
            min-height: 20px;
            background-color: $color-cobalt;
            border: 2px solid $color-cobalt;
            border-radius: 15px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $white;
            font-weight: $ft-bold;
            font-size: 12px;
            .btn_icon {
              margin-left: 5px;
            }
          }

          .name {
            max-width: calc(300px + 30px);
            min-width: calc(200px + 30px);
          }

          .last-update {
            max-width: calc(110px + 15px);
          }
        }

        & + .row {
          margin-top: 4px;
        }
      }
    }
  }

  .loader {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
}
