@import "../../../assets/scss/__mandatory";

$width: 80px;
$height: 30px;

.small-button {
  &:hover {
    color: #a3a3a3;
  }
  &[data-transparent="true"] {
    background-color: transparent;
    color: #dfe3e6;
  }

  &[data-smaller="true"] {
    min-width: $height;
  }

  &[auto-width="true"] {
    min-width: 0px;
  }
}

a,
button,
.uk-button,
.btn {
  @include transitionElt(all, 0.25s, ease);
  border: none;
  cursor: pointer;
  &:focus,
  &.focus,
  &:active,
  &.active,
  &:active:focus {
    outline: none;
    outline-offset: 0;
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

button,
.uk-button .btn {
  font-family: inherit;
  background-color: transparent;
  border: none;
  &:not(.btn-rounded) {
    @include border-radius(2px);
  }
}

.btn {
  position: relative;
  text-align: center;
  display: inline-flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  min-height: 30px;
  min-width: 30px;
  &:not(.no-cursor) {
    cursor: pointer;
  }
  &.no-cursor {
    cursor: default;
  }
  &:focus,
  &:active {
    outline: none;
  }
  .major {
    text-transform: uppercase;
  }
  &__label {
    position: relative;
    display: block;
    margin: auto;
    &.primary {
      color: $color-deepblue-100;
    }
    & + .btn-icon {
      //padding-left: 8px;
    }
  }
  &__icon {
    margin: auto;
    display: block;
    i {
      display: block;
      line-height: 1;
      font-size: 14px;
    }
  }
  &-full {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  &-action {
    min-height: 0;
    .icon {
      padding: 0;
    }
  }
  &-small {
    height: 30px;
    @include border-radius(15px);
    color: white;
    background-color: $color-ocean;
    &:not(.btn-circle) {
      min-width: 40px;
    }
    i {
      margin: auto;
    }
    .btn-label {
      & + .btn-icon {
        margin-left: 5px;
      }
    }
    .btn-icon {
      & + .btn-label {
        margin-left: 5px;
      }
    }
  }
  &-primary {
    min-height: 30px;
    @include border-radius(15px);
    color: white;
    background-color: $color-ocean;
    padding: 0 25px;
    .btn-label {
      margin: auto;
      font-size: 12px;
    }
  }
  &-primary-dark {
    min-height: 30px;
    @include border-radius(15px);
    color: white;
    background-color: $color-deepblue-100;
    padding: 0 25px;
    .btn-label {
      color: white;
      margin: auto;
      font-size: 12px;
    }
  }
  &-white {
    min-height: 30px;
    @include border-radius(15px);
    background-color: white;
    color: $color-deepblue-100;
    padding: 0 25px;
    .btn-label {
      color: $color-deepblue-100;
      margin: auto;
      font-size: 12px;
    }
  }

  &-next {
    min-height: 30px;
    @include border-radius(15px);
    color: white;
    background-color: $color-ocean;
    border: 2px solid white;
    padding: 0 25px;
    &:not(.start) {
      min-width: 80px;
    }
  }
  &-citron {
    min-height: 30px;
    @include border-radius(15px);
    color: $color-deepblue-100;
    background-color: $color-citron;
    border-color: $color-deepblue-100;
    padding: 0 25px;
    .btn-label {
      margin: auto;
      font-size: 12px;
    }
  }
  &-goals {
    background-color: rgba($color-sea, 1);
    border-color: rgba($color-sea, 1);
    min-height: 20px;
    @include border-radius(15px);
    padding: 0 10px;
    &.on {
      background-color: rgba($color-success, 0.5);
      border-color: rgba($color-success, 0.5);
    }
    .btn {
      &_label {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
  &.label-external {
    padding: 0;
    &:not(.irrelevent-question) {
      color: white;
    }
    .btn_label,
    .btn-label {
      font-size: 12px;
      font-weight: 400;
      background-color: transparent;
      &.label {
        &-primary {
          color: $color-primary;
        }
      }
      & + .btn_icon,
      & + .btn-icon {
        margin-left: 10px;
      }
    }
    .btn_icon,
    .btn-icon {
      display: block;
      width: 28px;
      height: 28px;
      padding: 0;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      @include border-radius(50%);
      i {
        font-size: 14px;
      }
      &.icon {
        &-primary {
          color: white;
          border: 1px solid $color-primary;
          background-color: $color-ocean;
        }
      }
      & + .btn_label,
      & + .btn-label {
        margin-left: 10px;
      }
    }
    &:not(.btn-cancel) {
      .btn-icon {
        background-color: $color-ocean;
        border: 2px solid $color-deepblue-100;
        width: 30px;
        height: 30px;
        display: flex;
        flex-flow: wrap;
        @include border-radius(15px);
        &:not(.circle) {
          min-width: 40px;
          & + .btn-label {
            margin-left: 10px;
          }
        }
        &.circle {
          & + .btn-label {
            margin-left: 5px;
          }
        }
        i {
          margin: auto;
        }
      }
    }
    &.btn-cancel {
      .btn-icon {
        color: $color-alert;
      }
      .btn-label {
        margin-left: 5px;
        color: $color-deepblue-100;
      }
    }
    &.btn-edit,
    &.btn-save {
      .btn-label {
        color: $color-deepblue-100;
        & + .btn-icon {
          margin-left: 5px;
        }
      }
    }
  }
  &-bordered {
    border: 2px solid $color-deepblue-100;
  }

  &-svg {
    .btn_label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;

      strong,
      small {
        font-size: 14px;
        letter-spacing: 1.3pt;
        display: block;
        margin: auto;
        @include transitionElt(all, 0.5s, ease);
      }
    }
    .btn-assets {
      svg {
        &.main-cta {
          width: 230.3 * 1 * 1px;
          height: 62.9 * 1 * 1px;
        }
      }
    }
  }
  &-select {
    color: white;
    background-color: $color-ocean;
    border: 2px solid $color-deepblue-100;
    @include border-radius(15px);
    padding: 0 5px 0 10px;
    min-height: 28px;
    transition: 0.3s all ease-in-out;

    @media (any-hover: hover) {
      &:hover {
        background-color: $color-cobalt;
        border-color: $color-cobalt;
        color: $white;
        transition: 0.3s all ease-in-out;
      }
    }

    .btn_label {
      font-size: 12px;
      padding-left: 2px;
    }

    &.with-filter {
      .btn_label {
        &:not(.uk-active) {
          display: none;
        }
      }
    }
    &.full {
      .btn_label {
        text-align: left;
        padding-left: 10px;
      }
    }
    &.btn-pdf-select {
      min-width: 90px;
    }
    &.btn-select-currency {
      background-color: $color-deepblue-100;
      min-width: 60px;
      .btn_icon {
        &:before {
          content: "\f0dc";
          font-family: "Font Awesome 5 Pro";
        }
      }
    }
    .btn_label {
      width: calc(100% - 30px);
      & + .btn_icon {
        width: 20px;
        margin-left: 10px;
      }
    }
    .btn_icon {
      &:before {
        content: "\f0d7";
        font-family: "Font Awesome 5 Pro";
      }
    }
    & + .drop-filter,
    & + .drop-menu {
      background-color: transparent;
      z-index: 20;
      padding: 0;
      &.uk-open {
        &:not(.uk-dropdown-top-left) {
          ul {
            margin-top: -40px;
          }
        }
        &.uk-dropdown-top-left {
          ul {
            margin-bottom: -40px;
          }
        }
      }
      ul {
        width: auto;
        background-color: $color-cobalt;
        color: white;

        @include border-radius(15px);
        box-shadow: 0 2px 5px rgba($color-deepblue-100, 0.5);
        li {
          width: 100%;
          min-height: 24px;
          display: flex;
          align-items: center;
          text-align: left;
          span {
            display: block;
            margin: auto 0;
            padding: 0 20px;
          }
          &:hover,
          &.uk-active {
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
    & + .drop {
      background-color: transparent;
      z-index: 21;
      &:not(.uk-open) {
        pointer-events: none;
      }
      &:not(.drop-currency) {
        width: 100%;
        min-width: 80px;
        padding: 0;
        &.drop-select-pdf {
          li {
            min-height: 24px;
          }
        }
      }
      &.drop-currency {
        padding: 0;
        min-width: 80px;
      }
      &.with-group {
        z-index: 3000;
      }
      &.large {
        min-width: 465px;
        ul {
          &.group {
            &.lvl0 {
              display: flex;
              position: relative;
              li {
                &.subgroup {
                  position: relative;
                  width: 50%;
                  height: 100%;
                  &:first-child {
                    border-right: 1px solid $color-deepblue-100;
                    // &:before {
                    //     content: "";
                    //     width: 2px;
                    //     height: 100%;
                    //     position: absolute;
                    //     top: 0;
                    //     right: 0;
                    //     margin-left: -1px;
                    //     background-color: $color-deepblue-100;
                    // }
                  }
                  & + .subgroup {
                  }
                }
              }
            }
          }
        }
      }
      &.uk-open {
        &:not(.uk-dropdown-top-left) {
          ul {
            &:not(.group) {
              margin-top: -28px;
              margin-left: 0;
            }
            &.group {
              &.lvl0 {
                margin-top: -40px;
              }
            }
          }
        }
        &.uk-dropdown-top-left {
          ul {
            &:not(.group) {
              margin-bottom: -70px;
              margin-left: 0;
            }
            &.group {
              &.lvl0 {
                margin-bottom: -70px;
              }
            }
          }
        }
      }
      ul {
        &:not(.group) {
          width: auto;
          background-color: $color-cobalt;
          @include border-radius(15px);
          box-shadow: 0 2px 5px rgba($color-deepblue-100, 0.5);
          color: white;
          padding: 5px 0;
          min-height: 30px;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          li {
            cursor: pointer;
            padding: 0 10px;
            width: 100%;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            &:hover {
              span {
                font-weight: 700;
              }
            }
            span {
              font-size: 12px;
              display: block;
              width: 100%;
              margin: auto 0;
            }
            & + li {
            }
          }
        }
        &.group {
          width: 100%;
          &.lvl0 {
            background-color: $color-cobalt;
            @include border-radius(15px);
            box-shadow: 0 2px 5px rgba($color-deepblue-100, 0.5);
          }
          .subgroup {
            width: 100%;
            padding: 5px 0;
            li {
              width: 100%;
              padding: 0 10px;
              color: white;
              font-size: 12px;
              cursor: pointer;
              & + li {
                margin-top: 5px;
              }
            }
            &.group-secondary {
              border-top: 1px solid rgba(white, 0.25);
              max-height: 80px;

              overflow-y: auto;
              -webkit-overflow-scrolling: touch;
            }
          }
          .label-group {
            width: 100%;
            padding: 0 10px;
            color: $color-sky;
            font-weight: 700;
            font-size: 13px;
            text-transform: uppercase;
            display: block;
          }
        }
        li {
          &.item {
            width: 100%;
            padding: 0;
            min-height: 20px;
            color: white;
            font-size: 12px;
            cursor: pointer;

            display: flex;
            flex-flow: wrap;
            align-items: center;
            span {
              font-size: 12px;
              display: block;
              margin: auto 0;
              &.icon {
                width: 24px;
                text-align: center;
              }
              &.name {
                width: calc(100% - 24px);
              }
            }
          }
        }
        span {
          &.clickable {
            &:hover {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  &.btn-burger {
    padding: 0;
    .btn-label {
      font-weight: 400;
      font-size: 14px;
      & + .btn-icon {
        @media screen and (min-width: $bp-tablet) {
          margin-left: 10px;
        }
      }
    }
    .btn-icon {
      position: relative;
      width: 30px;
      height: 30px;
      overflow: hidden;
      background-color: $color-ocean;
      border: 2px solid $color-deepblue-100;
      @include border-radius(50%);
      @include transitionElt(all, 0.25s, ease);
      color: white;
      display: flex;
      &:after {
        width: 100%;
        height: 100%;
        line-height: 26px;
        content: "\f060";
        font-family: "Font Awesome 5 Pro";
        font-weight: 700;
        position: absolute;
        left: 100%;
        @include transitionElt(all, 0.25s, ease);
      }
      i {
        margin: auto;
      }
    }
    &:hover {
      .btn-icon {
        background-color: $color-deepblue-100;
        i {
          opacity: 0;
        }
        &:after {
          left: 0;
        }
      }
    }
    &.open {
    }
  }
  &.btn-close {
    .btn-icon {
      padding: 0;
      min-width: 30px;
      color: white;
      border: 2px solid white;
    }
    // width: 30px;
    // height: 30px;
    // background-color: $color-ocean;
    // border: 2px solid $color-deepblue-100;
    // @include border-radius(50%);
    // color: white;
    // display: flex;
    // i {
    //     margin: auto;
    // }
  }
  &-close-menu {
    @include border-radius(50%);
    padding: 0;
    min-width: 30px;
    color: white;
    border: 2px solid $color-deepblue-100;
    background-color: $color-ocean;
    .btn_icon {
      width: 100%;
      margin: auto;
      @include transitionElt(all, 0.25s, ease);
    }
    &:hover {
      background-color: $color-deepblue-100;
      .btn-icon {
        @include rotateElt(90deg);
      }
    }
  }
  &.btn-citron {
  }
  &.btn-save {
    .btn-icon {
      display: flex;
      align-items: center;
      color: $color-success;
      svg {
        margin: auto;
        display: block;
        width: 16px;
        height: 16px;
        -webkit-animation: rotate-center 0.2s linear infinite both;
        animation: rotate-center 0.2s linear infinite both;
        transform-origin: 50% 50%;
      }
    }
  }
  // .fa-spinner {
  //     -webkit-animation: rotate-center 0.2s linear infinite both;
  //     animation: rotate-center 0.2s linear infinite both;
  // }

  &.animated //classic case
  {
    overflow: hidden;
    position: relative;

    &::after,
    &::before {
      @include border-radius(15px);
      content: "";
      width: 0%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      left: 0;
    }
    &::after {
      background-color: $color-anim-btn2;
      transition: width 0.35s ease-in;
      z-index: 3;
    }

    &::before {
      background-color: $color-anim-btn1;
      transition: width 0.4s cubic-bezier(0, 0.18, 0.25, 0.99);
      z-index: 1;
    }

    .btn-label,
    .btn-icon {
      position: relative;
      z-index: 5;
    }
    &:hover {
      &::after {
        width: 100%;
      }

      &::before {
        width: 100%;
      }

      .btn-label,
      .btn-icon {
        animation: 0.15s middleToRight ease-out forwards,
          0.1s jumpToLeft 0.15s forwards, 0.2s leftToMiddle 0.3s forwards;
      }
    }
  }

  &.next-animated:hover + .animated //case when a card button area overlap a ui button
  {
    &::after {
      width: 100%;
    }

    &::before {
      width: 100%;
    }

    .btn-label,
    .btn-icon {
      animation: 0.15s middleToRight ease-out forwards,
        0.1s jumpToLeft 0.15s forwards, 0.2s leftToMiddle 0.3s forwards;
    }
  }

  @keyframes middleToRight {
    0% {
      left: 0%;
    }
    99% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      left: 100%;
    }
  }

  @keyframes jumpToLeft {
    0% {
      left: 100%;
    }
    100% {
      left: -100%;
    }
  }

  @keyframes leftToMiddle {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
      left: -100%;
    }
    100% {
      left: 0%;
    }
  }
}

.btn-no-text {
  position: relative;
  padding: 0;
  .btn-label,
  .btn_label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    text-indent: -9999px;
  }
}
.btn__no-text {
  position: relative;
  padding: 0;
  .btn__label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    text-indent: -9999px;
  }
}

.link {
  padding: 0;
  @include transitionElt(all, 0.25s, ease);
  &:hover {
    text-decoration: none;
    //    opacity: 0.5;
  }
  &.link-minisite {
    font-size: 12px;
    letter-spacing: 0.8pt;
    font-weight: bold;
    text-transform: uppercase;
  }
  &.underline {
    text-decoration: underline;
  }
  .link-icon {
    width: 20px;
    i {
      margin: auto;
      font-size: 12px;
    }
    & + .link-label {
      margin-left: 10px;
    }
  }
  .link-label {
    & + .link-icon {
      margin-left: 10px;
    }
  }
  &.link-white {
    color: $color-white;
  }
}
