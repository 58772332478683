@import "../../../../assets/scss/__mandatory";
@import "https://unpkg.com/maplibre-gl@1.15.2/dist/maplibre-gl.css";

.tool_project-location {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: stretch;
    color: $color-deepblue-100;
    @media screen and (max-width: $bp-tablet) {
        padding: 0 20px;
    }
    .btn-mobile {
        position: absolute;
        top: 80px;
        right: 5px;
        width: 30px;
        height: 30px;
        overflow: hidden;
        background-color: #2b77a6;
        border: 2px solid #102d40;
        border-radius: 50%;
        transition: all 0.5s ease-in-out;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        @media screen and (min-width: $bp-smartphone-xlarge) {
            display: none;
        }
        &.open {
            top: 30px;
            right: 305px;
        }
    }
    .tool {
        &_map {
            position: relative;
            width: 100%;
            height: 100%;
            @media screen and (min-width: $bp-smartphone-xlarge) {
                width: calc(100% - 320px);
            }
            .map {
                &-wrapper {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    .mapboxgl {
                        &-map {
                            width: 100%;
                            height: 100%;
                        }
                        &-canvas {
                            width: 100% !important;
                            &-container {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
        &_container {
            width: 100%;
            height: 100%;
            max-width: 320px;
            background-color: $white;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            transform: translateX(100%);
            transition: 0.5s all ease-in-out;
            @media screen and (min-width: $bp-smartphone-xlarge) {
                max-width: 320px;
                position: relative;
                transform: none;
            }
            &.open {
                transform: translateX(0);
                transition: 0.5s all ease-in-out;
            }
            .tool_head {
                min-height: 80px;
                padding: 20px 20px 10px 20px;
                font-family: $font-body-wf;
                border-bottom: 1px solid $color-softGrey;
                .title {
                    font-weight: $ft-black;
                    font-size: 18px;
                    line-height: 1.4;
                    text-transform: uppercase;
                }
                .subtitle {
                    font-size: 14px;
                    line-height: 1.2;
                }
            }
            .tool_list {
                height: calc(100% - 80px);
                overflow: hidden;
                position: relative;

                ul {
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    overflow: scroll;
                    scroll-behavior: smooth;

                    li {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 4px 20px 8px 20px;
                        transition: .3s all ease-in-out;

                        &:nth-child(even) {
                            background-color: $color-seaLight;
                        }
                        .type {
                            opacity: 0.9;
                            margin-right: 8px;
                            img {
                                width: 14px;
                                transition: .3s all ease-in-out;
                                filter: invert(0.9) sepia(1) saturate(3) hue-rotate(200deg);
                            }
                            i {
                                font-weight: $ft-black;
                                font-size: 12px;
                                color: $color-deepblue-100;
                                transition: .3s all ease-in-out;
                            }
                        }
                        .content {
                            .name {
                                font-weight: $ft-bold;
                                font-size: 12px;
                                line-height: 1.2;
                                margin-top: 2px;
                            }
                            .stage {
                                font-style: italic;
                                font-size: 10px;
                                &:before {
                                    content: "";
                                    display: inline-block;
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 20px;
                                    background-color: $color-successBright;
                                    margin-right: 4px;
                                }
                                &.operation {
                                    &:before {
                                        background-color: $color-citron;
                                    }
                                }
                                &.both {
                                    &:before {
                                        background-color: $color-mandarine;
                                    }
                                }
                            }
                        }

                        &:hover, &.selected {
                            background-color: $color-deepblue-100;
                            color: white;
                            transition: .3s all ease-in-out;
                            .type {
                                img {
                                    filter: none;
                                    transition: .3s all ease-in-out;
                                }
                                i {
                                    color: white;
                                    transition: .3s all ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
