@import "../../../../assets/scss/__mandatory";

.list {
  .row {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    div + div {
      margin-left: 10px;
      @media screen and (min-width: $bp-tablet) {
        margin-left: 15px;
      }
    }

    .fund,
    .fund-indicators,
    .fund-documents,
    .fund-downloads {
      width: 100%;
      min-height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .fund {
      width: calc(100% - 180px - 180px - 60px - 45px);
    }
    .fund-indicators {
      max-width: 180px;
      width: 100%;
    }
    .fund-documents {
      max-width: 180px;
      width: 100%;
      padding-left: 12px;
    }
    .fund-downloads {
      max-width: 60px;
      width: 100%;
      padding-left: 12px;
    }

    &:not(.filters) {
      font-family: $font-title-wf;
      font-size: 16px;
      border: 1px solid $color-seaLight;
      background-color: $color-seaLight;
      border-radius: 0px 25px 25px 0px;
      color: $color-deepblue-100;
      .fund-indicators,
      .fund-documents {
        color: $color-ocean;
        font-weight: $ft-black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .completion {
          &-container {
            width: 100px;
            height: 8px;
            background-color: $white;
          }
          &-bar {
            display: block;
            height: 8px;
            background-color: $color-ocean;
          }
        }
        .warn-icon {
          color: $color-alert;
          opacity: 0;
        }
        &.warn {
          color: $color-alert;
          .warn-icon {
            opacity: 1;
          }
        }
      }

      .fund-documents,
      .fund-downloads {
        border-left: 1px solid $color-sea;
      }
      .fund-downloads {
        display: flex;
        justify-content: center;
      }

      &.warn {
        background-color: rgba($color-alert, 0.25);
        .fund-documents,
        .fund-downloads {
          border-left-color: $color-alert;
        }
      }
    }

    &.filters {
      .label {
        font-size: 12px;
        margin-bottom: 5px;
      }
      .fund,
      .fund-indicators,
      .fund-documents {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
      }
      .fund-downloads {
        text-align: center;
      }
      .btn-filter {
        min-height: 20px;
        background-color: $color-cobalt;
        border: 2px solid $color-cobalt;
        border-radius: 15px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        font-weight: $ft-bold;
        font-size: 12px;
        .btn_icon {
          margin-left: 5px;
        }
        &.btn-invert {
          background-color: $color-softGrey;
          border-color: $color-softGrey;
          color: $color-cobalt;
        }
      }
    }

    & + .row {
      margin-top: 2px;
    }
  }
}
