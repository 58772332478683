@import "../../__mandatory";

.own-modal {
  background-color: rgba($color-deepblue-100, 0.95);

  &:not(.uk-open) {
    pointer-events: none;
    .uk-modal-dialog {
      transform: translateY(0) !important;
      .own-modal-content {
        -webkit-animation: modal-disappear 0.3s
          cubic-bezier(0.47, 0, 0.745, 0.715) both;
        animation: modal-disappear 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
      }
    }
  }
  &.uk-open {
    .uk-modal-dialog {
      transform: translateY(0) !important;
      .own-modal-content {
        -webkit-animation: modal-appear 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
          both;
        animation: modal-appear 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
      }
    }
  }
  .uk-modal-dialog {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    margin: auto;
    background-color: transparent;
  }
  .own-modal-content {
    pointer-events: initial;
    position: relative;
  }
  .btn-close {
    position: absolute;
    top: -40px;
    right: 0;
    padding: 0;
  }
  .uk-modal-header {
    background-color: transparent;
    padding: 0 100px 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-ocean;
    h2 {
      font-size: 18px;
      line-height: 1.1;
      font-weight: 900;
      margin-bottom: 0;
      padding: 0;
      color: $color-deepblue-100;
      & + h3 {
        margin-top: 5px;
      }
    }
    h3 {
      color: $color-ocean;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.4;
    }
    &.mode-alert {
      h2 {
        &.colored {
          strong {
            color: $color-alert;
          }
        }
      }
      .modal-icon {
        color: $color-deepblue-100;
      }
    }
    &.mode-success {
      h2 {
        &.colored {
          strong {
            color: $color-successBright;
          }
        }
      }
    }
    .modal-icon {
      margin-bottom: 20px;
      color: white;

      i {
        font-size: 30px;
      }
    }
  }
  .uk-modal-body {
    width: 100%;
    margin: auto;
    height: 100%;
    padding: 0;
    background-color: transparent;
  }
  .uk-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.modal-footer-blue {
      background-color: $color-ocean;
    }
    &.modal-footer-yellow {
      color: $color-text;
      background-color: $color-citron;
      .btn {
        &.label-external {
          .btn-label {
            color: $color-text;
          }
        }
      }
    }
  }
}
