.form {
  &_inner {
    position: relative;
    width: 100%;
    padding: 15px 0;
  }
  &-row {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    &.row {
      &-block {
        width: 100%;
        label {
          width: 100%;
          display: block;
          & + div,
          & + input {
            margin-top: 5px;
          }
        }
        input {
        }
      }
      & + .row-block {
        margin-top: 15px;
        &.row-submit {
          margin-top: 30px;
        }
      }
      &-upload {
        position: relative;
      }
      &-autocomplete {
      }
    }
  }
  label {
    font-size: 12px;
    line-height: 1.2;
    font-weight: $ft-medium;
  }
  &_group {
    & + .form_group {
      margin-top: 30px;
    }
    &-title {
      margin-bottom: 10px;
    }
  }
  &-error,
  .error {
    margin-top: 5px;
    color: $color-secondary;
    font-size: 12px;
    font-weight: $ft-regular;
  }
}
