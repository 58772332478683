input {
  &[type="color"],
  &[type="date"],
  &[type="datetime"],
  &[type="datetime-local"],
  &[type="email"],
  &[type="month"],
  &[type="number"],
  &[type="password"],
  &[type="search"],
  &[type="tel"],
  &[type="text"],
  &[type="time"],
  &[type="url"],
  &[type="week"] {
    @include border-radius(20px);
    background-color: $color-primary-25;
    border: $input-border-size solid $color-primary-25;
    color: $color-primary;
    box-shadow: none;
    height: $input-height;
    line-height: $input-height;
    width: 100%;
    padding: 0 10px;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    @media screen and (min-width: $bp-tablet) {
      height: $input-heightDesk;
      line-height: $input-heightDesk;
    }
    &:focus,
    &:active {
      border-color: $color;
    }
  }
}
