@import "../../../../../assets/scss/_mandatory";

$color-toolbar-lp: #284253;
$color-toolbar-lp-text: #587482;

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.toolbar {
  position: relative;
  width: 100%;
  text-align: center;
  &_inner {
    // width: auto;
    // display: inline-flex;
    // flex-flow: column-reverse;
    // @media screen and (min-width: $bp-tablet) {
    //   flex-flow: wrap;
    //   min-height: 100px;
    //   align-items: center;
    //   background-color: inherit;
    // }

    //Quand un filtre est open
    // .area {
    //   &-left,
    //   &-right {
    //     width: 25%;
    //   }
    //   &-left,
    //   &-center,
    //   &-right {
    //   }
    //   &-left {
    //     margin: auto auto auto 0;
    //     text-align: left;
    //   }
    //   &-center {
    //     width: 50%;
    //     margin: auto;
    //     text-align: center;
    //   }
    //   &-right {
    //     margin: auto 0 auto auto;
    //     text-align: right;
    //   }
    // }
  }

  &-main {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    &.toolbar-white {
      .toolbar_inner {
        background-color: white;
        color: $color-deepblue-100;
        &:before,
        &:after {
          background-color: white;
        }

        .menu,
        .area-left {
          background-color: white;
          .btn {
            margin: auto;
            color: inherit;
          }
        }
        .area-left {
          color: inherit;
          &:before {
            background-color: white;
          }
        }
        .area-right {
          color: inherit;
        }
      }
    }
    .toolbar_inner {
      position: relative;
      display: inline-flex;
      width: 100%;
      background-color: $color-ocean;
      color: $color-deepblue-100;
      max-width: 960px;
      min-height: 60px;
      font-size: 14px;
      margin: auto 0;
      padding: 0 10px;
      flex-flow: wrap;
      align-items: center;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        display: block;
      }
      &:after {
        right: 100%;
        width: calc((100vw - 960px) / 2);
        background-color: $color-deepblue-100;
      }
      &:before {
        width: 30px;
        left: 100%;
        background-color: $color-ocean;
        @include border-radius(0 30px 30px 0);
      }

      .menu,
      .area-left {
        height: 100%;
        display: flex;
        align-items: center;
        flex-flow: wrap;
        min-height: 60px;
        background-color: $color-deepblue-100;
        align-items: center;
        margin-left: 0;
      }
      .menu {
        .btn {
          margin: auto;
          color: white;
          &-label {
            display: none;
            @media screen and (min-width: $bp-tablet) {
              display: block;
            }
          }
        }
      }
      .area-left {
        position: relative;
        color: white;
        width: calc(100% - 30px);
        @media screen and (min-width: $bp-tablet) {
          width: auto;
          padding: 0 40px 0 20px;
        }
        &:before {
          content: "";
          position: absolute;
          width: 30px;
          left: 100%;
          top: 0;
          height: 100%;
          background-color: $color-deepblue-100;
          @include border-radius(0 30px 30px 0);
        }
        @media screen and (min-width: $bp-tablet) {
        }
      }
      .area-right {
        margin-left: auto;
        margin-right: 0;
        color: white;
      }
    }
    // flex-direction: row;
    // height: 60px;
    // justify-content: space-between;
    // padding-left: 80px;
    // padding-right: 80px;
    // margin-right: 90px;
    // border-top-right-radius: 30px;
    // border-bottom-right-radius: 30px;
    // background-color: #fff;
    // color: $blue;
    // font-weight: 400;
    // font-size: 0.8em;

    .selector {
      display: flex;
      align-items: center;

      margin-left: 10px;
      @media screen and (min-width: $bp-tablet) {
        margin-left: 0;
      }
      .label {
        & + .label-year {
          padding-left: 5px;
          margin-right: 10px;
        }
      }
      .select {
        margin-left: 6px;
        position: relative;
        height: 30px;
        color: #fff;

        select {
          color: inherit;
          font-size: 0.7em;
          font-weight: 700;
          border: none;
          box-shadow: none;
          border-radius: 0;
          background: $blue;
          height: 100%;
          width: 100%;
          cursor: pointer;
          outline: none;
          padding-right: 25px;
          padding-bottom: 2px;
          padding-left: 12px;
          border: 2px solid $dark-blue;
          border-radius: 15px;

          -moz-appearance: none;

          &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
          }

          -webkit-appearance: none;

          &::-ms-expand {
            display: none;
          }
        }
      }
    }

    .search {
      display: flex;
      align-items: center;
      justify-content: center;
      .box {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 10px;
        background-color: #fff;
        width: 350px;
        min-width: 200px;
        border-radius: 15px;
        input {
          width: 100%;
        }

        i {
          margin-right: 10px;
          color: $blue;
        }
      }
    }

    .export {
      display: flex;
      align-items: center;
      justify-content: center;

      app-small-button {
        margin-left: 6px;
        span {
          font-weight: 700;
        }
      }
    }
  }
  &-secondary {
    &-filters {
      .menu {
        z-index: 10;
        position: relative;
        display: flex;
        flex-flow: wrap;
        width: 160px;
        background-color: $color-toolbar-lp;
        padding: 0 20px;
        height: 100%;
        min-height: 60px;
        @media screen and (min-width: $bp-tablet) {
          padding: 0;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          height: 100%;
          display: block;
          background-color: $color-toolbar-lp;
        }
        &:after {
          right: 100%;
          width: calc((100vw - 940px) / 2);
        }
        &:before {
          width: 30px;
          left: 100%;
          @include border-radius(0 30px 30px 0);
        }
        &-open {
          background-color: $color-cobalt;
          .filters {
            display: flex !important;
          }
        }
        .btn {
          position: relative;
          width: 100%;
          margin: auto 0;
          padding-left: 0;
          pointer-events: visible;

          @media screen and (min-width: $bp-tablet) {
            cursor: text;
            pointer-events: none;
          }
          &__label {
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            color: $color-toolbar-lp-text;
          }
          &__icon {
            display: block;
            color: $white;

            @media screen and (min-width: $bp-tablet) {
              display: none;
            }

            &:before {
              content: "\f0c9";
              font-family: $font-icon;
              font-weight: 400;
              font-size: 15px;
            }
            &.menu-open {
              &.rotate-center {
                -webkit-animation: rotate-center 0.5s ease-in-out both;
                animation: rotate-center 0.5s ease-in-out both;
              }
              &:before {
                content: "\f00d";
                font-family: $font-icon;
                font-weight: 400;
                font-size: 18px;
              }
            }
          }
        }
      }

      .filters {
        display: none;
        height: 100%;
        padding: 20px;
        @media screen and (min-width: $bp-tablet) {
          display: flex;
          flex-flow: wrap;
          align-items: center;
          width: calc(100% - 160px);
          padding: 0 0 0 60px;
        }

        .filters_inner {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-items: center;
          align-content: center;
          font-size: 12px;
          .filter {
            width: 100%;
            height: 80px;
            @media screen and (min-width: $bp-tablet) {
              width: 100 / 3 * 1%;
            }

            .filter_child {
              width: 100%;
              height: 100%;
              padding: 10px;
              display: flex;
              flex-flow: column;
              justify-content: space-between;
              align-items: flex-start;

              .filter__label {
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }
}

.uk-offcanvas {
  &:not(.uk-open) {
    .uk-offcanvas-bar {
      @media screen and (min-width: $bp-desk-large) {
        left: -640px;
      }
    }
  }
  &.uk-open {
    &.uk-offcanvas-overlay {
      &:before {
        background-color: rgba($color-deepblue-100, 0.8);
      }
    }
  }
  .uk-offcanvas-bar {
    @media screen and (min-width: $bp-desk-large) {
      //max-width: 700px;
      width: 640px;
    }
  }
  .own-offcanvas {
    background-color: white;
    color: $color-text;
    display: flex;
    flex-flow: wrap;
  }
  .inner-offcanvas {
    position: relative;
    margin: 60px 0 auto auto;
    width: 100%;
    max-width: 330px;
    padding-top: 60px;
    .btn-close {
      position: absolute;
      top: 15px;
      left: 0;
    }
  }
  .offcanvas-head {
    font-family: $font-title-wf;
    font-size: 14px;
    line-height: 1.1;
    padding: 8px 0;
    .btn {
      text-align: center;
      & + .brand {
        margin-left: 10px;
      }
    }
    .brand {
      font-weight: 400;
    }
    .universe {
      font-weight: 900;
      a {
        color: inherit;
        &:hover {
          text-decoration: none;
          color: $color-citron;
        }
      }
    }
  }
  .offcanvas-body {
    padding: 20px 0;
    h4 {
      font-style: italic;
      font-size: 12px;
      padding: 5px 0;
    }
    ul {
      li {
        div {
          display: flex;
          width: 100%;
          min-height: 40px;
          padding: 0 15px;
          span {
            display: block;
            margin: auto 0;
            font-size: 14px;
          }
        }

        &.current {
          background-color: rgba($color-deepblue-100, 1);
          color: white;
          font-weight: 700;
          pointer-events: none;
        }
        &:not(.current) {
          &:hover {
            cursor: pointer;
            background-color: $color-citronBright;
          }
        }
      }
    }
  }
}
