@import "../../../../../assets/scss/__mandatory";

.selection {
  width: 100%;
  background-color: $color-deepblue-100;
  color: white;
  @include border-radius(0 0 20px 20px);
  padding: 15px 30px;
  .btn {
    cursor: pointer;
  }
  &-wrapper {
    width: 100%;
    position: relative;
    &.close {
      display: none;
    }
    &.open {
      display: block;
    }
  }
  &-head {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    .btn {
      &-remote {
        margin: auto auto auto 0;
        color: white;
        .btn {
          &_label {
            font-size: 16px;
          }
          &_icon {
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
      &-reset-all {
        margin: auto 0 auto auto;
        color: white;
        .btn {
          &_label {
            font-weight: $ft-bold;
            font-style: italic;
            font-size: 12px;
          }
          &_icon {
            color: $color-alert;
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
    }
  }
  &-remote {
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
    appearance: none;
    display: block;
    &:checked {
      & + .selection {
        &-body {
          display: block;
        }
      }
    }
  }
  &-body {
    display: none;
    padding-top: 20px;
  }
  &-group {
    & + .selection {
      &-group {
        margin-top: 15px;
      }
    }
    .group {
      &-head {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        span {
          font-size: 12px;
        }
        .btn {
          margin: auto 0 auto auto;
          color: white;
          .btn {
            &_label {
              font-weight: $ft-bold;
              font-style: italic;
              font-size: 12px;
            }
            &_icon {
              color: $color-alert;
              font-size: 12px;
              margin-left: 10px;
            }
          }
        }
      }
      &-body {
        width: 100%;
        ul {
          width: 100%;
          display: flex;
          flex-flow: wrap;
          padding: 5px 60px 0px 0;
          li {
            padding-right: 5px;
            .btn {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: white;
              @include border-radius(30px);
              padding: 0 10px;
              min-height: 20px;
              &_label {
                color: $color-deepblue-100;
                font-size: 12px;
              }
              &_icon {
                color: $color-alert;
                font-size: 12px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
