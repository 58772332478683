@import "../../../../assets/scss/__mandatory";

.window-too-small {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background-color: rgba($color-deepblue-100, 0.95);
    display: flex;
    background-color: rgba($color-deepblue-100, 1);
    @media screen and (min-width: $bp-desk-small) {
        display: none;
    }

    &_inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
        padding: 0 20px;

        .content {
            margin: auto;
            width: 100%;
            text-align: center;
            .head {
                .logo-brand {
                    width: 174px;
                    height: 42px;
                }
                & + .body {
                    margin-top: 40px;
                }
            }

            .body {
                padding: 40px 20px;
                width: 100%;
                max-width: 600px;
                margin: auto;
                background-color: white;
                .title {
                    h3 {
                        color: #ff4a56;
                        font-size: 18px;
                        line-height: 1.1;
                        font-weight: 900;
                    }
                    & + .text {
                        margin-top: 20px;
                    }
                }
                .text {
                    color: $color-text;
                    svg {
                        & + .text {
                            margin-top: 20px;
                        }
                    }
                    .icons {
                        display: flex;
                        max-width: 180px;
                        margin: auto;
                        flex-flow: wrap;
                        justify-content: space-between;
                        i {
                            font-size: 60px;
                            margin: auto;
                        }
                    }
                    .text {
                        padding: 20px 0;
                        font-size: 12px;
                        font-weight: 700;
                    }
                    .wrapp-cta {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
