@import "../../../../../../assets/scss/_mandatory";

.dropdown {
  &-funds {
    // position: relative;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    //height: 25px;
    width: calc(100% - 15px);
    border-radius: 15px;
    background-color: $color-ocean;
    border: 2px solid $color-cobalt;
    height: auto;
    .dropdown {
      &__content {
        left: 0 !important;
        top: auto !important;
        bottom: 0;
        height: auto;
        @media screen and (min-width: $bp-tablet) {
          position: relative;
          top: 15px !important;
          bottom: auto;
          // height: 100%;
        }
        ul {
          position: absolute;
          display: block;
          margin-top: 0;
          border-radius: 0;
          padding-top: 30px;
          padding-bottom: 80px;
          align-items: flex-start;
          bottom: 0;
          left: 0;
          min-height: 285px;
          @media screen and (min-width: $bp-tablet) {
            min-height: 0;
            bottom: auto;
            padding-top: 15px;
            padding-left: 15px;
            padding-bottom: 15px;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .uk-dropdown {
    background-color: transparent;
    padding: 0;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 5;
    text-align: left;
    @media screen and (min-width: $bp-tablet) {
      position: relative;
      width: 50%;
      max-width: 420px;
      min-width: 420px;
      height: auto;
      margin: 0;
      padding-top: 15px;
    }
    button {
      position: relative;
      display: block;
      width: 100%;
    }

    ul {
      background-color: white;
      border-radius: 10px;
      padding: 10px;
      margin-top: 15px;
    }
    .dropdown___choice {
      width: 100%;
      margin: 5px 0;
      color: $color-deepblue-100;
      font-weight: 700;
      @media screen and (min-width: $bp-tablet) {
        &:not(:first-child) {
          margin-left: 25px;
        }
      }
      .checkbox__label {
        margin-left: 25px;
        padding: 2px 5px;
        border-radius: 10px;
        transition: 0.2s ease all;
      }

      &:hover {
        .checkbox__label {
          background-color: $color-deepblue-100;
          color: white;
          transition: 0.2s ease all;
        }
      }
    }

    .back {
      position: absolute;
      right: 20px;
      bottom: 15px;
      cursor: pointer;
      width: calc(100% - 230px);
      height: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &__icon {
        margin-right: 10px;
      }
      &__label {
        font-size: 12px;
        font-weight: 700;
        color: $color-deepblue-100;
      }

      @media screen and (min-width: $bp-tablet) {
        display: none;
      }
    }
  }
  &__title {
    position: relative;
    width: 100%;
    font-weight: 700;
    color: white;
    text-align: left;
    padding-left: 10px;
    margin: auto 0;
    height: 100%;
    min-height: 25px;
    // display: flex;
    align-items: center;
    &:after {
      content: "\f061";
      font-family: $font-icon;
      position: absolute;
      color: white;
      top: 50%;
      right: 0px;
      font-size: 12px;
      font-weight: 500;
      line-height: 25px;
      display: block;
      height: 25px;
      width: 25px;
      padding: 0;
      text-align: center;
      transition: 0.5s ease all;
      transform: translateY(-50%);
      transform-origin: 50% 0%;
    }

    &.uk-open {
      &:after {
        transform: rotate(90deg) translateY(-50%);
      }
    }
  }
}

//Checkbox
.row {
  &__checkbox {
    padding-right: 40px;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    &:not(.inline) {
      & + .row__checkbox {
        margin-top: 5px;
      }
    }
    .input {
      position: relative;
      display: inline-flex;
      align-items: center;
      margin: auto 0;
      input {
        appearance: none;
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        padding: 0;
        &:checked {
          & + .checkbox__like {
            // border-color: $color-checkbox-active;
            // border-color: red;
            background-color: $color-deepblue-100;
            &:before {
              transform: translate(-50%, -50%) scale(1);
              opacity: 1;
            }
            & + .checkbox__label {
              background-color: $color-softGrey;
            }
          }
        }

        &:not(:checked) {
          pointer-events: none;
          cursor: none;
          // border-color: red;
          & + .checkbox__like {
            &:before {
              transform: translate(-50%, -50%) scale(0.5);
              opacity: 0;
            }
            & + .checkbox__label {
            }
          }
        }
      }
    }
    .checkbox {
      &__like,
      &__label {
        cursor: pointer;
      }
      &__label {
        margin: auto;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        min-height: 20px;
        line-height: 1.3;
        font-size: 12px;
        margin: auto;
        padding-left: 28px;
        padding-right: 5px;
        font-weight: 700;

        span {
          display: block;
          margin: auto 0;
        }
        a {
          text-decoration: underline;
          color: inherit;
        }
      }
      &__like {
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        border: 1px solid $light-grey;
        width: 20px;
        height: 20px;
        @include border-radius(15%);
        &:before {
          content: "\f00c";
          font-family: $font-icon;
          color: $white;
          pointer-events: none;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          transform: translateY(-50%) translateX(-50%);
          @include border-radius(50%);
          opacity: 0;
          transition: all 0.2s ease;
        }
      }
      // Uniquement pour le bouton all
      &__all {
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        border: 1px solid $light-grey;
        width: 20px;
        height: 20px;
        @include border-radius(15%);
        &:before {
          content: "\f068";
          font-family: $font-icon;
          color: white;
          pointer-events: none;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          transform: translateY(-50%) translateX(-50%);
          @include border-radius(50%);
          opacity: 0;
          transition: all 0.2s ease;
        }
      }
    }
  }
}
