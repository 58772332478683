@import "../../__mandatory";

.MuiAutocomplete {
    &-root {
        &-list {
            margin-top: 8px;
            width: 100%;
            max-width: 420px;
            font-family: $font-mui;
            * {
                &:not(.fa-solid) {
                    font-family: $font-mui;
                }
                box-sizing: border-box;
            }

            .input {
                &-wrapper {
                    position: relative;
                    display: flex;
                    width: 100%;

                    &.filled {
                        :global {
                            .MuiInputLabel {
                                &-root {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
                &-icon {
                    background-color: white;
                    position: absolute;
                    width: 56px;
                    height: calc(100% - 4px);
                    top: 2px;
                    right: 2px;
                    border-radius: 0 5px 5px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $color-primary;
                }
            }
            &.Mui {
                &-focused {
                    .MuiInputLabel {
                        &-root {
                            opacity: 0;
                            // padding: 0 30px 0 15px;
                            // line-height: 1;
                            // top: 0%;
                            // transform: translateY(-100%);
                            // font-size: 12px;
                            // height: 12px;
                        }
                    }
                    .MuiOutlinedInput {
                        &-notchedOutline {
                            border-color: $color-secondary;
                        }
                    }
                }
            }
            .MuiFormControl {
                &-root {
                    position: relative;
                    width: 100%;
                    color: white;
                    background-color: #2b77a6;
                    border: 2px solid #102d40;
                    border-radius: 24px;
                }
            }
            .MuiInputLabel {
                &-root {
                    font-size: 12px;
                    font-family: $font-button;
                    font-weight: $ft-bold;
                    color: white;
                }
                &-outlined {
                    transform: translate(10px, 10px) scale(1);
                }
            }
            legend {
                display: none;
            }
            .MuiInputBase {
                &-root {
                    border-radius: 5px;
                    padding: 5px 52px 5px 10px;

                    input {
                        height: 100%;
                        border: 0;
                        background-color: transparent;
                        font-size: 12px;
                        font-family: $font-button;
                        color: white;
                    }
                    .MuiOutlinedInput {
                        &-root {
                        }
                        &-notchedOutline {
                            top: 0;
                            padding: 0;
                            border: none;
                            transform: translateY(-2px);
                        }
                    }
                    .MuiChip-root {
                        height: 24px;
                    }
                    .MuiAutocomplete-endAdornment {
                        right: 5px;
                        .MuiButtonBase-root {
                            border-radius: 20px;
                            .MuiIconButton-label {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
