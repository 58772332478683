@import "../../../../assets/scss/__mandatory";

$heightElementUserMenu: 18;

.header {
  &-wrapper {
    .over-toolbar-top {
      @for $i from 1 through 10 {
        &[data-item-length="#{$i}"] {
          height: ($i * $heightElementUserMenu + $paddingMenuUser * 2) * 1px;
        }
      }
    }
  }
}

.header {
  &-wrapper {
    width: 100%;
    &.open {
      .over-toolbar-top {
        position: relative;
        //transform: translateY(-100%);
        overflow: hidden;
        @include transitionElt(all, 0.8s, ease);
        nav {
          // display: none;
          opacity: 1;
          transform: translateY(0%);
          position: relative;
          @include transitionElt(all, 0.8s 0.25s, ease);
        }
      }
    }
    &:not(.open) {
      .over-toolbar-top {
        height: 0;
        @include transitionElt(all, 0.8s 0.25s, ease);
        nav {
          // display: none;
          transform: translateY(-100%);
          opacity: 0;
          @include transitionElt(all, 0.8s, ease);
        }
      }
    }
  }
}

.over-toolbar-top,
.toolbar-top {
  width: 100%;
  @media print {
    display: none;
  }
  .inner {
    max-width: 960px;
    margin: auto;
    color: #fff;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    padding: 0 10px;
  }
  button,
  a {
    color: white;
    &:hover {
      color: $color-citron;
    }
  }
  &[data-context="blue-dark"] {
    background-color: $color-deepblue-100;
  }
  &[data-context="seaLight"] {
    background-color: $color-seaLight;
    .inner {
      color: $color-deepblue-100;
      .brand {
        color: $color-ocean;
      }
      .user {
        i {
          color: $color-deepblue-100;
        }
      }
    }
    button,
    a {
      color: $color-deepblue-100;
    }
    .inner {
    }
    nav {
      .link-icon {
        color: $color-oceanBright;
      }
    }
  }
}

.toolbar-top {
  font-family: $font-title-wf;
  .inner {
    height: 60px;
    justify-content: space-between;
  }
  &.toolbar-lp {
    .brand {
      color: $color-sea;
    }
  }
  .brand {
    font-weight: 400;
  }
  .universe {
    font-weight: 900;
    a {
      color: inherit;
      &:hover {
        text-decoration: none;
        color: $color-citron;
      }
    }
  }
  .link-user {
    .link-label {
      font-size: 12px;
      display: none;
      @media screen and (min-width: $bp-tablet) {
        display: inline-flex;
      }
    }
    .link-icon {
      font-size: 12px;
      i {
        font-size: 16px;
        margin-left: 5px;
        @media screen and (min-width: $bp-tablet) {
          font-size: 12px;
        }
      }
    }
  }
}

.over-toolbar-top {
  position: relative;
  //transform: translateY(-100%);
  overflow: hidden;
  // &.open {
  //   // min-height: 120px;
  //   @include transitionElt(all, 0.8s, ease);
  //   nav {
  //     // display: none;
  //     opacity: 1;
  //     transform: translateY(0%);
  //     position: relative;
  //     @include transitionElt(all, 0.8s 0.25s, ease);
  //   }
  // }
  // &:not(.open) {
  //   height: 0;
  //   @include transitionElt(all, 0.8s 0.25s, ease);
  //   nav {
  //     // display: none;
  //     transform: translateY(-100%);
  //     opacity: 0;
  //     @include transitionElt(all, 0.8s, ease);
  //   }
  // }
  .inner {
    justify-content: flex-end;
  }
  nav {
    padding: $paddingMenuUser#{"px"} 0;
    position: relative;
    li {
      & + li {
        margin-top: 5px;
      }
    }
  }
  a,
  button {
    .link-label {
      font-size: 12px;
    }
    .link-icon {
      color: $color-sea;
    }
    &:hover {
      .link-icon {
        color: $color-citron;
      }
    }
  }
}

.menu {
  &-technical {
    position: fixed;
    top: 0;
    right: 0;
    background-color: gold;
    padding: 10px;
  }
}
