@import "../../../../assets/scss/__mandatory";

.modules {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &-item {
    margin-top: 15px;
    width: calc(100% / 3);
    min-height: 200px;
    background-color: $white;
    padding: 20px 30px;

    h3 {
      font-weight: $ft-black;
      font-size: 24px;
      min-height: 50px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    & + .modules-item {
      margin-left: 15px;
    }
    .btn {
      width: 100%;
      .btn-label {
        margin: initial;
      }
    }
  }
}
