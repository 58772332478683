@import "../../../../../assets/scss/_mandatory";

.user {
  &-manager {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .user-access {
      width: 65%;
      &_inner {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-top: 20px;
        .btn {
          text-align: left;
          padding: 0 15px;
          &-label {
            margin: initial;
          }
        }
      }
    }
    .manage-account {
      width: 35%;
      margin-left: 20px;
      &_inner {
        margin-top: 20px;
        height: calc(100% - 40px);
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        &.align-full {
          justify-content: space-between;
        }
        &:not(.align-full) {
          .account-status {
            margin-top: 10px;
          }
        }
        .account-status {
          display: flex;
          align-items: center;
          .toggle {
            margin: 0 10px;
          }
          span {
            font-family: $font;
            font-size: 14px;
            color: #829395;
            &.active {
              font-weight: $ft-bold;
              color: $color-deepblue-100;
            }
          }
        }
        .delete {
          .btn-delete {
            background-color: $color-alert;
            color: $white;
            border-color: $color-alert;
            max-width: 150px;
            width: 100%;
            text-align: left;
            padding: 0 15px;
            .btn-label {
              margin: initial;
            }
          }
        }
      }
    }
  }
}

.confirm-dialog {
  position: absolute;
  z-index: 55;
  background: $color-deepblue-100;
  color: $white;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  padding: 30px;
  min-width: 300px;
  &-question {
    text-align: center;
  }
  &-button {
    &-group {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    &--false {
      color: $white;
      text-decoration: underline;
    }
  }
}
