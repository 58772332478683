@import "../../__mandatory";

@each $theme, $text in $listColor {
  .clr-#{$theme} {
    color: $text;
  }
}

@each $theme, $background, $text in $listTheme {
  [data-theme="#{$theme}"] {
    background-color: $background;
    .page {
      &__title {
        color: $text;
      }
    }
  }
}

.page {
  width: 100%;
  &_inner {
    width: 100%;
  }
  &__title {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
    padding: 15px 10px;
    font-size: 18px;
    text-transform: uppercase;
    text-align: left;
    font-family: $font-title-wf;
    @media screen and (min-width: $bp-tablet) {
      padding: 15px 5px;
      font-size: 32px;
    }
    @media screen and (min-width: $bp-desk-small) {
      padding: 15px 0;
    }
    b {
      font-weight: 900;
    }
    small {
      font-weight: 400;
      & + b {
        //margin-left: 10px;
      }
    }
  }
}
