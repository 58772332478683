@import "../../../../assets/scss/__mandatory";

.search-container {
  display: flex;
  align-items: center;
  margin: auto 0;
  min-width: 100%;
  @media screen and (min-width: 440px) {
    min-width: 200px;
  }
  @media screen and (min-width: $bp-smartphone-xlarge) {
    min-width: 400px;
  }
  .search-box {
    position: relative;
    border-radius: 30px;
    min-width: 100%;
    input {
      height: 28px;
      padding: 0px 10px;
      border-radius: 20px;
      border: 2px solid #d5e0e1;
      width: 100%;
      font-size: 14px;
      color: $color-ocean;
      background-color: white;
      transition: all 0.25s ease;
      &::placeholder {
        font-size: 14px;
        color: $color-ocean;
      }
      &:focus,
      &.filled {
        background-color: $color-seaLight;
      }
    }
    &_icon {
      font-weight: $ft-black;
      font-size: 14px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      .fa-search {
        color: $color-ocean;
      }
      .fa-times {
        color: $color-alert;
        cursor: pointer;
      }
    }
  }
}

.module-filter {
  min-width: 100%;
  margin-top: 10px;
  @media screen and (min-width: 440px) {
    min-width: 200px;
    margin-top: 0;
  }
  .filter {
    &__value-container {
      min-height: 28px;
    }
    &__control {
      padding: 0 5px;
    }
    &__single-value,
    &__indicator-separator,
    &__menu-notice {
      color: $white;
    }
    &__indicator {
      color: $white;
      &:hover {
        cursor: pointer;
        color: $white;
      }
    }
    input {
      font-size: 12px;
      color: $color-ocean !important;
      font-weight: 600;
    }
    &__menu {
      background-color: transparent;
      border: none;
      box-shadow: unset;
      margin-top: 1px;
      font-size: 12px;
    }
    &__menu-list {
      width: auto;
      background-color: $color-cobalt;
      border-radius: 25px;
      box-shadow: 0 2px 5px rgba(16, 45, 64, 0.5);
      color: €white;
      padding: 5px 6px;
      min-height: 30px;
      display: flex;
      flex-flow: wrap;
      align-items: center;
    }
    &__option {
      cursor: pointer;
      padding: 0 10px;
      width: 100%;
      display: flex;
      flex-flow: wrap;
      align-items: center;

      font-size: 12px;
      margin: auto 0;
      background-color: transparent;
      &:hover {
        font-weight: 700;
      }
    }
  }
}
