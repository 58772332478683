@import "../../../../../../assets/scss/_mandatory";

.export {
  .select {
    .drop {
      ul {
        max-width: 90px;
      }
    }
  }
}
.btn {
  &.btn {
    &-download-pdf {
      display: flex;
      border: 2px solid $color-deepblue-100;
      @include border-radius(15px);
      padding: 0 10px;
      &.dropdown {
      }
      &.btn-primary {
        &:hover {
          color: white;
          background-color: $color-cobalt;
        }
      }
      .btn_label {
        width: calc(100% - 30px);
        font-size: 13px;
        & + .btn_icon {
          width: 20px;
          margin-left: 10px;
        }
      }
      .btn_icon {
      }
      & + .drop {
        background-color: transparent;
        z-index: 20;
        &:not(.uk-open) {
          pointer-events: none;
        }
        &.uk-open {
          &:not(.uk-dropdown-top-left) {
            ul {
              margin-top: -30px;
              margin-left: 0px;
            }
          }
          &.uk-dropdown-top-left {
            ul {
              margin-bottom: -70px;
              margin-left: 0;
            }
          }
        }
        ul {
          width: auto;
          background-color: $color-cobalt;
          @include border-radius(15px);
          box-shadow: 0 2px 5px rgba($color-deepblue-100, 0.5);
          color: white;
          padding: 5px 0;
          min-height: 30px;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          li {
            cursor: pointer;
            padding: 0 10px;
            width: 100%;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            &:hover {
              span {
                font-weight: 700;
              }
            }
            span {
              font-size: 12px;
              display: block;
              width: 100%;
              margin: auto 0;
            }
            & + li {
            }
          }

          li {
            width: 100%;
            padding: 0 10px;
            min-height: 20px;
            color: white;
            font-size: 12px;
            cursor: pointer;

            display: flex;
            flex-flow: wrap;
            align-items: center;
            span {
              font-size: 12px;
              display: block;
              margin: auto 0;
              &.icon {
                width: 24px;
                text-align: center;
              }
              &.name {
                width: calc(100% - 24px);
              }
            }
          }
          span {
            &.clickable {
              &:hover {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
